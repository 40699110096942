import type { FC, ReactNode } from 'react';

import { Form } from 'antd';
import cn from 'classnames';

import { Switch } from '@saturn/uikit';
import type { ComponentProps, FormListChild } from '@saturn/uikit';
import { Button } from '@saturn/uikit/src/components/Button';
import { Icon } from '@saturn/uikit/src/components/Icon';

import { VisibilityChangeListener } from 'shared/components';

import { CollapsibleContentBlock } from './components/CollapsibleContentBlock';
import { ContentBlock } from './components/ContentBlock';

import styles from './ContentBlockContainer.module.scss';

interface ContentBlockContainerProps extends ComponentProps {
  maxItems?: number;
  section?: string;
  name: Array<number | string>;
  children: (fieldsInfo: ContentBlockProps) => ReactNode;
  addButtonText?: string;
  title: string;
  hideDeleteButton?: boolean;
  showChangePositionButton?: boolean;
  showVisibilityToggle?: boolean;
  isCollapsible?: boolean;
}

export type ContentBlockProps = FormListChild & {
  parentName: Array<number | string>;
  required: boolean;
};

export const ContentBlockContainer: FC<ContentBlockContainerProps> = ({
  style,
  className,
  children,
  section,
  name: listName,
  maxItems = 20,
  addButtonText = 'Add block',
  title,
  hideDeleteButton,
  showChangePositionButton = false,
  showVisibilityToggle = false,
  isCollapsible = false,
}) => {
  return (
    <div className={cn(className, 'saturn-content-block-container', styles.wrapper)} style={style}>
      <Form.List name={listName}>
        {(fields, operation) => {
          const internalHideDeleteButton =
            (listName[0] === 'howItWorksSection' && fields.length < 2) ||
            (listName[0] === 'benefitsOfInsurance' && fields.length < 4);

          return (
            <>
              <div className={styles.content}>
                {fields.map(({ key, name, ...rest }, index, arr) =>
                  isCollapsible ? (
                    <CollapsibleContentBlock
                      key={key}
                      name={listName}
                      title={title}
                      index={index}
                      hideDeleteButton={hideDeleteButton || internalHideDeleteButton}
                      onDelete={() => operation.remove(name)}
                    >
                      <VisibilityChangeListener section={section ?? listName.slice(0, -1)}>
                        {required => children?.({ name, ...rest, required, index, parentName: listName })}
                      </VisibilityChangeListener>
                    </CollapsibleContentBlock>
                  ) : (
                    <ContentBlock
                      key={key}
                      name={listName}
                      title={title}
                      index={index}
                      hideDeleteButton={hideDeleteButton || internalHideDeleteButton}
                      showChangePositionButton={showChangePositionButton}
                      onDelete={() => operation.remove(name)}
                      onMove={(from: number, to: number) => operation.move(from, to)}
                      length={arr.length}
                      toggleBlock={
                        showVisibilityToggle ? (
                          <Form.Item name={[name, 'enabled']} valuePropName="checked" style={{ marginBottom: 0 }}>
                            <Switch checkedChildren="on" unCheckedChildren="off" />
                          </Form.Item>
                        ) : null
                      }
                    >
                      <VisibilityChangeListener section={section ?? listName.slice(0, -1)}>
                        {required => children?.({ name, ...rest, required, index, parentName: listName })}
                      </VisibilityChangeListener>
                    </ContentBlock>
                  ),
                )}
              </div>
              {fields.length < maxItems && !hideDeleteButton ? (
                <div className={styles.controlPanel}>
                  <Button
                    data-key={`${listName.join('-')}-add-block-btn`}
                    type="primary"
                    onClick={() => operation.add()}
                  >
                    <Icon name="plus-outline" />
                    {addButtonText}
                  </Button>
                </div>
              ) : null}
            </>
          );
        }}
      </Form.List>
    </div>
  );
};
