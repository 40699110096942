import type { AxiosResponse } from 'axios';

import { EmailCredentialsDto, JwtAccessTokenDto, OtpCredentialsDto, OtpSessionDto } from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function logout(): Promise<void> {
  await api.post('/admin/iam/users/logout');
}

export async function sendOtp(credentialsDto: EmailCredentialsDto): Promise<OtpSessionDto> {
  const { data } = await api.post<OtpSessionDto>('/admin/iam/users/login', credentialsDto);
  return data;
}

export async function checkOtp(otp: OtpCredentialsDto): Promise<JwtAccessTokenDto> {
  const { data } = await api.post<JwtAccessTokenDto>('/admin/iam/users/login/otp', otp);
  return data;
}

export const refreshToken = (): Promise<AxiosResponse<JwtAccessTokenDto>> =>
  api.post<JwtAccessTokenDto>('/admin/iam/users/login/refresh-token');
