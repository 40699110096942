import { ReactNode, useEffect, useState } from 'react';

import { DatePicker as AntDatePicker } from 'antd';
import type { DatePickerProps as AntDatePickerProps } from 'antd';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { getTimezoneOffset } from '@saturn/api';

import { loadDatePickerLocale } from '../../utils/loadDatePickerLocale';
import { Icon } from '../Icon';

dayjs.extend(utc);
dayjs.extend(timezone);

export type DatePickerProps = AntDatePickerProps & {
  withAgeLabel?: boolean;
  label?: ReactNode;
  value?: Dayjs | string;
  onDisableDateChange?: () => void;
  isExtendedItem?: boolean;
  appLocale?: string;
  description?: ReactNode;
  onChange?: (date: Dayjs | null) => void;
};

export function DatePicker({
  id,
  label,
  value,
  format = 'DD MMMM YYYY',
  withAgeLabel,
  isExtendedItem,
  onDisableDateChange,
  disabledDate,
  appLocale,
  description,
  defaultPickerValue,
  onChange,
  ...rest
}: DatePickerProps): JSX.Element {
  const [isActive, setIsActive] = useState(Boolean(value));
  const [datePickerLocale, setDatePickerLocale] = useState<PickerLocale>();

  useEffect(() => {
    appLocale && loadTranslations(appLocale);
  }, [appLocale]);

  async function loadTranslations(appLocale: string) {
    try {
      setDatePickerLocale(await loadDatePickerLocale(appLocale));
    } catch (e) {
      console.log('The localization of datepicker can not be loaded');
    }
  }

  useEffect(() => {
    disabledDate && value && onDisableDateChange && onDisableDateChange();
  }, [disabledDate, value, onDisableDateChange]);

  const currentValue = typeof value === 'string' ? dayjs(value) : value;

  useEffect(() => {
    Boolean(currentValue) !== isActive && setIsActive(Boolean(currentValue));
  }, [currentValue, isActive]);

  const zoneOffset = getTimezoneOffset(appLocale);
  const age = dayjs()
    .utcOffset(zoneOffset)
    .startOf('day')
    .diff(dayjs(currentValue).utcOffset(zoneOffset).startOf('day'), 'year');

  const suffixIcon =
    isExtendedItem && withAgeLabel && age !== undefined ? (
      <span className="saturn-age-wrap">
        <span className="saturn-age">{`(${age} yrs)`}</span>
        <Icon name="calendar-outline" />
      </span>
    ) : isExtendedItem ? (
      <Icon name="calendar-outline" />
    ) : withAgeLabel && age !== undefined ? (
      <span className="saturn-age">{`(${age} yrs)`}</span>
    ) : null;

  // if we use defaultPickerValue when value is already select than picker will reset the view to defaultPickerValue
  const defaultVal = value ? dayjs(value) : defaultPickerValue;

  return (
    <div className={cn('float-label', { active: isActive }, { 'no-label': !label })}>
      <AntDatePicker
        inputReadOnly
        format={format}
        onFocus={() => setIsActive(true)}
        onBlur={e => !(e.target as HTMLInputElement).value && setIsActive(false)}
        value={currentValue}
        disabledDate={disabledDate}
        suffixIcon={suffixIcon}
        allowClear={!isExtendedItem}
        id={id}
        locale={datePickerLocale}
        defaultPickerValue={defaultVal}
        onChange={date => {
          onChange && onChange(date);
        }}
        {...rest}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {description && <span className="input-description">{description}</span>}
    </div>
  );
}
