import type { FC } from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';

import { InsuranceProviderDto } from '@saturn/api';
import { Icon, Table } from '@saturn/uikit';

type InsurancePartnersListProps = {
  partners: InsuranceProviderDto[];
};

export const InsurancePartnersList: FC<InsurancePartnersListProps> = ({ partners }) => {
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'title',
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (time: string | null) => {
          return time ? dayjs(time).format('MMM D, YYYY') : '';
        },
      },
      {
        title: 'Edit',
        dataIndex: 'id',
        render: (id: string) => {
          const path = `${location.pathname}/${id}`.replaceAll(/\/{2,}/gi, '/');

          return (
            <Link data-key="action-edit" to={path}>
              <Icon name="edit-2-outline" />
            </Link>
          );
        },
        width: 100,
      },
    ],
    [location.pathname],
  );

  return <Table rowKey={record => record.id} columns={columns} dataSource={partners} pagination={false} />;
};
