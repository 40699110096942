import type { FC } from 'react';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { Icon, Spin } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import { InsurancePartnersList } from '../features/insurance-partners/components/InsurancePartnersList/InsurancePartners';

const InsurancePartnersPage: FC = () => {
  const { adminLocation } = useAdminLocation();

  const { data: locationProviders = [], loading } = useRequest(
    async () => await admin.getProvidersList(adminLocation?.id),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  return (
    <main>
      <PageHeader title="Insurance Partners" />
      <Spin spinning={loading} indicator={<Icon name="sync-outline" />}>
        <InsurancePartnersList partners={locationProviders} />
      </Spin>
    </main>
  );
};

export default InsurancePartnersPage;
