import { useState } from 'react';
import type { FC } from 'react';

import { Button, Form, FormSubmitFailed, Input, notification } from '@saturn/uikit';

import { DISABILITY_CONFIRMATION_BUTTON_TIME } from '../../../../shared/constants';
import { ConfirmEmailFormProps } from './models';

export const ConfirmEmailForm: FC<ConfirmEmailFormProps> = ({
  onConfirmEmailFormFinish,
  onPrevScreen,
  onSendCodeAgain,
}) => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const onFinishFailed = ({ errorFields }: FormSubmitFailed) => {
    errorFields.forEach(err => {
      notification.error({
        message: err.errors || 'Something went wrong',
      });
    });
  };

  const onTryAgain = () => {
    setIsButtonDisabled(true);
    onSendCodeAgain();
    setTimeout(() => setIsButtonDisabled(false), DISABILITY_CONFIRMATION_BUTTON_TIME);
  };

  return (
    <Form form={form} onFinish={onConfirmEmailFormFinish} onFinishFailed={onFinishFailed} preserve={false}>
      <div>
        <div>
          <Form.Item
            name="otp"
            normalize={(value: string) => value && value.trim()}
            rules={[
              {
                required: true,
                message: 'Code is required',
              },
            ]}
          >
            <Input label="Verification code" />
          </Form.Item>
        </div>
        <div>
          <Form.Item colon={false}>
            <Button type="primary" htmlType="submit">
              Continue
            </Button>
            <Button onClick={onPrevScreen}>Back</Button>
            <Button disabled={isButtonDisabled} onClick={onTryAgain}>
              Send code again
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
