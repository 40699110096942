import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import type { CategoryCreateDto, CategoryDataDto, CategoryDetailsDto } from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, Icon, Switch, Tooltip, notification } from '@saturn/uikit';

import { PageHeader, SubTitle } from 'shared/components';
import { SidebarList } from 'shared/components/SidebarList/SidebarList';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import {
  getVisibleSections,
  onFormFinishFailed,
  revalidateAfterVisibilityChanged,
  useAdminLocation,
} from 'shared/utils';

import CATEGORY_SECTION_TITLES from 'features/categories/constants';

import { CategorySystemInfo, CategoryTabs } from '../features/categories/components';

import styles from './styles.module.scss';

const CategoriesCreatePage: FC = () => {
  const { adminLocation, locationLanguage } = useAdminLocation();
  const [formCategory] = Form.useForm<CategoryDataDto>();
  const [form] = Form.useForm<CategoryDetailsDto>();
  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [isLandingPageEnabled, setIsLandingPageEnabled] = useState<boolean>(false);
  const [indices, setIndices] = useState<IndicesType>([]);
  const [selectedInsurancePartners, updateSelectedInsurancePartners] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const disableProviders = (insuranceProviderIds: string[]) => {
    const providers = insuranceProviderIds;
    const result = [...new Set(providers)] as string[];
    updateSelectedInsurancePartners(result);
  };

  const defaultIndices: Record<string, DefaultIndexValue> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(sectionsVisibility).map(([key]) => [
          key,
          {
            order: 0,
            subSections: null,
          },
        ]),
      ),
    [sectionsVisibility],
  );

  useEffect(() => {
    if (isLandingPageEnabled) {
      formCategory.setFieldsValue({
        locationId: adminLocation.id,
        name: '',
        slugPart: '',
      });
      form.setFieldsValue({
        hero: {
          visible: false,
        },
        productQuoteForms: {
          visible: false,
        },
        categoryProducts: {
          visible: false,
          isCarousel: true,
          cardsAlignment: 'left',
        },
        findOutMoreSection: {
          visible: false,
        },
        disclosuresFooter: {
          visible: false,
        },
        video: {
          visible: false,
        },
        breadCrumb: {},
      });
    } else {
      formCategory.setFieldsValue({
        locationId: adminLocation.id,
      });
    }
    setSectionsVisibility(getVisibleSections(form.getFieldsValue()));
  }, [isLandingPageEnabled]);

  useEffect(() => {
    setSectionsVisibility(getVisibleSections(form.getFieldsValue()));
  }, []);

  const { runAsync: createCategory } = useRequest(admin.createCategory, {
    manual: true,
  });
  const { data: locationProducts = [] } = useRequest(() => admin.getProductForLocation(adminLocation.id), {
    refreshDeps: [adminLocation.id],
  });

  const { data: providers = [] } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const formFinished = async () => {
    try {
      const categoryInfo = await formCategory.validateFields();
      const categoryDetails = await form.validateFields();

      const indexedValues = indices.reduce((res, item, i) => {
        const itemKey: string = item[0];
        // @ts-ignore
        const block = res[itemKey];
        if (block && typeof block == 'object') {
          // @ts-ignore
          block.order = i;
        }
        if ('subSections' in block) {
          const indItem = indices.find(ind => ind[0] === itemKey);
          const itemSubSections = indItem && indItem[1]?.subSections;
          itemSubSections?.forEach((subSection, subSectionIndex) => {
            block.subSections[subSection[0]].order = subSectionIndex;
          });
        }
        return res;
      }, categoryDetails);

      const categoryData: CategoryCreateDto = {
        category: {
          ...categoryInfo,
          supportedLanguages: adminLocation.supportedLanguages,
          hasLandingPage: isLandingPageEnabled,
        },
        landing: indexedValues,
      };
      locationLanguage && (await createCategory(categoryData, locationLanguage));

      notification.success({
        message: 'Category has been successfully created',
      });
      const [, locationInPath, categories] = location.pathname.split('/');
      navigate(`/${locationInPath}/${categories}`, { replace: true });
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Create category" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Form name="category" form={formCategory} onFinishFailed={onFormFinishFailed} preserve={false}>
              <CategorySystemInfo isLandingPageEnabled={isLandingPageEnabled} />
            </Form>
            <Form
              name="categoryDetails"
              form={form}
              onFinishFailed={onFormFinishFailed}
              preserve={false}
              onFieldsChange={(changedFields, allFields) =>
                revalidateAfterVisibilityChanged(form, changedFields, allFields)
              }
              onValuesChange={(changedValues, allValues) => {
                if (changedValues.insurancePartners) {
                  disableProviders(allValues.insurancePartners.insuranceProviderIds);
                }
                setSectionsVisibility(getVisibleSections(allValues));
              }}
            >
              <SubTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Landing page</span>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Switch
                      checkedChildren="on"
                      unCheckedChildren="off"
                      checked={isLandingPageEnabled}
                      onChange={() => {
                        setIsLandingPageEnabled(prevState => !prevState);
                      }}
                    />
                    <Tooltip title="You cannot turn OFF/ON landing page for a created category.">
                      <Icon size={25} name="question-mark-circle-outline" />
                    </Tooltip>
                  </div>
                </div>
              </SubTitle>
              <CategoryTabs
                isShowLeaveModal={false}
                products={locationProducts}
                sectionTitles={CATEGORY_SECTION_TITLES}
                isNewCategory={true}
                providers={providers}
                selectedInsurancePartners={selectedInsurancePartners}
                isLandingPageEnabled={isLandingPageEnabled}
              />
            </Form>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Button type="primary" htmlType="submit" onClick={formFinished}>
              Create
            </Button>
            {isLandingPageEnabled && (
              <Card title="Page Layout" bordered={false} className={styles.sidebarCardWrapper}>
                <SidebarList
                  parent="category"
                  sidebarListValues={sectionsVisibility}
                  sectionTitles={CATEGORY_SECTION_TITLES}
                  defaultIndices={defaultIndices}
                  indices={indices}
                  setIndices={setIndices}
                />
              </Card>
            )}
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default CategoriesCreatePage;
