import axiosClient, { AxiosError, AxiosResponse } from '@saturn/api';
import type { UploadRequestOption } from '@saturn/uikit';
import { notification } from '@saturn/uikit';

import { refreshAndRetry } from './refreshAndRetry';

/**
 * Show error notification on failed request
 * @param {AxiosResponse} [response]
 */
function showErrorNotification(response?: AxiosResponse | null) {
  if (response) {
    if (response.data.errors?.length) {
      response.data.errors.map((err: { message: string }) => {
        notification.error({
          message: err.message,
        });
      });
    } else {
      notification.error({
        message: response.data.message || response.statusText || 'Something went wrong',
      });
    }
  }
}

export function configureApi(): void {
  axiosClient.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_API_SUFFIX;
  axiosClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.config?.url === '/admin/iam/users/login/refresh-token') {
          // Do not retry to avoid refresh-token loop
          localStorage.removeItem('accessToken');
          return Promise.reject(error);
        }
        if (localStorage.getItem('accessToken')) {
          return refreshAndRetry(error);
        }
      }

      if (error.config?.url === '/admin/iam/users/me' && error.response?.status === 404) {
        return Promise.reject('Authorization is not enabled');
      }
      showErrorNotification(error.response);

      return Promise.reject(error);
    },
  );
}

export * from './addTokenToRequest';

export const onCustomRequest = async (options: UploadRequestOption): Promise<void> => {
  const { onSuccess, onError, file, action } = options;
  const fmData = new FormData();
  fmData.append('file', file);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  try {
    const response = await axiosClient.post(action, fmData, config);
    onSuccess && onSuccess(response.data, {} as XMLHttpRequest);
  } catch (err) {
    const error = new Error('Refresh token error');
    onError && onError(error);
  }
};
