import { FC, useState } from 'react';

import { LANDING_TYPE, PRODUCT_TYPE, PRODUCT_TYPE_API, PRODUCT_TYPE_NON_API, ProductDto } from '@saturn/api';

import {
  About,
  Breadcrumb,
  FooterDisclosures,
  GeneralInfo,
  HeroBanner,
  LanguageTabs,
  MostPopularPlan,
  PageSEO,
  SecondaryFooterDisclosures,
  Video,
} from 'shared/components';
import { CallUsBack } from 'shared/components/CallUsBack/CallUsBack';
import { HowItWorks } from 'shared/components/HowItWorks/HowItWorks';
import { InsurancePartners } from 'shared/components/InsurancePartners/InsurancePartners';
import PromoEventsBanner from 'shared/components/PromoEventsBanner/PromoEventsBanner';
import { ReferFriend } from 'shared/components/ReferFriend/ReferFriend';
import { WhyAmex } from 'shared/components/WhyAmex';
import type { ProviderOption } from 'shared/utils';

import { InsuranceProducts } from 'features/categories/components';

import { AllYouNeedToKnow } from '../AllYouNeedToKnow/AllYouNeedToKnow';
import { BenefitsOfInsurance } from '../BenefitsOfInsurance/BenefitsOfInsurance';
import { CallbackMobile } from '../CallbackMobile/CallbackMobile';
import { CallbackWidget } from '../CallbackWidget';
import { Contacts } from '../Contacts';
import { Coverages } from '../Coverages/Coverages';
import EditCoverages from '../Coverages/EditCoverages/EditCoverages';
import { Documents } from '../Documents';
import { FAQ } from '../FAQ';
import { Features } from '../Features';
import { LegalDisclaimer } from '../LegalDisclamer';
import { NextSteps } from '../NextSteps';
import OurPlans from '../OurPlans/OurPlans';
import { ProductOverview } from '../ProductOverview/ProductOverview';
import { PromoBenefit } from '../PromoBenefit';
import { WhatIsCovered } from '../WhatIsCovered';
import { WhatIsIncluded } from '../WhatIsIncluded/WhatIsIncluded';
import { WhoIsCovered } from '../WhoIsCovered';

export type ProductTabsProps = {
  type: PRODUCT_TYPE;
  landingType?: LANDING_TYPE;
  isShowLeaveModal: boolean;
  isLandingPageEnabledForNonApiProduct?: boolean;
  sectionTitles: Record<string, string>;
  isNewProduct?: boolean;
  isSecondaryFooterDisclosureVisible?: boolean;
  productLandingId?: string;
  providers: ProviderOption[];
  isEdit?: boolean;
  selectedProducts?: string[];
  products?: ProductDto[];
  selectedInsurancePartners: string[];
};

export const ProductTabs: FC<ProductTabsProps> = ({
  type,
  isShowLeaveModal,
  sectionTitles,
  isNewProduct,
  isSecondaryFooterDisclosureVisible,
  isLandingPageEnabledForNonApiProduct,
  landingType,
  productLandingId,
  providers,
  isEdit,
  selectedProducts,
  products,
  selectedInsurancePartners,
}) => {
  const isSectionVisible =
    type === PRODUCT_TYPE_API ||
    (isLandingPageEnabledForNonApiProduct && type === PRODUCT_TYPE_NON_API) ||
    (landingType === 'EXTENDED' && type === PRODUCT_TYPE_NON_API);
  const [isSubsectionsVisible, setIsSubsectionsVisible] = useState<boolean>(true);

  return (
    <>
      <LanguageTabs isShowLeaveModal={isShowLeaveModal}>
        {isSectionVisible ? (
          <>
            <CallbackMobile />
            <PromoEventsBanner />
            <Breadcrumb header={sectionTitles.breadcrumb} />
            {type === PRODUCT_TYPE_API ? (
              <MostPopularPlan header={sectionTitles.highlightPlan} productLandingId={productLandingId} />
            ) : null}
            <PageSEO header={sectionTitles.seo} />
          </>
        ) : null}
        <GeneralInfo
          header={sectionTitles.info}
          type="product"
          productType={type}
          landingType={landingType}
          isNewProduct={isNewProduct}
          isLandingPageEnabled={isLandingPageEnabledForNonApiProduct}
        />
        {isSectionVisible ? (
          <>
            <ProductOverview header={sectionTitles.overview} />
            {type === PRODUCT_TYPE_API ? <LegalDisclaimer header={sectionTitles.planLegalDisclaimer} /> : null}
            <HeroBanner header={sectionTitles.hero} providers={providers} />
            <CallUsBack header={sectionTitles.callUsBack} />
            <Video header={sectionTitles.video} parent="product" />
            <Features header={sectionTitles.feature} />
            <About header={sectionTitles.about} />
            <InsuranceProducts
              header={sectionTitles.categoryProducts}
              selectedInsuranceProducts={selectedProducts}
              products={products}
              title="Other products"
            />
            <CallbackWidget header={sectionTitles.callbackWidgetSection} />
            {type === PRODUCT_TYPE_API ? <PromoBenefit header={sectionTitles.promotionBenefit} /> : null}
            <AllYouNeedToKnow
              header={sectionTitles.allYouNeedToKnow}
              onCollapse={key => setIsSubsectionsVisible(key.length > 0)}
            />
            <div style={{ display: isSubsectionsVisible ? 'block' : 'none' }}>
              <WhatIsCovered header={sectionTitles.whatIsCovered} />
              <WhoIsCovered header={sectionTitles.whoIsCovered} />
              {isEdit ? (
                <EditCoverages header={sectionTitles.coverage} />
              ) : (
                <Coverages header={sectionTitles.coverage} />
              )}
              <FAQ header={sectionTitles.faq} />
              <Documents header={sectionTitles.document} />
            </div>
            {type === PRODUCT_TYPE_API ? <ReferFriend header={sectionTitles.referFriend} /> : null}
            <Contacts header={sectionTitles.contact} />
            <InsurancePartners
              header={sectionTitles.insurancePartners}
              providers={providers}
              selectedInsurancePartners={selectedInsurancePartners}
            />
            <WhyAmex header={sectionTitles.whyAmericanExpress} />
            <FooterDisclosures header={sectionTitles.disclosuresFooter} />
            {isSecondaryFooterDisclosureVisible && (
              <SecondaryFooterDisclosures header={sectionTitles.secondaryFooterDisclosure} />
            )}
            <NextSteps header={sectionTitles.nextSteps} />
            <WhatIsIncluded header={sectionTitles.whatIsIncluded} />
            <BenefitsOfInsurance header={sectionTitles.benefitsOfInsurance} />
            <HowItWorks header={sectionTitles.howItWorksSection} />
            <OurPlans />
          </>
        ) : null}
      </LanguageTabs>
    </>
  );
};
