import type { FC } from 'react';

import { Form, Switch } from '@saturn/uikit';

import { SubTitle } from '../SubTitle';

interface VisibilitySwitcherProps {
  title: string;
  name: (string | number)[];
  fieldProps?: {
    preserve?: boolean | undefined;
    parentName: (string | number)[];
  };
}

export const VisibilitySwitcher: FC<VisibilitySwitcherProps> = ({ title, name, fieldProps }) => {
  return (
    <SubTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{title}</span>
        <Form.Item {...fieldProps} name={[...name]} valuePropName="checked" style={{ marginBottom: 0 }}>
          <Switch checkedChildren="on" unCheckedChildren="off" />
        </Form.Item>
      </div>
    </SubTitle>
  );
};
