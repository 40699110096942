import get from 'lodash/get';

import { LandingMediaFiles, admin } from '@saturn/api';

// any is used because we would need to write huge union with all the nesting for out landing this type can come up with more then  200 lines and it will be hard to maintain
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type IndexedValues = any;

type PathToId = (string | number)[];

export const findPath = (obj: IndexedValues, targetId: string, currentPath: PathToId = [], result: PathToId[] = []) => {
  for (const key in obj) {
    const newPath = [...currentPath, key];
    if (obj[key] === targetId) {
      result.push(newPath);
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      findPath(obj[key], targetId, newPath, result);
    }
  }
  return result;
};

const uploadImportedImages = async (
  importImages: LandingMediaFiles[],
  indexedValues: IndexedValues,
  onSuccess: (
    results: { pathToId: PathToId; id: string; fileMetadata?: { mimeType?: string; title?: string } }[],
  ) => void,
) => {
  const imagesWithPath = importImages
    ?.map(image => {
      const targetId = image.id;
      const paths = findPath(indexedValues, targetId) ?? [];
      return { ...image, paths };
    })
    ?.filter(image => image.paths.length > 0);

  if (imagesWithPath?.length) {
    const results = await Promise.all(
      imagesWithPath.map(async imageWithPath => {
        const imageFormData = new FormData();
        const file = await fetch(imageWithPath.inlineBody)
          .then(res => res.blob())
          .then(blob => {
            // Extract MIME type from the Base64 string (if available)
            const mimeTypeMatch = imageWithPath.inlineBody.match(/^data:(.*?);base64,/);
            const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : 'image/png';
            return new File([blob], 'File name', { type: mimeType });
          });
        imageFormData.append('file', file);
        const response = await admin.uploadFile({ form: imageFormData, fileName: imageWithPath.id });
        return { paths: imageWithPath.paths, id: response.id };
      }),
    );
    if (results.length) {
      const mapped = transformPaths(results, indexedValues);
      onSuccess(mapped);
    }
  }
};

function transformPaths(res: { paths: PathToId[]; id: string }[], indexedValues: IndexedValues) {
  return res.flatMap(result =>
    result.paths.map(path => {
      const pathToFileMetadata = path.slice(0, -1);
      const fileMetaData = get(indexedValues, pathToFileMetadata);
      return {
        pathToId: pathToFileMetadata,
        id: result.id,
        fileMetaData,
      };
    }),
  );
}

export default uploadImportedImages;
