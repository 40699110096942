import { useState } from 'react';
import type { FC } from 'react';

import { InputNumber } from 'antd';
import type { InputNumberProps } from 'antd';

import { Button } from '../../Button';
import { Icon } from '../../Icon';

import styles from './CounterInput.module.scss';

export const CounterInput: FC<InputNumberProps> = ({ defaultValue, min, max, onChange, ...rest }) => {
  const [counterValue, setCounterValue] = useState<number>(Number(defaultValue ?? 1));

  const handleChange = (value: number | string | null) => {
    onChange && onChange(value);
    setCounterValue(Number(value ?? 0));
  };

  const decreaseCounter = () => {
    setCounterValue(prevValue => {
      onChange && onChange(prevValue - 1);
      return prevValue - 1;
    });
  };

  const increaseCounter = () => {
    setCounterValue(prevValue => {
      onChange && onChange(prevValue + 1);
      return prevValue + 1;
    });
  };

  return (
    <div className={styles.wrapper}>
      <Button type="primary" onClick={decreaseCounter} disabled={counterValue === Number(min)}>
        <Icon color="#ffffff" size={20} name="minus-outline" />
      </Button>
      <InputNumber
        min={min}
        max={max}
        defaultValue={defaultValue}
        value={counterValue}
        onChange={value => handleChange(value ?? 1)}
        {...rest}
      />
      <Button type="primary" onClick={increaseCounter} disabled={counterValue === Number(max)}>
        <Icon color="#ffffff" size={20} name="plus-outline" />
      </Button>
    </div>
  );
};
