import type { PickerLocale } from 'antd/lib/date-picker/generatePicker';

export async function loadDatePickerLocale(locale: string): Promise<PickerLocale> {
  let loadedDatePickerLocale: PickerLocale;
  switch (locale) {
    case 'fr':
    case 'fr-fr':
      loadedDatePickerLocale = (await import('antd/lib/date-picker/locale/fr_FR.js')).default;
      return { ...loadedDatePickerLocale, lang: { ...loadedDatePickerLocale.lang, dayFormat: 'D' } };
    case 'it':
    case 'it-it':
      return (await import('antd/lib/date-picker/locale/it_IT.js')).default;
    case 'es':
    case 'es-ar':
      return (await import('antd/lib/date-picker/locale/es_ES.js')).default;
    case 'ja-jp':
      return (await import('antd/lib/date-picker/locale/ja_JP.js')).default;
    case 'zh-hk':
      return (await import('antd/lib/date-picker/locale/zh_CN.js')).default;
    case 'de':
    case 'de-de':
      return (await import('antd/lib/date-picker/locale/de_DE.js')).default;
    case 'th-th':
      return (await import('antd/lib/date-picker/locale/th_TH.js')).default;
    case 'en':
    case 'en-gb':
    case 'en-sg':
    case 'en-hk':
    case 'en-in':
    case 'en-th':
    default:
      return (await import('antd/lib/date-picker/locale/en_GB.js')).default;
  }
}
