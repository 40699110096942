import type { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import type { ContentBlockProps } from 'shared/components';
import { MarkdownTip } from 'shared/components';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import styles from '../../HowItWorksBlock.module.scss';

export const HowItWorksBlock: FC<ContentBlockProps> = ({ name, required, ...fieldProps }) => {
  const howItWorksImageInline = useImportedInlineBody([...fieldProps.parentName, name, 'cardImage']);
  return (
    <Form.Item required={false} noStyle shouldUpdate>
      <div className={styles.wrapper}>
        <Form.Item
          {...fieldProps}
          name={[name, 'cardImage']}
          rules={[
            {
              required,
              message: 'Picture is required',
            },
          ]}
        >
          <ImageUploader
            inlineBody={howItWorksImageInline}
            content="Upload Picture"
            tooltip="Recommended aspect ratio 1:1"
            className={styles.image_container}
            valueFormatter={fileUrlFormatter}
            action={uploadFileUrl(`howItWorks_card_image_${name}`)}
            customRequest={onCustomRequest}
            maxSize={500}
            style={{
              width: 265,
              height: 225,
            }}
          />
        </Form.Item>
        <div className={styles.inputsWrapper}>
          <Form.Item
            {...fieldProps}
            name={[name, 'title']}
            rules={[
              {
                required,
                message: 'Scenario Title is required',
                whitespace: true,
              },
              ...(required
                ? [
                    {
                      max: 100,
                      message: 'Scenario Title should be at most 100 characters',
                    },
                  ]
                : []),
            ]}
          >
            <Input label="Scenario Title" />
          </Form.Item>
          <MarkdownTip>
            <Form.Item
              {...fieldProps}
              name={[name, 'description']}
              rules={[
                {
                  required,
                  message: 'Description is required',
                  whitespace: true,
                },
                ...(required
                  ? [
                      {
                        max: 1000,
                        message: 'Description should be at most 1000 characters',
                      },
                    ]
                  : []),
              ]}
            >
              <Textarea label="Description" />
            </Form.Item>
          </MarkdownTip>
        </div>
      </div>
    </Form.Item>
  );
};
