import type { FC } from 'react';

import { LanguageTabs, PageHeader } from 'shared/components';

import { ProductsTranslationsList } from '../features/products-translations-list';

const TranslationsPage: FC = () => {
  return (
    <main>
      <PageHeader title="Manage Translations" />
      <LanguageTabs isShowLeaveModal={false}>
        <ProductsTranslationsList />
      </LanguageTabs>
    </main>
  );
};

export default TranslationsPage;
