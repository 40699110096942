import type { FC } from 'react';
import InputMask, { Props } from 'react-input-mask';

import { InputElementProps } from '../models';

export type MaskedInputProps = Props &
  Pick<InputElementProps, 'label' | 'view'> & { maskChar?: string; maskType?: string };
export type MaskedInputExtraProps = Partial<
  Pick<MaskedInputProps, 'mask' | 'maskPlaceholder' | 'alwaysShowMask' | 'beforeMaskedStateChange' | 'maskChar'>
>;

const mapMask: Record<string, string> = {
  ssnMask: '99-99999999-9',
};

export const MaskedInput: FC<MaskedInputProps> = props => {
  if (props.maskType && mapMask[props.maskType]) {
    props = { ...props, mask: mapMask[props.maskType] };
  }
  return <InputMask className="ant-input" {...props} />;
};
