import { useCallback, useEffect, useMemo, useState } from 'react';
import type { CSSProperties, FC, FocusEventHandler } from 'react';

import { Input } from 'antd';
import type { TextAreaProps as AntTextAreaProps } from 'antd/lib/input';
import cn from 'classnames';

export interface TextareaProps extends AntTextAreaProps {
  label: string;
  className?: string;
  style?: CSSProperties;
  customErrorMessage?: string;
}

export const Textarea: FC<TextareaProps> = ({
  className,
  value,
  label,
  style = {},
  customErrorMessage,
  defaultValue,
  ...rest
}) => {
  const isFieldInput = useMemo(() => !!(value || typeof value === 'number'), [value]);
  const [isActive, setIsActive] = useState<boolean>(isFieldInput);

  // TODO: fix react-hooks/exhaustive-deps
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    isFieldInput !== isActive && setIsActive(isFieldInput);
  }, [isFieldInput]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    // Set input as active if defaultValue exists to render floating label correctly
    setIsActive(!!defaultValue);
  }, [defaultValue]);

  const onFocus: FocusEventHandler<HTMLTextAreaElement> = useCallback(() => {
    setIsActive(true);
  }, []);

  const onBlur: FocusEventHandler<HTMLTextAreaElement> = useCallback(e => {
    !(e.target as HTMLTextAreaElement).value && setIsActive(false);
  }, []);

  const inputProps = {
    ...rest,
    ...(customErrorMessage && { status: 'error' as '' | 'warning' | 'error' | undefined }),
  };

  return (
    <div
      className={cn(className, 'float-label', {
        active: isActive || isFieldInput,
        ['no-label']: !label,
      })}
      style={style}
    >
      <Input.TextArea
        rows={3}
        value={value}
        defaultValue={defaultValue}
        onFocus={onFocus}
        onBlur={onBlur}
        {...inputProps}
      />
      <label>{label}</label>
      {customErrorMessage && <span className="ant-form-item-explain-error">{customErrorMessage}</span>}
    </div>
  );
};
