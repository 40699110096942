import { useNavigate } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { FormProps, notification } from '@saturn/uikit';

import { ErrorReportForm, PageHeader } from 'shared/components';

import { useAdminLocation } from '../shared/utils';

function ErrorReportPage(): JSX.Element {
  const { adminLocation } = useAdminLocation();
  const navigate = useNavigate();

  const { run: handleSendErrorReport } = useRequest(admin.sendErrorReport, {
    manual: true,
    onSuccess: () => {
      notification.success({
        message: 'Report was successfully sent',
      });
    },
  });

  if (adminLocation.id !== 'in') {
    navigate('/');
  }

  const onSubmit: FormProps['onFinish'] = async values => {
    handleSendErrorReport({
      providerId: values.providerId,
      from: values.from.toISOString(),
      to: values.to.toISOString(),
    });
  };

  return (
    <main>
      <PageHeader title="Error Report" />
      <ErrorReportForm onSubmit={onSubmit} />
    </main>
  );
}

export default ErrorReportPage;
