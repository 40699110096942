import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, IconsGallery, LanguageTabs, MarkdownTip } from 'shared/components';
import type { ContentBlockProps } from 'shared/components';

import styles from './PromotionBenefits.module.scss';

type PromotionBenefitsProps = {
  header?: string;
};

export function PromotionBenefits({ header }: PromotionBenefitsProps) {
  return (
    <LanguageTabs isShowLeaveModal={false}>
      <CollapsiblePanel header={header || 'Promotion Benefits'} name={['benefits']} withoutSwitch>
        <ContentBlockContainer
          className={styles.content}
          maxItems={10}
          name={['benefits']}
          addButtonText="Add plan"
          title="Promo benefit"
        >
          {props => <PromotionBenefitBlock {...props} />}
        </ContentBlockContainer>
      </CollapsiblePanel>
    </LanguageTabs>
  );
}

function PromotionBenefitBlock({ name: blockName, ...fieldProps }: ContentBlockProps) {
  return (
    <>
      <Form.Item
        {...fieldProps}
        name={[blockName, 'planName']}
        rules={[
          {
            required: true,
            message: 'Plan Name is required',
            whitespace: true,
          },
          {
            min: 3,
            message: 'Plan Name should be at least 3 characters',
          },
          {
            max: 50,
            message: 'Plan Name should be at most 50 characters',
          },
        ]}
      >
        <Input label="Plan Name" />
      </Form.Item>
      <Form.Item
        {...fieldProps}
        name={[blockName, 'benefitTitle']}
        rules={[
          {
            max: 50,
            message: 'Title should be at most 50 characters',
          },
        ]}
      >
        <Input label="Title" />
      </Form.Item>
      <MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[blockName, 'benefitDescription']}
          rules={[
            {
              required: true,
              message: 'Benefit Description is required',
              whitespace: true,
            },
            {
              min: 3,
              message: 'Benefit Description should be at least 3 characters',
            },
            {
              max: 150,
              message: 'Benefit Description be at most 150 characters',
            },
          ]}
        >
          <Textarea label="Description" />
        </Form.Item>
      </MarkdownTip>
      <Form.Item
        {...fieldProps}
        className={styles.iconInput}
        preserve={false}
        valuePropName="icon"
        name={[blockName, 'thumbnailId']}
      >
        <IconsGallery />
      </Form.Item>
    </>
  );
}
