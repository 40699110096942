import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';

import type { ContentBlockProps } from 'shared/components';
import { IconsGallery, MarkdownTip } from 'shared/components';

import styles from '../PromoBenefit.module.scss';

export const PromoBenefitBlock: FC<ContentBlockProps> = ({ name, required, index, ...fieldProps }) => {
  return (
    <Form.Item required={false} noStyle shouldUpdate>
      {form => {
        const customRequired =
          required && form.getFieldValue(['promotionBenefit', 'promotionBenefitPlans'])[index]?.enabled;
        return (
          <>
            <div className={styles.inputsWrapper}>
              <Form.Item {...fieldProps} name={[name, 'planName']}>
                <Input label="Plan Name" disabled />
              </Form.Item>
              <Form.Item
                {...fieldProps}
                name={[name, 'promoBenefitTitle']}
                rules={[
                  {
                    required: customRequired,
                    message: 'Title is required',
                    whitespace: true,
                  },
                  ...(customRequired
                    ? [
                        {
                          max: 50,
                          message: 'The maximum number of characters should not exceed 50.',
                        },
                      ]
                    : []),
                ]}
              >
                <Input label="Title" />
              </Form.Item>
              <MarkdownTip>
                <Form.Item
                  {...fieldProps}
                  name={[name, 'promoBenefitDescription']}
                  rules={[
                    {
                      required: customRequired,
                      message: 'Description is required',
                      whitespace: true,
                    },
                    ...(customRequired
                      ? [
                          {
                            max: 150,
                            message: 'The maximum number of characters should not exceed 150.',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Textarea label="Description" />
                </Form.Item>
              </MarkdownTip>
            </div>
            <Form.Item
              {...fieldProps}
              preserve={false}
              valuePropName="icon"
              name={[name, 'thumbnailId']}
              rules={[
                {
                  required: customRequired,
                  message: 'Thumbnail is required',
                },
              ]}
            >
              <IconsGallery />
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
};
