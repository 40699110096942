import type { FC } from 'react';

import cn from 'classnames';

import { Markdown } from '@saturn/uikit';

import styles from './Hyphenated.module.scss';

export type HyphenatedProps = {
  children: string;
  className?: string;
  withMarkdown?: boolean;
  wrapperComponent?: 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  isTableTransformedUpperCase?: boolean;
};

export const Hyphenated: FC<HyphenatedProps> = ({
  children,
  className,
  withMarkdown,
  wrapperComponent = 'div',
  isTableTransformedUpperCase = true,
}) => {
  const Wrapper = wrapperComponent;
  if (withMarkdown) {
    return (
      <Wrapper className={cn(className, styles.common)}>
        <Markdown isTableTransformedUpperCase={isTableTransformedUpperCase} content={children} />
      </Wrapper>
    );
  }
  return (
    <Wrapper className={cn(className, styles.common)}>
      {children ? children.replaceAll('&shy;', '\u00AD') : children}
    </Wrapper>
  );
};
