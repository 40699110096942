import type { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';

import { admin } from '@saturn/api';
import { PromoEventBasicDto } from '@saturn/api/src/models/dto/PromoEventDto';
import { Icon, Popconfirm, Spin, Switch, Table, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import styles from './styles.module.scss';

const EventsPage: FC = () => {
  const { adminLocation } = useAdminLocation();
  const location = useLocation();

  const {
    data: locationEvents = [],
    loading,
    refresh,
  } = useRequest(() => admin.getPromoEventsList(adminLocation.id), {
    refreshDeps: [adminLocation.id],
  });

  const { runAsync: enablePromoEvent } = useRequest(admin.enablePromoEvent, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Event has been enabled successfully.',
        duration: 5,
      });
    },
  });
  const { runAsync: disablePromoEvent } = useRequest(admin.disablePromoEvent, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Event has been disabled successfully.',
        duration: 5,
      });
    },
  });
  const { run: deletePromoEvent, loading: deleting } = useRequest(admin.deletePromoEvent, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promo event has been successfully deleted',
        duration: 5,
      });
    },
    onError: () => {
      notification.error({
        message: 'Error while deleting promo event',
        duration: 5,
      });
    },
  });

  return (
    <main>
      <PageHeader title="Events" />
      <div className={styles.buttonContainer}>
        <Link type="primary" className="ant-btn ant-btn-primary" to="create">
          <span>Create event</span>
        </Link>
      </div>
      <Spin spinning={loading || deleting} indicator={<Icon name="sync-outline" />}>
        <Table
          rowKey={record => record.id || ''}
          columns={[
            {
              title: 'Name',
              dataIndex: 'systemName',
            },
            {
              title: 'Updated at',
              dataIndex: 'updatedAt',
              render: (text: string) => dayjs(text).format('MMM D, YYYY'),
            },
            {
              title: 'Edit',
              dataIndex: 'id',
              render: (id?: string) => {
                const path = `${location.pathname}/${id}`.replaceAll(/\/{2,}/gi, '/');

                return id ? (
                  <Link data-key="action-edit" to={path}>
                    <Icon name="edit-2-outline" />
                  </Link>
                ) : null;
              },
              width: 100,
            },
            {
              title: 'Active',
              dataIndex: 'visible',
              width: 150,
              render: (visible: boolean, record: PromoEventBasicDto) => {
                const { id } = record;
                return id ? (
                  <Switch
                    checkedChildren="yes"
                    unCheckedChildren="no"
                    checked={visible}
                    data-key="action-active"
                    onChange={() => (visible ? disablePromoEvent(id) : enablePromoEvent(id))}
                  />
                ) : null;
              },
            },

            {
              title: 'Delete',
              dataIndex: 'id',
              width: 80,
              render: function deleteUserAction(id?: string) {
                return id ? (
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure you want to delete this promo event?"
                    onConfirm={() => deletePromoEvent(id)}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Icon data-key="action-delete" color="red" size={20} name="close-circle-outlined" />
                  </Popconfirm>
                ) : null;
              },
            },
          ]}
          dataSource={locationEvents}
          pagination={false}
        />
      </Spin>
    </main>
  );
};

export default EventsPage;
