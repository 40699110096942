import { FC, ReactNode } from 'react';

import { Form, Option, Select, Title } from '@saturn/uikit';

import { ContentBlockContainer } from 'shared/components';

import { ProviderOption } from '../../utils';
import { Tip } from '../Tip';

import styles from './InsurancePartnersBlock.module.scss';

interface InsurancePartnersBlockProps {
  name: string[];
  maxItems: number;
  providers: ProviderOption[];
  selectedInsurancePartners: string[];
  title?: ReactNode;
  titleTip?: ReactNode;
  blockTip?: ReactNode;
  addButtonText?: string;
}

const InsurancePartnersBlock: FC<InsurancePartnersBlockProps> = ({
  titleTip,
  blockTip,
  name,
  maxItems,
  title,
  addButtonText = 'Add partner',
  providers,
  selectedInsurancePartners,
}) => {
  return (
    <div className={styles.content}>
      {titleTip && <Tip>{titleTip}</Tip>}
      {title && (
        <Title className={styles.title} level={4}>
          {title}
        </Title>
      )}
      {blockTip && <Tip>{blockTip}</Tip>}
      <ContentBlockContainer maxItems={maxItems} title="Partner" addButtonText={addButtonText} name={name}>
        {({ name: blockName, required, ...rest }) => {
          return (
            <>
              <Form.Item
                name={[blockName]}
                valuePropName="value"
                rules={[
                  {
                    required,
                    message: 'Partner is required',
                  },
                ]}
                {...rest}
              >
                <Select placeholder="Partner">
                  {providers?.map(item => (
                    <Option
                      key={item.value}
                      data-key={item.value}
                      value={item.value}
                      disabled={selectedInsurancePartners?.some(partnerId => partnerId === item.value)}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </ContentBlockContainer>
    </div>
  );
};
export default InsurancePartnersBlock;
