import axios from 'axios';
import type { AxiosError } from 'axios';

import { getEnvVariable } from '../utils';

// Saturn axios client (this one is used mostly for admin site)
export const axiosClient = axios.create({
  baseURL: getEnvVariable('API_ENDPOINT') ?? getEnvVariable('REACT_APP_API_ENDPOINT'),
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  config => config,
  error => {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (typeof window === 'undefined' && process.env.NODE_ENV !== 'test') {
      console.error(
        `Error while ${error.config?.method?.toUpperCase()} ${error.config?.url} ${error.response?.status} - ${
          error.message
        }`,
      );
      if (error.config?.data) {
        console.error('Request body', JSON.parse(error.config?.data));
      }
      if (error.response?.data) {
        console.error('Response body', error.response?.data);
      }
    }
    return Promise.reject(error);
  },
);
