import type { CSSProperties, FC, ReactNode } from 'react';

import { Spin as AntSpin } from 'antd';
import type { SpinProps as AntSpinProps } from 'antd';
import cn from 'classnames';

import { Icon } from '../Icon';

import styles from './Spin.module.scss';

export interface SpinProps extends AntSpinProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Spin: FC<SpinProps> = props => {
  const { className, indicator = <Icon name="sync-outline" />, children, ...rest } = props;

  return (
    <div className={styles.saturnSpinWrapper}>
      <AntSpin indicator={indicator} className={cn(className, 'saturn-spin', styles.saturnSpin)} {...rest}>
        {children}
      </AntSpin>
    </div>
  );
};
