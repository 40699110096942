import type { FC, HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from 'react';
import { useCallback } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { URL_RULE } from '@saturn/api';
import { ModalConfirm } from '@saturn/uikit';

import { WARNING_LABELS } from '../../constants';

import styles from './ExtendedLink.module.scss';

const WHITELIST_DOMAINS = ['com', 'sg', 'fr', 'it', 'hk', 'in', 'jp', 'ar', 'de', 'es', 'th'];

const AMEX_SITES = [
  'americanexpress.com',
  'americanexpress.it',
  'americanexpress.fr',
  'americanexpress.de',
  'americanexpress.jp',
  'cdn.e1.insurance.arkapply.com',
  'cdn.e2.insurance.arkapply.com',
  'cdn.e3.insurance.arkapply.com',
  'go.amex',
  'm.amex',
];

function getDomain(host: string) {
  return host.split('.').slice(-2);
}

function isLeaveCurrentSite(url: string, isShow?: boolean) {
  if (isShow != null) {
    return isShow;
  }

  if (!url || !new RegExp(URL_RULE, 'gi').test(url)) {
    return false;
  }

  const { host, search, href } = new URL(url);
  const params = new URLSearchParams(search);

  // don't show popup if the link is pdf
  if (
    href.split('.').at(-1) === 'pdf' ||
    params.get('file_name')?.split('.').at(-1) === 'pdf' ||
    params.get('accept') === 'application/pdf'
  ) {
    return false;
  }

  // don't show popup if the link leads to amex page
  if (AMEX_SITES.some(site => host.includes(site))) {
    return false;
  }

  const to = getDomain(host);
  const from = getDomain(window.location.hostname);

  return to[0] !== from[0] || (to[1] !== from[1] && !WHITELIST_DOMAINS.includes(to[1]));
}

export function useExtendedLinkHandler({
  href,
  onClick,
  isShowLeaveWarning,
}: {
  href: string;
  onClick?: MouseEventHandler<Element>;
  isShowLeaveWarning?: boolean;
}) {
  const { formatMessage } = useIntl();

  const clickHandler: MouseEventHandler<Element> = useCallback(
    event => {
      if (!isLeaveCurrentSite(href, isShowLeaveWarning)) {
        onClick?.(event);
      }
      if (isLeaveCurrentSite(href, isShowLeaveWarning)) {
        event.stopPropagation();
        event.preventDefault();
        ModalConfirm({
          title: formatMessage(
            defineMessage({
              id: 'warning.leave-amex-page-tile',
              description: 'redirect popup title',
              defaultMessage: 'You are being redirected',
            }),
          ),
          className: styles.wrapper,
          content: (
            <>
              <p>{formatMessage(WARNING_LABELS?.leaveAmexPageP1)}</p>
              <p>{formatMessage(WARNING_LABELS?.leaveAmexPageP2)}</p>
            </>
          ),
          okText: (
            <span data-cy="leave-website-confirmation">
              {formatMessage(
                defineMessage({ id: 'continue', description: 'Continue btn text', defaultMessage: 'Continue' }),
              )}
            </span>
          ),
          cancelText: formatMessage(
            defineMessage({ id: 'cancel', description: 'Cancel btn text', defaultMessage: 'Cancel' }),
          ),
          onOk: () => {
            onClick?.(event);
            window.open(href, '_blank');
          },
        });
      }
    },
    [formatMessage, isShowLeaveWarning, href],
  );

  return clickHandler;
}

interface ExtendedLinkProps {
  href: string;
  className?: string;
  children?: ReactNode;
  isShowLeaveWarning?: boolean;
  target?: HTMLAttributeAnchorTarget;
  title?: string;
  rel?: 'noreferrer' | string;
  onClick?: MouseEventHandler<Element>;
}

export const ExtendedLink: FC<ExtendedLinkProps> = ({
  isShowLeaveWarning,
  className,
  children,
  href,
  target,
  title,
  rel,
  onClick,
  ...restProps
}) => {
  const clickHandler = useExtendedLinkHandler({ href, onClick, isShowLeaveWarning });
  return (
    <a className={className} target={target} rel={rel} title={title} href={href} {...restProps} onClick={clickHandler}>
      <span>{children}</span>
    </a>
  );
};
