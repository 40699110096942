import type { FC } from 'react';

import type { RuleType } from '@saturn/uikit';
import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, MarkdownTip, VisibilitySwitcher } from 'shared/components';

type StripeProps = {
  header?: string;
};

export const Stripe: FC<StripeProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Stripe'} name={['stripe']} showSectionTitle>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isSectionVisible = getFieldValue(['stripe', 'visible']);
          return (
            <>
              <MarkdownTip>
                <Form.Item
                  name={['stripe', 'sectionDescription']}
                  rules={[
                    {
                      required: isSectionVisible,
                      message: 'Description is required',
                      whitespace: true,
                    },
                    ...(isSectionVisible
                      ? [
                          {
                            max: 255,
                            message: 'Description should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Textarea label="Description" />
                </Form.Item>
              </MarkdownTip>
              <VisibilitySwitcher title={'Call to Action Button'} name={['stripe', 'callToActionButton', 'visible']} />
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const isFieldRequired = getFieldValue(['stripe', 'callToActionButton', 'visible']);
                  return (
                    <>
                      <Form.Item
                        name={['stripe', 'callToActionButton', 'title']}
                        rules={[
                          {
                            required: isSectionVisible && isFieldRequired,
                            message: 'Title is required',
                            whitespace: true,
                          },
                          ...(isSectionVisible && isFieldRequired
                            ? [
                                {
                                  max: 75,
                                  message: 'Title should be at most 75 characters',
                                },
                              ]
                            : []),
                        ]}
                      >
                        <Input label="Title" />
                      </Form.Item>
                      <Form.Item
                        name={['stripe', 'callToActionButton', 'url']}
                        rules={[
                          {
                            required: isSectionVisible && isFieldRequired,
                            message: 'URL is required',
                            whitespace: true,
                          },
                          ...(isSectionVisible && isFieldRequired
                            ? [
                                {
                                  max: 255,
                                  message: 'URL should be at most 255 characters',
                                },
                                {
                                  type: 'url' as RuleType,
                                  message: 'URL is invalid',
                                },
                              ]
                            : []),
                        ]}
                      >
                        <Input label="URL" />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
