import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

import styles from './PolicyDetails.module.scss';

export const PolicyDetails = ({ header }: { header: string }) => {
  return (
    <CollapsiblePanel header={header || 'Policy details'} name={['policyDetailsContent']}>
      <div className={styles.content}>
        <Form.Item
          name={['policyDetailsContent', 'banner', 'title']}
          rules={[
            {
              required: true,
              message: 'Title is required!',
            },
            {
              max: 255,
              message: 'Title should be at most 255 characters',
            },
          ]}
        >
          <Input label="Title" />
        </Form.Item>
        <Form.Item
          name={['policyDetailsContent', 'banner', 'subtitle']}
          rules={[
            {
              required: true,
              message: 'Subtitle is required!',
            },
            {
              max: 255,
              message: 'Subtitle should be at most 255 characters',
            },
          ]}
        >
          <Textarea label="Subtitle" />
        </Form.Item>
        <Form.Item
          name={['policyDetailsContent', 'banner', 'url']}
          rules={[
            {
              type: 'url',
              message: 'Link is not valid',
            },
          ]}
        >
          <Input label="Link (optional)" />
        </Form.Item>
      </div>
    </CollapsiblePanel>
  );
};
