import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';

import cn from 'classnames';

import { Button, Icon, Modal } from '@saturn/uikit';

import { icons as defaultIcons } from './constants';

import styles from './IconsGallery.module.scss';

export interface IconsGalleryProps {
  icon?: string;
  icons?: string[];
  iconSize?: number;
  modalTitle?: string;
  onChange?: (iconSrc: string | undefined) => void;
  customErrorMessage?: string;
}

export const IconsGallery: FC<IconsGalleryProps> = ({
  icon,
  icons = defaultIcons,
  modalTitle = 'Icons',
  iconSize = 48,
  onChange,
  customErrorMessage,
}) => {
  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(undefined);
  const [currentIcon, setCurrentIcon] = useState<string | undefined>(icon);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setCurrentIcon(icon);
  }, [icon]);

  const handleOpenModal = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const handleOk = useCallback(() => {
    setCurrentIcon(selectedIcon);
    onChange && onChange(selectedIcon);
    setIsOpenModal(false);
  }, [selectedIcon, onChange]);

  const handleCancel = useCallback(() => {
    setSelectedIcon(currentIcon);
    setIsOpenModal(false);
  }, [currentIcon]);

  return (
    <>
      <div className={styles['icons-gallery']}>
        <p className={styles['icons-gallery__label']}>Thumbnail</p>
        {currentIcon && (
          <div className={styles['icons-gallery__icon-thumbnail']}>
            <Icon size={iconSize} name={currentIcon} color="#006fcf" />
          </div>
        )}
        <Button className={styles['icons-gallery__button']} onClick={handleOpenModal} type="link">
          {currentIcon ? 'change icon' : 'choose icon'}
        </Button>
        <Modal
          className={styles.modal}
          title={modalTitle}
          open={isOpenModal}
          onOk={handleOk}
          onCancel={handleCancel}
          width={750}
          centered
        >
          {icons.map(iconToSelect => {
            const className = cn(styles['icons-gallery__icon'], styles['modal-icons__icon'], {
              [styles['modal-icons__icon--active']]: selectedIcon === iconToSelect,
            });

            // TODO: use <button> instead of <div>
            /* eslint-disable  jsx-a11y/click-events-have-key-events */
            /* eslint-disable  jsx-a11y/no-static-element-interactions */
            return (
              <div key={iconToSelect} className={className} onClick={() => setSelectedIcon(iconToSelect)}>
                <Icon size={iconSize} name={iconToSelect} color="#006fcf" />
              </div>
            );
            /* eslint-enable  jsx-a11y/click-events-have-key-events */
            /* eslint-enable  jsx-a11y/no-static-element-interactions */
          })}
        </Modal>
      </div>
      {customErrorMessage && <span className="ant-form-item-explain-error">{customErrorMessage}</span>}
    </>
  );
};
