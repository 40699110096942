import { useMemo } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useRequest } from 'ahooks';
import type { ColumnsType } from 'antd/es/table';
import intersectionBy from 'lodash/intersectionBy';

import { ProductTranslationDto, admin } from '@saturn/api';
import { Spin, Table, notification } from '@saturn/uikit';

import { TranslationModal } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

export const ProductsTranslationsList: FC = () => {
  const { locationLanguage = 'en', adminLocation } = useAdminLocation();
  const locale = `${locationLanguage}-${adminLocation.id.toUpperCase()}`;
  const { productId = '' } = useParams();

  const {
    data: updatedData,
    run: updateTableData,
    loading: updatingData,
  } = useRequest(admin.updateTranslation, {
    manual: true,
    onSuccess: () => {
      notification.success({
        message: 'Translation has been successfully updated',
      });
    },
  });

  const { data: productTranslations = [], loading: loadingTranslations } = useRequest(
    () => admin.getTranslationsList(productId, locale),
    {
      refreshDeps: [adminLocation.id, locationLanguage, updatedData],
    },
  );

  const formFilters = useMemo(
    () =>
      intersectionBy(
        productTranslations.map(item => ({ text: item.formType, value: item.formType })),
        'value',
      ),
    [productTranslations],
  );

  const columns: ColumnsType<ProductTranslationDto> = useMemo(
    () => [
      {
        title: 'Form',
        dataIndex: 'formType',
        filters: formFilters,
        filterMode: 'menu',
        onFilter: (value, record) => record.formType.startsWith(value as string),
        width: '20%',
      },
      {
        title: 'Input Key',
        dataIndex: 'key',
      },
      {
        title: 'Field Name',
        dataIndex: 'fieldName',
      },
      {
        title: 'Translation Message',
        dataIndex: 'message',
      },
      {
        title: 'Edit',
        fixed: 'right',
        width: 100,
        render: (record: ProductTranslationDto) => {
          return <TranslationModal record={record} onTranslateQuoteForm={updateTableData} />;
        },
      },
    ],
    [formFilters, updateTableData],
  );

  return (
    <Spin spinning={loadingTranslations || updatingData}>
      <Table rowKey={record => record.key} columns={columns} dataSource={productTranslations} pagination={false} />
    </Spin>
  );
};
