export const AEROLAB_ICONS = {
  'al-lock': 'aerolab/aerolab/al-lock.svg',
  'al-notice': 'aerolab/al-notice.svg',
  'al-shield': 'aerolab/al-shield.svg',
  'al-location-marker': 'aerolab/al-location-marker.svg',
  'al-checkmark': 'aerolab/al-checkmark.svg',
  'al-download-outline': 'aerolab/al-download-outline.svg',
  'al-gift-box': 'aerolab/al-gift-box.svg',
  'al-gift': 'aerolab/al-gift.svg',
  'al-star-hollow': 'aerolab/al-star-hollow.svg',
  'al-arrow-left': 'aerolab/al-arrow-left.svg',
  'al-arrow-right': 'aerolab/al-arrow-right.svg',
  'al-bolt': 'aerolab/al-bolt.svg',
} as const;
