import type { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { ROLE_ADMIN } from '@saturn/api';
import { Option, Select } from '@saturn/uikit';

import { useAdminLocation, useAuth } from 'shared/utils';

import { NavigationMenu } from '../NavigationMenu';

import styles from './NavigationBar.module.scss';

export const NavigationBar: FC = () => {
  const { user, isAuthEnabled } = useAuth();
  const { adminLocation, setAvailableLocation, locations } = useAdminLocation();

  const onChange = (id: string) => {
    setAvailableLocation(id);
  };

  return (
    <aside className={styles.navBar}>
      <NavLink className="logo" to={`/${adminLocation.id}/locations`}>
        <img src="/images/logo.png" alt="American Express" />
      </NavLink>
      <Select
        placeholder="Location"
        value={adminLocation?.id}
        onChange={onChange}
        getPopupContainer={trigger => trigger.parentElement}
      >
        {locations?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
      <NavigationMenu adminLocation={adminLocation.id} role={isAuthEnabled ? user?.roles ?? [] : [ROLE_ADMIN]} />
    </aside>
  );
};
