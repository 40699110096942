export const LIBRARY_ICONS = {
  camera: 'lib/camera.svg',
  construction: 'lib/construction.svg',
  copy: 'lib/copy.svg',
  dental: 'lib/dental.svg',
  'dls-icon-account': 'lib/dls-icon-account.svg',
  'dls-icon-activity': 'lib/dls-icon-activity.svg',
  'dls-icon-airplane': 'lib/dls-icon-airplane.svg',
  'dls-icon-archive': 'lib/dls-icon-archive.svg',
  'dls-icon-bar-chart': 'lib/dls-icon-bar-chart.svg',
  'dls-icon-business': 'lib/dls-icon-business.svg',
  'dls-icon-calendar': 'lib/dls-icon-calendar.svg',
  'dls-icon-cancel-circle': 'lib/dls-icon-cancel-circle.svg',
  'dls-icon-car': 'lib/dls-icon-car.svg',
  'dls-icon-card': 'lib/dls-icon-card.svg',
  'dls-icon-card-contactless': 'lib/dls-icon-card-contactless.svg',
  'dls-icon-card-insert': 'lib/dls-icon-card-insert.svg',
  'dls-icon-cart': 'lib/dls-icon-cart.svg',
  'dls-icon-change': 'lib/dls-icon-change.svg',
  'dls-icon-chip': 'lib/dls-icon-chip.svg',
  'dls-icon-logout-outline-square': 'lib/log-out-outline-square.svg',
  'dls-icon-closed-captions-on': 'lib/dls-icon-closed-captions-on.svg',
  'dls-icon-concierge': 'lib/dls-icon-concierge.svg',
  'dls-icon-cruise-ship': 'lib/dls-icon-cruise-ship.svg',
  'dls-icon-data-protection': 'lib/dls-icon-data-protection.svg',
  'dls-icon-desktop': 'lib/dls-icon-desktop.svg',
  'dls-icon-dining': 'lib/dls-icon-dining.svg',
  'dls-icon-direction': 'lib/dls-icon-direction.svg',
  'dls-icon-dls-icon-line-graph': 'lib/dls-icon-dls-icon-line-graph.svg',
  'dls-icon-document': 'lib/dls-icon-document.svg',
  'dls-icon-dollar': 'lib/dls-icon-dollar.svg',
  'dls-icon-donate': 'lib/dls-icon-donate.svg',
  'dls-icon-edit': 'lib/dls-icon-edit.svg',
  'dls-icon-email': 'lib/dls-icon-email.svg',
  'dls-icon-entertainment': 'lib/dls-icon-entertainment.svg',
  'dls-icon-euro': 'lib/dls-icon-euro.svg',
  'dls-icon-favorite': 'lib/dls-icon-favorite.svg',
  'dls-icon-fraud-protection': 'lib/dls-icon-fraud-protection.svg',
  'dls-icon-geolocation': 'lib/dls-icon-geolocation.svg',
  'dls-icon-gift': 'lib/dls-icon-gift.svg',
  'dls-icon-gift-card': 'lib/dls-icon-gift-card.svg',
  'dls-icon-global': 'lib/dls-icon-global.svg',
  'dls-icon-grocery': 'lib/dls-icon-grocery.svg',
  'dls-icon-guide': 'lib/dls-icon-guide.svg',
  'dls-icon-home': 'lib/dls-icon-home.svg',
  'dls-icon-hotel': 'lib/dls-icon-hotel.svg',
  'dls-icon-insurance': 'lib/dls-icon-insurance.svg',
  'dls-icon-instant': 'lib/dls-icon-instant.svg',
  'dls-icon-laptop': 'lib/dls-icon-laptop.svg',
  'dls-icon-location': 'lib/dls-icon-location.svg',
  'dls-icon-location-services': 'lib/dls-icon-location-services.svg',
  'dls-icon-lock': 'lib/dls-icon-lock.svg',
  'dls-icon-membership': 'lib/dls-icon-membership.svg',
  'dls-icon-merchandise': 'lib/dls-icon-merchandise.svg',
  'dls-icon-mobile': 'lib/dls-icon-mobile.svg',
  'dls-icon-more': 'lib/dls-icon-more.svg',
  'dls-icon-paperless': 'lib/dls-icon-paperless.svg',
  'dls-icon-payment-due': 'lib/dls-icon-payment-due.svg',
  'dls-icon-pdf': 'lib/dls-icon-pdf.svg',
  'dls-icon-pet': 'lib/dls-icon-pet.svg',
  'dls-icon-plus-circle': 'lib/dls-icon-plus-circle.svg',
  'dls-icon-pound': 'lib/dls-icon-pound.svg',
  'dls-icon-receipt': 'lib/dls-icon-receipt.svg',
  'dls-icon-receipt-view': 'lib/dls-icon-receipt-view.svg',
  'dls-icon-refreshment': 'lib/dls-icon-refreshment.svg',
  'dls-icon-round-the-clock': 'lib/dls-icon-round-the-clock.svg',
  'dls-icon-rupee': 'lib/dls-icon-rupee.svg',
  'dls-icon-savings': 'lib/dls-icon-savings.svg',
  'dls-icon-search': 'lib/dls-icon-search.svg',
  'dls-icon-setting': 'lib/dls-icon-setting.svg',
  'dls-icon-shipping-truck': 'lib/dls-icon-shipping-truck.svg',
  'dls-icon-show': 'lib/dls-icon-show.svg',
  'dls-icon-tablet': 'lib/dls-icon-tablet.svg',
  'dls-icon-thumbs-up': 'lib/dls-icon-thumbs-up.svg',
  'dls-icon-time': 'lib/dls-icon-time.svg',
  'dls-icon-train': 'lib/dls-icon-train.svg',
  'dls-icon-travel-bag': 'lib/dls-icon-travel-bag.svg',
  'dls-icon-unlock': 'lib/dls-icon-unlock.svg',
  'dls-icon-watch': 'lib/dls-icon-watch.svg',
  'dls-icon-yen': 'lib/dls-icon-yen.svg',
  'face-id': 'lib/face-id.svg',
  filter: 'lib/filter.svg',
  flash: 'lib/flash.svg',
  'freeze-card': 'lib/freeze-card.svg',
  'lock-card': 'lib/lock-card.svg',
  medal: 'lib/medal.svg',
  medical: 'lib/medical.svg',
  partnership: 'lib/partnership.svg',
  'qr-scan': 'lib/qr-scan.svg',
  spa: 'lib/spa.svg',
  watch: 'lib/watch.svg',
  'dls-icon-accessibility-filled': 'lib/dls-icon-accessibility-filled.svg',
  'dls-icon-account-filled': 'lib/dls-icon-account-filled.svg',
  'dls-icon-bank': 'lib/dls-icon-bank.svg',
  'dls-icon-airplane-filled': 'lib/dls-icon-airplane-filled.svg',
  'dls-icon-autopay': 'lib/dls-icon-autopay.svg',
  'dls-icon-baht-autopay-filled': 'lib/dls-icon-baht-autopay-filled.svg',
  'dls-icon-billing.svg': 'lib/dls-icon-billing.svg',
  'dls-icon-bookmark': 'lib/dls-icon-bookmark.svg',
  'dls-icon-business-services': 'lib/dls-icon-business-services.svg',
  'dls-icon-calendar-filled': 'lib/dls-icon-calendar-filled.svg',
  'dls-icon-captions-off-filled': 'lib/dls-icon-captions-off-filled.svg',
  'dls-icon-captions-off': 'lib/dls-icon-captions-off.svg',
  'dls-icon-card-contactless-filled': 'lib/dls-icon-card-contactless-filled.svg',
  'dls-icon-card-insert-filled': 'lib/dls-icon-card-insert-filled.svg',
  'dls-icon-card-swipe-filled': 'lib/dls-icon-card-swipe-filled.svg',
  'dls-icon-card-tap-filled': 'lib/dls-icon-card-tap-filled.svg',
  'dls-icon-card-tap': 'lib/dls-icon-card-tap.svg',
  'dls-icon-cards-contactless': 'lib/dls-icon-cards-contactless.svg',
  'dls-icon-cards': 'lib/dls-icon-cards.svg',
  'dls-icon-chat': 'lib/dls-icon-chat.svg',
  'dls-icon-check-banking-filled': 'lib/dls-icon-check-banking-filled.svg',
  'dls-icon-check-scan-filled': 'lib/dls-icon-check-scan-filled.svg',
  'dls-icon-check-scan': 'lib/dls-icon-check-scan.svg',
  'dls-icon-concierge-filled': 'lib/dls-icon-concierge-filled.svg',
  'dls-icon-construction-filled': 'lib/dls-icon-construction-filled.svg',
  'dls-icon-copy-filled': 'lib/dls-icon-copy-filled.svg',
  'dls-icon-cruise-ship-filled': 'lib/dls-icon-cruise-ship-filled.svg',
  'dls-icon-data-protection-filled': 'lib/dls-icon-data-protection-filled.svg',
  'dls-icon-dental-filled': 'lib/dls-icon-dental-filled.svg',
  'dls-icon-dining-filled': 'lib/dls-icon-dining-filled.svg',
  'dls-icon-direct-deposit-filled': 'lib/dls-icon-direct-deposit-filled.svg',
  'dls-icon-document-filled': 'lib/dls-icon-document-filled.svg',
  'dls-icon-down-filled': 'lib/dls-icon-down-filled.svg',
  'dls-icon-e-check': 'lib/dls-icon-e-check.svg',
  'dls-icon-entertainment-filled': 'lib/dls-icon-entertainment-filled.svg',
  'dls-icon-euro-cashback': 'lib/dls-icon-euro-cashback.svg',
  'dls-icon-euro-cashback-filled': 'lib/dls-icon-euro-cashback-filled.svg',
  'dls-icon-expand-filled': 'lib/dls-icon-expand-filled.svg',
  'dls-icon-feedback': 'lib/dls-icon-feedback.svg',
  'dls-icon-fraud-protection-filled': 'lib/dls-icon-fraud-protection-filled.svg',
  'dls-icon-freeze-card-filled.svg': 'lib/dls-icon-freeze-card-filled.svg',
  'dls-icon-gift-filled': 'lib/dls-icon-gift-filled.svg',
  'dls-icon-grocery-filled': 'lib/dls-icon-grocery-filled.svg',
  'dls-icon-guide-filled': 'lib/dls-icon-guide-filled.svg',
  'dls-icon-help-filled': 'lib/dls-icon-help-filled.svg',
  'dls-icon-hide': 'lib/dls-icon-hide.svg',
  'dls-icon-hide-filled': 'lib/dls-icon-hide-filled.svg',
  'dls-icon-high-yield-filled': 'lib/dls-icon-high-yield-filled.svg',
  'dls-icon-home-filled': 'lib/dls-icon-home-filled.svg',
  'dls-icon-infinity.svg': 'lib/dls-icon-infinity.svg',
  'dls-icon-krone-cashback': 'lib/dls-icon-krone-cashback.svg',
  'dls-icon-krone-filled': 'lib/dls-icon-krone-filled.svg',
  'dls-icon-laptop-filled': 'lib/dls-icon-laptop-filled.svg',
  'dls-icon-launch': 'lib/dls-icon-launch.svg',
  'dls-icon-left': 'lib/dls-icon-left.svg',
  'dls-icon-line-graph': 'lib/dls-icon-line-graph.svg',
  'dls-icon-lock-card-filled': 'lib/dls-icon-lock-card-filled.svg',
  'dls-icon-lock-filled': 'lib/dls-icon-lock-filled.svg',
  'dls-icon-lounge-filled': 'lib/dls-icon-lounge-filled.svg',
  'dls-icon-medical-filled': 'lib/dls-icon-medical-filled.svg',
  'dls-icon-menu-filled': 'lib/dls-icon-menu-filled.svg',
  'dls-icon-minus': 'lib/dls-icon-minus.svg',
  'dls-icon-multi-channel': 'lib/dls-icon-multi-channel.svg',
  'dls-icon-neutral-filled': 'lib/dls-icon-neutral-filled.svg',
  'dls-icon-no-fee': 'lib/dls-icon-no-fee.svg',
  'dls-icon-offers-mobile': 'lib/dls-icon-offers-mobile.svg',
  'dls-icon-overdraft-protection': 'lib/dls-icon-overdraft-protection.svg',
  'dls-icon-p2p': 'lib/dls-icon-p2p.svg',
  'dls-icon-p2p-filled': 'lib/dls-icon-p2p-filled.svg',
  'dls-icon-partnership-filled': 'lib/dls-icon-partnership-filled.svg',
  'dls-icon-pause': 'lib/dls-icon-pause.svg',
  'dls-icon-pause-filled': 'lib/dls-icon-pause-filled.svg',
  'dls-icon-play': 'lib/dls-icon-play.svg',
  'dls-icon-plus-circle-filled': 'lib/dls-icon-plus-circle-filled.svg',
  'dls-icon-point-8x-filled': 'lib/dls-icon-point-8x-filled.svg',
  'dls-icon-point-20k': 'lib/dls-icon-point-20k.svg',
  'dls-icon-pound-autopay': 'lib/dls-icon-pound-autopay.svg',
  'dls-icon-pound-cashback-filled': 'lib/dls-icon-pound-cashback-filled.svg',
  'dls-icon-pound-filled': 'lib/dls-icon-pound-filled.svg',
  'dls-icon-pound-no-fee': 'lib/dls-icon-pound-no-fee.svg',
  'dls-icon-pound-no-fee-filled': 'lib/dls-icon-pound-no-fee-filled.svg',
  'dls-icon-print': 'lib/dls-icon-print.svg',
  'dls-icon-quick-transfer-filled': 'lib/dls-icon-quick-transfer-filled.svg',
  'dls-icon-recent-points-filled': 'lib/dls-icon-recent-points-filled.svg',
  'dls-icon-retirement-filled': 'lib/dls-icon-retirement-filled.svg',
  'dls-icon-rewards-filled': 'lib/dls-icon-rewards-filled.svg',
  'dls-icon-rupee-autopay': 'lib/dls-icon-rupee-autopay.svg',
  'dls-icon-search-filled': 'lib/dls-icon-search-filled.svg',
  'dls-icon-security': 'lib/dls-icon-security.svg',
  'dls-icon-share': 'lib/dls-icon-share.svg',
  'dls-icon-show-filled': 'lib/dls-icon-show-filled.svg',
  'dls-icon-social': 'lib/dls-icon-social.svg',
  'dls-icon-social-filled': 'lib/dls-icon-social-filled.svg',
  'dls-icon-sort-down': 'lib/dls-icon-sort-down.svg',
  'dls-icon-sort-up': 'lib/dls-icon-sort-up.svg',
  'dls-icon-sound-off-filled': 'lib/dls-icon-sound-off-filled.svg',
  'dls-icon-source': 'lib/dls-icon-source.svg',
  'dls-icon-split-filled': 'lib/dls-icon-split-filled.svg',
  'dls-icon-statement-paid': 'lib/dls-icon-statement-paid.svg',
  'dls-icon-statement-paid-filled': 'lib/dls-icon-statement-paid-filled.svg',
  'dls-icon-success': 'lib/dls-icon-success.svg',
  'dls-icon-tap-to-pay': 'lib/dls-icon-tap-to-pay.svg',
  'dls-icon-taxi-filled': 'lib/dls-icon-taxi-filled.svg',
  'dls-icon-telephone': 'lib/dls-icon-telephone.svg',
  'dls-icon-telephone-filled': 'lib/dls-icon-telephone-filled.svg',
  'dls-icon-thumbs-down': 'lib/dls-icon-thumbs-down.svg',
  'dls-icon-thumbs-down-filled': 'lib/dls-icon-thumbs-down-filled.svg',
  'dls-icon-train-filled': 'lib/dls-icon-train-filled.svg',
  'dls-icon-transfer-filled': 'lib/dls-icon-transfer-filled.svg',
  'dls-icon-travel-bag-filled': 'lib/dls-icon-travel-bag-filled.svg',
  'dls-icon-trends': 'lib/dls-icon-trends.svg',
  'dls-icon-up': 'lib/dls-icon-up.svg',
  'dls-icon-wifi-off': 'lib/dls-icon-wifi-off.svg',
  'dls-icon-wifi-off-filled': 'lib/dls-icon-wifi-off-filled.svg',
  'dls-icon-yen-autopay': 'lib/dls-icon-yen-autopay.svg',
  'dls-icon-yen-autopay-filled': 'lib/dls-icon-yen-autopay-filled.svg',
  Burglary_or_Robbery_of_Gadget: 'lib/Insurance-Specific/Burglary_or_Robbery_of_Gadget.svg',
  Burglary_or_Robbery_of_Gadget_bg: 'lib/Insurance-Specific/Burglary_or_Robbery_of_Gadget_bg.svg',
  Day_Surgery_Benefit: 'lib/Insurance-Specific/Day_Surgery_Benefit.svg',
  Day_Surgery_Benefit_bg: 'lib/Insurance-Specific/Day_Surgery_Benefit_bg.svg',
  Inbox_accessories: 'lib/Insurance-Specific/Inbox_accessories.svg',
  Inbox_accessories_bg: 'lib/Insurance-Specific/Inbox_accessories_bg.svg',
  Lump_Sum_Critical_Illness_Benefit: 'lib/Insurance-Specific/Lump_Sum_Critical_Illness_Benefit.svg',
  Lump_Sum_Critical_Illness_Benefit_bg: 'lib/Insurance-Specific/Lump_Sum_Critical_Illness_Benefit_bg.svg',
  Surgery_Cash_Benefit: 'lib/Insurance-Specific/Surgery_Cash_Benefit.svg',
  Surgery_Cash_Benefit_bg: 'lib/Insurance-Specific/Surgery_Cash_Benefit_bg.svg',
  Annual_Multi_Trip: 'lib/Insurance-Specific/Annual_Multi_Trip.svg',
  Family: 'lib/Insurance-Specific/Family.svg',
  'dls-icon-accessibility': 'lib/dls-icon-accessibility.svg',
  'dls-icon-activity-filled': 'lib/dls-icon-activity-filled.svg',
  'dls-icon-alert': 'lib/dls-icon-alert.svg',
  'dls-icon-alert-filled': 'lib/dls-icon-alert-filled.svg',
  'dls-icon-archive-filled': 'lib/dls-icon-archive-filled.svg',
  'dls-icon-attach': 'lib/dls-icon-attach.svg',
  'dls-icon-attach-filled': 'lib/dls-icon-attach-filled.svg',
  'dls-icon-autopay-filled': 'lib/dls-icon-autopay-filled.svg',
  'dls-icon-baht': 'lib/dls-icon-baht.svg',
  'dls-icon-baht-autopay': 'lib/dls-icon-baht-autopay.svg',
  'dls-icon-baht-cashback': 'lib/dls-icon-baht-cashback.svg',
  'dls-icon-baht-cashback-filled': 'lib/dls-icon-baht-cashback-filled.svg',
  'dls-icon-baht-filled': 'lib/dls-icon-baht-filled.svg',
  'dls-icon-bank-filled': 'lib/dls-icon-bank-filled.svg',
  'dls-icon-bar-chart-filled': 'lib/dls-icon-bar-chart-filled.svg',
  'dls-icon-billing-filled': 'lib/dls-icon-billing-filled.svg',
  'dls-icon-bookmark-filled': 'lib/dls-icon-bookmark-filled.svg',
  'dls-icon-business-filled': 'lib/dls-icon-business-filled.svg',
  'dls-icon-business-services-filled': 'lib/dls-icon-business-services-filled.svg',
  'dls-icon-calculator': 'lib/dls-icon-calculator.svg',
  'dls-icon-calculator-filled': 'lib/dls-icon-calculator-filled.svg',
  'dls-icon-camera-filled': 'lib/dls-icon-camera-filled.svg',
  'dls-icon-cancel-circle-filled': 'lib/dls-icon-cancel-circle-filled.svg',
  'dls-icon-captions-on': 'lib/dls-icon-captions-on.svg',
  'dls-icon-captions-on-filled': 'lib/dls-icon-captions-on-filled.svg',
  'dls-icon-card-benefit': 'lib/dls-icon-card-benefit.svg',
  'dls-icon-card-benefit-filled': 'lib/dls-icon-card-benefit-filled.svg',
  'dls-icon-card-filled': 'lib/dls-icon-card-filled.svg',
  'dls-icon-cards-contactless-filled': 'lib/dls-icon-cards-contactless-filled.svg',
  'dls-icon-cards-filled': 'lib/dls-icon-cards-filled.svg',
  'dls-icon-card-swipe': 'lib/dls-icon-card-swipe.svg',
  'dls-icon-car-filled': 'lib/dls-icon-car-filled.svg',
  'dls-icon-cart-filled': 'lib/dls-icon-cart-filled.svg',
  'dls-icon-cashback': 'lib/dls-icon-cashback.svg',
  'dls-icon-cashback-filled': 'lib/dls-icon-cashback-filled.svg',
  'dls-icon-cd': 'lib/dls-icon-cd.svg',
  'dls-icon-cd-filled': 'lib/dls-icon-cd-filled.svg',
  'dls-icon-change-filled': 'lib/dls-icon-change-filled.svg',
  'dls-icon-chat-filled': 'lib/dls-icon-chat-filled.svg',
  'dls-icon-check': 'lib/dls-icon-check.svg',
  'dls-icon-check-banking': 'lib/dls-icon-check-banking.svg',
  'dls-icon-check-filled': 'lib/dls-icon-check-filled.svg',
  'dls-icon-chip-filled': 'lib/dls-icon-chip-filled.svg',
  'dls-icon-close': 'lib/dls-icon-close.svg',
  'dls-icon-close-filled': 'lib/dls-icon-close-filled.svg',
  'dls-icon-collapse': 'lib/dls-icon-collapse.svg',
  'dls-icon-collapse-filled': 'lib/dls-icon-collapse-filled.svg',
  'dls-icon-credit-score': 'lib/dls-icon-credit-score.svg',
  'dls-icon-credit-score-filled': 'lib/dls-icon-credit-score-filled.svg',
  'dls-icon-declined': 'lib/dls-icon-declined.svg',
  'dls-icon-declined-filled': 'lib/dls-icon-declined-filled.svg',
  'dls-icon-dental': 'lib/dls-icon-dental.svg',
  'dls-icon-desktop-filled': 'lib/dls-icon-desktop-filled.svg',
  'dls-icon-direct-deposit': 'lib/dls-icon-direct-deposit.svg',
  'dls-icon-direction-filled': 'lib/dls-icon-direction-filled.svg',
  'dls-icon-dollar-filled': 'lib/dls-icon-dollar-filled.svg',
  'dls-icon-donate-filled': 'lib/dls-icon-donate-filled.svg',
  'dls-icon-down': 'lib/dls-icon-down.svg',
  'dls-icon-download': 'lib/dls-icon-download.svg',
  'dls-icon-download-filled': 'lib/dls-icon-download-filled.svg',
  'dls-icon-e-check-filled': 'lib/dls-icon-e-check-filled.svg',
  'dls-icon-edit-filled': 'lib/dls-icon-edit-filled.svg',
  'dls-icon-email-filled': 'lib/dls-icon-email-filled.svg',
  'dls-icon-euro-autopay': 'lib/dls-icon-euro-autopay.svg',
  'dls-icon-euro-autopay-filled': 'lib/dls-icon-euro-autopay-filled.svg',
  'dls-icon-euro-filled': 'lib/dls-icon-euro-filled.svg',
  'dls-icon-expand': 'lib/dls-icon-expand.svg',
  'dls-icon-faceid-filled': 'lib/dls-icon-faceid-filled.svg',
  'dls-icon-favorite-filled': 'lib/dls-icon-favorite-filled.svg',
  'dls-icon-feedback-filled': 'lib/dls-icon-feedback-filled.svg',
  'dls-icon-filter': 'lib/dls-icon-filter.svg',
  'dls-icon-filter-filled': 'lib/dls-icon-filter-filled.svg',
  'dls-icon-flash': 'lib/dls-icon-flash.svg',
  'dls-icon-flash-filled': 'lib/dls-icon-flash-filled.svg',
  'dls-icon-flash-off': 'lib/dls-icon-flash-off.svg',
  'dls-icon-flash-off-filled': 'lib/dls-icon-flash-off-filled.svg',
  'dls-icon-gas-station': 'lib/dls-icon-gas-station.svg',
  'dls-icon-gas-station-filled': 'lib/dls-icon-gas-station-filled.svg',
  'dls-icon-geolocation-filled': 'lib/dls-icon-geolocation-filled.svg',
  'dls-icon-gift-card-filled': 'lib/dls-icon-gift-card-filled.svg',
  'dls-icon-global-filled': 'lib/dls-icon-global-filled.svg',
  'dls-icon-grid': 'lib/dls-icon-grid.svg',
  'dls-icon-grid-filled': 'lib/dls-icon-grid-filled.svg',
  'dls-icon-hearing-impaired': 'lib/dls-icon-hearing-impaired.svg',
  'dls-icon-hearing-impaired-filled': 'lib/dls-icon-hearing-impaired-filled.svg',
  'dls-icon-help': 'lib/dls-icon-help.svg',
  'dls-icon-high-yield': 'lib/dls-icon-high-yield.svg',
  'dls-icon-hotel-filled': 'lib/dls-icon-hotel-filled.svg',
  'dls-icon-infinity-filled': 'lib/dls-icon-infinity-filled.svg',
  'dls-icon-info': 'lib/dls-icon-info.svg',
  'dls-icon-info-filled': 'lib/dls-icon-info-filled.svg',
  'dls-icon-instant-filled': 'lib/dls-icon-instant-filled.svg',
  'dls-icon-insurance-filled': 'lib/dls-icon-insurance-filled.svg',
  'dls-icon-krone': 'lib/dls-icon-krone.svg',
  'dls-icon-krone-autopay': 'lib/dls-icon-krone-autopay.svg',
  'dls-icon-krone-autopay-filled': 'lib/dls-icon-krone-autopay-filled.svg',
  'dls-icon-krone-cashback-filled': 'lib/dls-icon-krone-cashback-filled.svg',
  'dls-icon-launch-filled': 'lib/dls-icon-launch-filled.svg',
  'dls-icon-left-filled': 'lib/dls-icon-left-filled.svg',
  'dls-icon-line-graph-filled': 'lib/dls-icon-line-graph-filled.svg',
  'dls-icon-link': 'lib/dls-icon-link.svg',
  'dls-icon-link-filled': 'lib/dls-icon-link-filled.svg',
  'dls-icon-list': 'lib/dls-icon-list.svg',
  'dls-icon-list-filled': 'lib/dls-icon-list-filled.svg',
  'dls-icon-location-filled': 'lib/dls-icon-location-filled.svg',
  'dls-icon-location-services-filled': 'lib/dls-icon-location-services-filled.svg',
  'dls-icon-lock-card': 'lib/dls-icon-lock-card.svg',
  'dls-icon-lounge': 'lib/dls-icon-lounge.svg',
  'dls-icon-medal': 'lib/dls-icon-medal.svg',
  'dls-icon-medal-filled': 'lib/dls-icon-medal-filled.svg',
  'dls-icon-medical': 'lib/dls-icon-medical.svg',
  'dls-icon-membership-filled': 'lib/dls-icon-membership-filled.svg',
  'dls-icon-menu': 'lib/dls-icon-menu.svg',
  'dls-icon-merchandise-filled': 'lib/dls-icon-merchandise-filled.svg',
  'dls-icon-minus-circle': 'lib/dls-icon-minus-circle.svg',
  'dls-icon-minus-circle-filled': 'lib/dls-icon-minus-circle-filled.svg',
  'dls-icon-minus-filled': 'lib/dls-icon-minus-filled.svg',
  'dls-icon-mobile-filled': 'lib/dls-icon-mobile-filled.svg',
  'dls-icon-more-filled': 'lib/dls-icon-more-filled.svg',
  'dls-icon-multi-channel-filled': 'lib/dls-icon-multi-channel-filled.svg',
  'dls-icon-neutral': 'lib/dls-icon-neutral.svg',
  'dls-icon-no-fee-filled': 'lib/dls-icon-no-fee-filled.svg',
  'dls-icon-offers-desktop': 'lib/dls-icon-offers-desktop.svg',
  'dls-icon-offers-desktop-filled': 'lib/dls-icon-offers-desktop-filled.svg',
  'dls-icon-offers-mobile-filled': 'lib/dls-icon-offers-mobile-filled.svg',
  'dls-icon-open-banking': 'lib/dls-icon-open-banking.svg',
  'dls-icon-open-banking-filled': 'lib/dls-icon-open-banking-filled.svg',
  'dls-icon-overdraft-protection-filled': 'lib/dls-icon-overdraft-protection-filled.svg',
  'dls-icon-oversize-bag': 'lib/dls-icon-oversize-bag.svg',
  'dls-icon-oversize-bag-filled': 'lib/dls-icon-oversize-bag-filled.svg',
  'dls-icon-paperless-filled': 'lib/dls-icon-paperless-filled.svg',
  'dls-icon-pause-circle': 'lib/dls-icon-pause-circle.svg',
  'dls-icon-pause-circle-filled': 'lib/dls-icon-pause-circle-filled.svg',
  'dls-icon-payment-due-filled': 'lib/dls-icon-payment-due-filled.svg',
  'dls-icon-pay-over-time': 'lib/dls-icon-pay-over-time.svg',
  'dls-icon-pay-over-time-filled': 'lib/dls-icon-pay-over-time-filled.svg',
  'dls-icon-pdf-filled': 'lib/dls-icon-pdf-filled.svg',
  'dls-icon-pet-filled': 'lib/dls-icon-pet-filled.svg',
  'dls-icon-pie-chart': 'lib/dls-icon-pie-chart.svg',
  'dls-icon-pie-chart-filled': 'lib/dls-icon-pie-chart-filled.svg',
  'dls-icon-play-circle': 'lib/dls-icon-play-circle.svg',
  'dls-icon-play-circle-filled': 'lib/dls-icon-play-circle-filled.svg',
  'dls-icon-play-filled': 'lib/dls-icon-play-filled.svg',
  'dls-icon-plus': 'lib/dls-icon-plus.svg',
  'dls-icon-plus-filled': 'lib/dls-icon-plus-filled.svg',
  'dls-icon-point-2x': 'lib/dls-icon-point-2x.svg',
  'dls-icon-point-2x-filled': 'lib/dls-icon-point-2x-filled.svg',
  'dls-icon-point-3x': 'lib/dls-icon-point-3x.svg',
  'dls-icon-point-3x-filled': 'lib/dls-icon-point-3x-filled.svg',
  'dls-icon-point-5x': 'lib/dls-icon-point-5x.svg',
  'dls-icon-point-5x-filled': 'lib/dls-icon-point-5x-filled.svg',
  'dls-icon-point-8x': 'lib/dls-icon-point-8x.svg',
  'dls-icon-point-10k': 'lib/dls-icon-point-10k.svg',
  'dls-icon-point-10k-filled': 'lib/dls-icon-point-10k-filled.svg',
  'dls-icon-point-20k-filled': 'lib/dls-icon-point-20k-filled.svg',
  'dls-icon-pound-autopay-filled': 'lib/dls-icon-pound-autopay-filled.svg',
  'dls-icon-pound-cashback': 'lib/dls-icon-pound-cashback.svg',
  'dls-icon-print-filled': 'lib/dls-icon-print-filled.svg',
  'dls-icon-processing': 'lib/dls-icon-processing.svg',
  'dls-icon-processing-filled': 'lib/dls-icon-processing-filled.svg',
  'dls-icon-qr-scan': 'lib/dls-icon-qr-scan.svg',
  'dls-icon-qr-scan-filled': 'lib/dls-icon-qr-scan-filled.svg',
  'dls-icon-quick-transfer': 'lib/dls-icon-quick-transfer.svg',
  'dls-icon-receipt-add': 'lib/dls-icon-receipt-add.svg',
  'dls-icon-receipt-add-filled': 'lib/dls-icon-receipt-add-filled.svg',
  'dls-icon-receipt-filled': 'lib/dls-icon-receipt-filled.svg',
  'dls-icon-receipt-view-filled': 'lib/dls-icon-receipt-view-filled.svg',
  'dls-icon-recent-points': 'lib/dls-icon-recent-points.svg',
  'dls-icon-recurring-interest': 'lib/dls-icon-recurring-interest.svg',
  'dls-icon-recurring-interest-filled': 'lib/dls-icon-recurring-interest-filled.svg',
  'dls-icon-refresh': 'lib/dls-icon-refresh.svg',
  'dls-icon-refresh-filled': 'lib/dls-icon-refresh-filled.svg',
  'dls-icon-refreshment-filled': 'lib/dls-icon-refreshment-filled.svg',
  'dls-icon-retirement': 'lib/dls-icon-retirement.svg',
  'dls-icon-rewards': 'lib/dls-icon-rewards.svg',
  'dls-icon-right': 'lib/dls-icon-right.svg',
  'dls-icon-right-filled': 'lib/dls-icon-right-filled.svg',
  'dls-icon-round-the-clock-filled': 'lib/dls-icon-round-the-clock-filled.svg',
  'dls-icon-rupee-autopay-filled': 'lib/dls-icon-rupee-autopay-filled.svg',
  'dls-icon-rupee-cashback': 'lib/dls-icon-rupee-cashback.svg',
  'dls-icon-rupee-cashback-filled': 'lib/dls-icon-rupee-cashback-filled.svg',
  'dls-icon-rupee-filled': 'lib/dls-icon-rupee-filled.svg',
  'dls-icon-savings-filled': 'lib/dls-icon-savings-filled.svg',
  'dls-icon-security-filled': 'lib/dls-icon-security-filled.svg',
  'dls-icon-send-and-split': 'lib/dls-icon-send-and-split.svg',
  'dls-icon-send-and-split-filled': 'lib/dls-icon-send-and-split-filled.svg',
  'dls-icon-setting-filled': 'lib/dls-icon-setting-filled.svg',
  'dls-icon-share-filled': 'lib/dls-icon-share-filled.svg',
  'dls-icon-shipping-truck-filled': 'lib/dls-icon-shipping-truck-filled.svg',
  'dls-icon-sound-off': 'lib/dls-icon-sound-off.svg',
  'dls-icon-sound-on': 'lib/dls-icon-sound-on.svg',
  'dls-icon-sound-on-filled': 'lib/dls-icon-sound-on-filled.svg',
  'dls-icon-source-filled': 'lib/dls-icon-source-filled.svg',
  'dls-icon-spa': 'lib/dls-icon-spa.svg',
  'dls-icon-spa-filled': 'lib/dls-icon-spa-filled.svg',
  'dls-icon-split': 'lib/dls-icon-split.svg',
  'dls-icon-statement-ready': 'lib/dls-icon-statement-ready.svg',
  'dls-icon-statement-ready-filled': 'lib/dls-icon-statement-ready-filled.svg',
  'dls-icon-success-filled': 'lib/dls-icon-success-filled.svg',
  'dls-icon-tablet-filled': 'lib/dls-icon-tablet-filled.svg',
  'dls-icon-tag': 'lib/dls-icon-tag.svg',
  'dls-icon-tag-filled': 'lib/dls-icon-tag-filled.svg',
  'dls-icon-tap-to-pay-filled': 'lib/dls-icon-tap-to-pay-filled.svg',
  'dls-icon-taxi': 'lib/dls-icon-taxi.svg',
  'dls-icon-thumbs-up-filled': 'lib/dls-icon-thumbs-up-filled.svg',
  'dls-icon-time-filled': 'lib/dls-icon-time-filled.svg',
  'dls-icon-transfer': 'lib/dls-icon-transfer.svg',
  'dls-icon-trash': 'lib/dls-icon-trash.svg',
  'dls-icon-trash-filled': 'lib/dls-icon-trash-filled.svg',
  'dls-icon-trends-filled': 'lib/dls-icon-trends-filled.svg',
  'dls-icon-unlock-filled': 'lib/dls-icon-unlock-filled.svg',
  'dls-icon-up-filled': 'lib/dls-icon-up-filled.svg',
  'dls-icon-upload': 'lib/dls-icon-upload.svg',
  'dls-icon-upload-filled': 'lib/dls-icon-upload-filled.svg',
  'dls-icon-warning': 'lib/dls-icon-warning.svg',
  'dls-icon-warning-filled': 'lib/dls-icon-warning-filled.svg',
  'dls-icon-watch-filled': 'lib/dls-icon-watch-filled.svg',
  'dls-icon-wi-fi': 'lib/dls-icon-wi-fi.svg',
  'dls-icon-wi-fi-filled': 'lib/dls-icon-wi-fi-filled.svg',
  'dls-icon-wire-transfer': 'lib/dls-icon-wire-transfer.svg',
  'dls-icon-wire-transfer-filled': 'lib/dls-icon-wire-transfer-filled.svg',
  'dls-icon-wire-yen-cashback': 'lib/dls-icon-yen-cashback.svg',
  'dls-icon-wire-yen-cashback-filled': 'lib/dls-icon-yen-cashback-filled.svg',
  'dls-icon-wire-yen-filled': 'lib/dls-icon-yen-filled.svg',
  'dls-glyph-account': 'lib/glyphs/dls-glyph-account.svg',
  'dls-glyph-cancel-circle': 'lib/glyphs/dls-glyph-cancel-circle.svg',
  'dls-glyph-check': 'lib/glyphs/dls-glyph-check.svg',
  'dls-glyph-close': 'lib/glyphs/dls-glyph-close.svg',
  'dls-glyph-double-left': 'lib/glyphs/dls-glyph-double-left.svg',
  'dls-glyph-double-right': 'lib/glyphs/dls-glyph-double-right.svg',
  'dls-glyph-down': 'lib/glyphs/dls-glyph-down.svg',
  'dls-glyph-equal': 'lib/glyphs/dls-glyph-equal.svg',
  'dls-glyph-favorite': 'lib/glyphs/dls-glyph-favorite.svg',
  'dls-glyph-info': 'lib/glyphs/dls-glyph-info.svg',
  'dls-glyph-left': 'lib/glyphs/dls-glyph-left.svg',
  'dls-glyph-link-out': 'lib/glyphs/dls-glyph-link-out.svg',
  'dls-glyph-minus': 'lib/glyphs/dls-glyph-minus.svg',
  'dls-glyph-nav': 'lib/glyphs/dls-glyph-nav.svg',
  'dls-glyph-plus': 'lib/glyphs/dls-glyph-plus.svg',
  'dls-glyph-plus-circle': 'lib/glyphs/dls-glyph-plus-circle.svg',
  'dls-glyph-right': 'lib/glyphs/dls-glyph-right.svg',
  'dls-glyph-sort-down': 'lib/glyphs/dls-glyph-sort-down.svg',
  'dls-glyph-sort-up': 'lib/glyphs/dls-glyph-sort-up.svg',
  'dls-glyph-up': 'lib/glyphs/dls-glyph-up.svg',
  'callback-image': 'lib/callback-headphones-pic.svg',
  'checkmark-square': 'lib/checkmark-square.svg',
  'dog-filled': 'lib/dog-filled.svg',
  'dog-outline': 'lib/dog-outline.svg',
  'cat-filled': 'lib/cat-filled.svg',
  'cat-outline': 'lib/cat-outline.svg',
  'plane-ticket': 'lib/plane-ticket.svg',
  'calendar-year': 'lib/calendar-year.svg',
} as const;
