import { FC, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { AxiosError, FormAndCardViewProductDto, PromoEventBasicDto, PromoEventDetailsDto, admin } from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import { PROMPT_MESSAGE } from 'shared/constants';
import {
  getVisibleSections,
  onFormFinishFailed,
  revalidateAfterVisibilityChanged,
  useAdminLocation,
  usePrompt,
} from 'shared/utils';

import { EventSystemInfo } from 'features/events/components/EventSystemInfo/EventSystemInfo';
import { PromoEventTabs } from 'features/events/components/PromoEventTabs/PromoEventTabs';
import EVENT_SECTION_TITLES from 'features/events/constants';

import ExportImport from '../shared/components/ExportImport/ExportImport';
import { SidebarList } from '../shared/components/SidebarList/SidebarList';
import ImportPageContext from '../shared/contexts/ImportPageContext';
import useExportImportEnabled from '../shared/hooks/useExportImportEnabled';
import downloadJson from '../shared/utils/downloadJson';
import uploadImportedImages from '../shared/utils/processImportedImages';

import styles from './styles.module.scss';

const EventLandingPage: FC = () => {
  const { eventId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { adminLocation, locationLanguage = '' } = useAdminLocation();
  const locationId = adminLocation?.id;
  const [isShowLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [selectedProducts, updateSelectedProducts] = useState<string[]>([]);
  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [defaultIndices, setDefaultIndices] = useState<Record<string, DefaultIndexValue>>({});
  const [resetIndices, setResetIndicies] = useState(false);
  const [indices, setIndices] = useState<IndicesType>([]);
  const [form] = Form.useForm<PromoEventBasicDto>();
  const [formDetails] = Form.useForm<PromoEventDetailsDto>();
  const [showDoYouWantToImportModal, setShowDoYouWantToImportModal] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);

  usePrompt(PROMPT_MESSAGE, isShowLeaveModal);

  const { data: providers = [] } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );
  const { data: locationProducts = [] } = useRequest(() => admin.getProductForLocation(locationId), {
    refreshDeps: [locationId],
  });

  const onError = () => {
    const [, locationInPath, events] = location.pathname.split('/');
    navigate(`/${locationInPath}/${events}`, { replace: true });
  };

  const { data: promoEvent, loading: promoEventLoading } = useRequest(() => admin.getPromoEvent(eventId), {
    refreshDeps: [eventId],
    onSuccess: data => {
      if (data) {
        form.setFieldsValue(data);
      }
    },
    onError,
  });

  const setFormDetailsFromResponse = (data: PromoEventDetailsDto) => {
    formDetails.setFieldsValue(data);
    disableProducts(data.productsSection.products);
    setSectionsVisibility(getVisibleSections(data));
    setDefaultIndices(
      Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          { order: value?.order ?? 0, subSections: value?.subSections ?? null },
        ]),
      ),
    );
  };

  const { data: promoEventDetails, loading: promoEventDetailsLoading } = useRequest(
    () => admin.getPromoEventDetails(eventId, locationLanguage),
    {
      refreshDeps: [eventId, locationLanguage],
      onSuccess: data => {
        if (data) {
          setFormDetailsFromResponse(data);
        }
      },
      onError,
    },
  );

  const { runAsync: updateEvent } = useRequest(admin.updatePromoEvent, { manual: true });
  const { runAsync: updateEventDetails } = useRequest(admin.updatePromoEventDetails, { manual: true });

  const disableProducts = (newSelectedProducts: FormAndCardViewProductDto[]) => {
    const result = [
      ...new Set(newSelectedProducts?.map((item: { productId: string }) => item?.productId).filter((id: string) => id)),
    ] as string[];
    updateSelectedProducts(result);
  };

  const {
    runAsync: importPage,
    loading: importLoading,
    data: importedPageData,
  } = useRequest(
    (form: FormData) => {
      return admin.importEventLandingPage({ locationLandingId, form });
    },
    {
      manual: true,
      onSuccess: response => {
        if (response.content.landingContent) {
          setResetIndicies(true);
          // the problem is in ContentBlockContainer it does need list to be present to actually show the data in form we need copy because in the set function something can be mutating values in place
          const landingContentCopy = cloneDeep(response.content.landingContent);
          setFormDetailsFromResponse({ ...landingContentCopy });
          setTimeout(() => {
            setFormDetailsFromResponse({ ...landingContentCopy });
          }, 1000);
          setIsImportModalOpen(false);
          if (response.isFullContent) {
            notification.success({
              message: 'Page imported successfully',
            });
          } else {
            notification.warning({
              message: 'Warning: Content was not fully imported. Please review the page carefully before publishing',
            });
          }
        }
      },
      onError: (e: Maybe<AxiosError>) => {
        if (e.response.data.errors?.length) {
          setIsImportModalOpen(true);
          setShowDoYouWantToImportModal(false);
        }
      },
    },
  );

  const formFinished = async () => {
    try {
      const promoEventData = await form.validateFields();
      const promoEventDetailsData = await formDetails.validateFields();

      const indexedValues = indices.reduce((res, item, i) => {
        const itemKey: string = item[0];
        // @ts-ignore
        const block = res[itemKey];
        if (block && typeof block == 'object') {
          // @ts-ignore
          block.order = i;
        }
        if ('subSections' in block) {
          const indItem = indices.find(ind => ind[0] === itemKey);
          const itemSubSections = indItem && indItem[1]?.subSections;
          itemSubSections?.forEach((subSection, subSectionIndex) => {
            block.subSections[subSection[0]].order = subSectionIndex;
          });
        }
        return res;
      }, promoEventDetailsData);

      // handle imported images
      const importImages = importedPageData?.content.landingMediaFiles;

      if (importImages?.length) {
        await uploadImportedImages(importImages, indexedValues, results => {
          results.forEach(result => {
            set(indexedValues, result.pathToId, {
              id: result.id,
              mimeType: result.fileMetadata?.mimeType ?? null,
              title: result.fileMetadata?.title ?? null,
            });
          });
        });
      }

      setShowLeaveModal(false);
      await Promise.all([
        updateEvent({ ...promoEventData, locationId, id: eventId }),
        updateEventDetails({
          ...indexedValues,
          ...{
            language: locationLanguage ?? '',
            locationEventId: eventId,
            id: promoEventDetails?.id,
          },
        }),
      ]).then(() => {
        setShowDoYouWantToImportModal(false);
        notification.success({
          message: 'Promo event has been successfully updated',
        });
        return null;
      });
      return true;
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  const pageTitle = promoEvent?.systemName ?? 'Event';
  const envId = window.__RUNTIME_CONFIG__.ENVIRONMENT_ID ?? 'e1';
  const exportName = `${adminLocation.id}_${locationLanguage}_Event_${pageTitle}_${envId}_${new Date().getTime()}`;

  const locationLandingId = promoEventDetails?.id ?? '';
  const { runAsync: exportPage, loading: exportLoading } = useRequest(
    () => {
      return admin.exportEventLandingPage({ locationLandingId, fileName: exportName });
    },
    {
      manual: true,
      onSuccess: async base64Data => {
        downloadJson(base64Data, exportName);
        notification.success({
          message: 'Page exported successfully',
        });
      },
    },
  );

  const isExportImportEnabled = useExportImportEnabled() || true;

  return (
    <main>
      <PageHeader title={pageTitle} subTitle="Edit Event" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Spin spinning={promoEventLoading || promoEventDetailsLoading}>
              {isExportImportEnabled && (
                <ExportImport
                  exportLoading={exportLoading}
                  importLoading={importLoading}
                  onExportPage={exportPage}
                  onImportPage={importPage}
                  hasUnsavedChanges={isShowLeaveModal || showDoYouWantToImportModal}
                  onImportFinished={setShowDoYouWantToImportModal}
                  isImportModalOpen={isImportModalOpen}
                  setIsImportModalOpen={setIsImportModalOpen}
                  onSaveChangesAndExport={async () => {
                    const submitSuccess = await formFinished();
                    if (submitSuccess) {
                      await exportPage();
                    }
                  }}
                />
              )}
              <Form
                name="event"
                form={form}
                onFinishFailed={onFormFinishFailed}
                onValuesChange={() => {
                  !isShowLeaveModal && setShowLeaveModal(true);
                }}
                preserve={false}
                initialValues={promoEvent}
              >
                <EventSystemInfo />
              </Form>
              <Form
                name="eventDetails"
                form={formDetails}
                onFinishFailed={onFormFinishFailed}
                onFieldsChange={(changedFields, allFields) => {
                  revalidateAfterVisibilityChanged(formDetails, changedFields, allFields);
                }}
                onValuesChange={(changedValues, allValues) => {
                  if (changedValues.productsSection) {
                    disableProducts(allValues.productsSection.products);
                  }
                  setSectionsVisibility(getVisibleSections(allValues));
                  !isShowLeaveModal && setShowLeaveModal(true);
                }}
                preserve={false}
                initialValues={promoEventDetails}
              >
                <ImportPageContext.Provider value={importedPageData}>
                  <PromoEventTabs
                    isShowLeaveModal={false}
                    sectionTitles={EVENT_SECTION_TITLES}
                    providers={providers}
                    products={locationProducts}
                    selectedProducts={selectedProducts}
                  />
                </ImportPageContext.Provider>
              </Form>
            </Spin>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Button type="primary" htmlType="submit" onClick={formFinished}>
              Save
            </Button>
            <Card title="Layout" bordered={false} className={styles.sidebarCardWrapper}>
              <SidebarList
                parent="event"
                resetIndices={resetIndices}
                setResetIndicies={setResetIndicies}
                sidebarListValues={sectionsVisibility}
                sectionTitles={EVENT_SECTION_TITLES}
                defaultIndices={defaultIndices}
                indices={indices}
                setIndices={setIndices}
              />
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default EventLandingPage;
