import type { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN, admin } from '@saturn/api';
import { Icon, Menu, MenuDividerType, MenuItemGroupType } from '@saturn/uikit';

import { NavMenuLinkProps, NavigationMenuProps } from './models';

const getMenuLink = (label: string, url: string) => {
  return {
    key: label,
    label: <NavMenuLink label={label} url={url} />,
  };
};

export const NavMenuLink: FC<NavMenuLinkProps> = ({ label, url }) => {
  const location = useLocation();

  return (
    <>
      {location.pathname.includes(url) && <Icon size={13} name="arrow-back" />}
      <NavLink to={url} className={({ isActive }) => (isActive ? 'active' : undefined)}>
        {label}
      </NavLink>
    </>
  );
};

export const NavigationMenu: FC<NavigationMenuProps> = ({ adminLocation, role }) => {
  const sections: (MenuItemGroupType | MenuDividerType)[] = [];
  const location = useLocation();
  const { data: features = {} } = useRequest(() => admin.loadFeatures(), {
    refreshDeps: [location],
  });
  const arePromocodesEnabled = features['cms-promocode-enabled'];

  sections.push({
    label: 'Pages',
    type: 'group',
    children: [
      getMenuLink('Homepage', `/${adminLocation}/locations`),
      getMenuLink('Insurance Categories', `/${adminLocation}/categories`),
      getMenuLink('Products', `/${adminLocation}/products`),
      getMenuLink('Insurance Partners', `/${adminLocation}/insurance-partners`),
      getMenuLink('Events', `/${adminLocation}/events`),
    ],
  });

  if (arePromocodesEnabled) {
    sections.push({
      label: 'Features',
      type: 'group',
      children: [getMenuLink('Promocodes', `/${adminLocation}/promocodes`)],
    });
  }

  sections.push({
    type: 'divider',
  });

  const settingSection: MenuItemGroupType = {
    label: 'Settings',
    type: 'group',
    children: [getMenuLink('Location Settings', `/${adminLocation}/location/settings`)],
  };
  if (role.includes(ROLE_ADMIN) || role.includes(ROLE_SUPER_ADMIN)) {
    settingSection.children?.push(getMenuLink('Admin Users', `/${adminLocation}/users`));
  }
  sections.push(settingSection);

  if (role.includes(ROLE_ADMIN) || role.includes(ROLE_SUPER_ADMIN)) {
    const analyticsSection: MenuItemGroupType = {
      label: 'Tools',
      type: 'group',
      children: [getMenuLink('Analytics Report', `/${adminLocation}/analytics`)].concat(
        adminLocation === 'in' ? [getMenuLink('Error Report', `/${adminLocation}/error-report`)] : [],
      ),
    };
    if (role.includes(ROLE_SUPER_ADMIN)) {
      analyticsSection.children?.push(getMenuLink('System Action Logs', `/${adminLocation}/logs`));
    }
    sections.push(analyticsSection);
  }

  sections.push({
    label: 'My Policies',
    type: 'group',
    children: [
      getMenuLink('List of policies', `/${adminLocation}/list-of-policies`),
      getMenuLink('Policy details', `/${adminLocation}/policy-details`),
    ],
  });

  return <Menu items={sections} />;
};
