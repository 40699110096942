import type { FC } from 'react';

import { LanguageTabs } from 'shared/components';

import { ContactContent } from '../ContactContent/ContactContent';
import { DisclaimerSection } from '../DisclaimerSection/DisclaimerSection';
import { ListOfPolicies } from '../ListOfPolicies/ListOfPolicies';
import { ListOfPoliciesTabsProps } from './models';

export const ListOfPoliciesTabs: FC<ListOfPoliciesTabsProps> = ({ isShowLeaveModal }) => {
  return (
    <LanguageTabs isShowLeaveModal={isShowLeaveModal}>
      <DisclaimerSection header="Disclaimer" />
      <ListOfPolicies header="List of policies" />
      <ContactContent header="Still have questions?" />
    </LanguageTabs>
  );
};
