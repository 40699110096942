import type { FC } from 'react';

import cn from 'classnames';

import { Hyphenated } from '../Hyphenated';
import { TitleProps } from './models';

const Title: FC<TitleProps> = ({
  children,
  className,
  style,
  level = 3,
  color = 'default',
  offset = 'normal',
  align = 'start',
  extra,
  isHyphenated,
}) => {
  const Wrapper = `h${level}` as const;
  const commonClasses = cn(
    'saturn-heading',
    { [`heading--color-${color}`]: color !== 'default' },
    { [`heading--offset-${offset}`]: offset !== 'normal' },
    { [`heading--align-${align}`]: align !== 'start' },
    className,
  );

  return isHyphenated && typeof children === 'string' ? (
    <Hyphenated wrapperComponent={Wrapper} className={commonClasses}>
      {children}
    </Hyphenated>
  ) : (
    <Wrapper className={commonClasses} style={style}>
      {children}
      {extra}
    </Wrapper>
  );
};

export { Title };
