import { Button, Modal } from '@saturn/uikit';

import styles from './DoYouWantToContentModal.module.scss';

type DoYouWantToImportContentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onImport: () => void;
};
export default function DoYouWantImportContentModal({ isOpen, onClose, onImport }: DoYouWantToImportContentModalProps) {
  return (
    <Modal
      open={isOpen}
      width={284}
      className={styles.modal}
      closable={false}
      footer={() => {
        return (
          <div className={styles.buttonsContainer}>
            <Button type="primary" onClick={onImport}>
              Import
            </Button>
            <Button type="text" className={styles.cancel} onClick={onClose}>
              Cancel
            </Button>
          </div>
        );
      }}
    >
      <p className={styles.title}>Do you want to import content?</p>
      <p className={styles.subtitle}>Please note all previous changes won’t be saved after import</p>
    </Modal>
  );
}
