import type { FC } from 'react';

import { Modal as AntModal } from 'antd';
import type { ModalProps } from 'antd';
import cn from 'classnames';

export const Modal: FC<ModalProps> = props => {
  const { className, children, ...rest } = props;

  return (
    <AntModal className={cn(className, 'saturn-modal')} {...rest}>
      {children}
    </AntModal>
  );
};
export const ModalConfirm = AntModal.confirm;
