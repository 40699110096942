import type { FC } from 'react';

import { BlockWithIcon, CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { WhyAmexProps } from './model';

import styles from './WhyAmex.module.scss';

export const WhyAmex: FC<WhyAmexProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Why American Express?'} name={['whyAmericanExpress']} showSectionTitle>
      <ContentBlockContainer
        name={['whyAmericanExpress', 'whyAmericanExpressBlogs']}
        className={styles.content}
        addButtonText="Add section"
        title="Why Amex"
        showChangePositionButton={true}
      >
        {props => <BlockWithIcon {...props} labelTitle="Title" isIconRequired />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
