import { axiosClient as apiClient } from './service';
import { getEnvVariable } from './utils/envVariables.utils';

export * from './models';
export * from './utils';
export * from './interfaces';
export * from './constants';
export { createServerApi, default as api, ApiClient } from './service/api';
export * as admin from './service/admin';
export * as titan from './service/titan';

export default apiClient;
export type { AxiosResponse, AxiosRequestConfig, AxiosInstance, AxiosError, AxiosRequestHeaders } from 'axios';

export const getProductSalesUrl = (productId: string): string => `/products/${productId}/sales`;
export const getPaymentConfigUrl = (providerId: string): string => `/insurance-providers/${providerId}/payments/config`;
export const getProductPlansUrl = (productId: string): string => `/products/${productId}/offers`;
export const getCardConfigUrl = (providerId: string, cardType: string): string =>
  `/insurance-providers/${providerId}/payments/config/cardType/${cardType}`;
export const uploadFileUrl = (title?: string): string => `/admin/storage/files?title=${title}`;
export const getFileUrl = (fileId: string): string => {
  if (process.env.NEXT_PUBLIC_E2E_ENV_MOCKING === 'enabled') {
    return '';
  }
  const cdnPrefix = getEnvVariable('CDN_PATH') ?? '';
  const apiPrefix = getEnvVariable('API_SUFFIX') ?? '/api'; // API_SUFFIX is utilized by the customer app
  return `${cdnPrefix}${apiPrefix}/storage/files/${fileId}`.replace(/(https?:\/\/)|(\/){2,}/g, '$1$2');
};
