import type { FC } from 'react';

import { CollapsiblePanel } from 'shared/components';

export interface QuoteWidgetProps {
  header?: string;
}

export const QuoteWidget: FC<QuoteWidgetProps> = ({ header }) => {
  return (
    <CollapsiblePanel
      header={header ?? 'Quote Widget'}
      showSectionTitle
      isRequiredSectionTitle
      name={['quoteWidget']}
    />
  );
};
