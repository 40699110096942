import type { FC } from 'react';

import { CollapsiblePanel, InsuranceProductsBlock } from 'shared/components';

import type { LocationTabsProps } from '../LocationTabs/model';

export const InsuranceProducts: FC<Pick<LocationTabsProps, 'products' | 'selectedInsuranceProducts' | 'header'>> = ({
  products = [],
  selectedInsuranceProducts = [],
  header,
}) => {
  const productsWithForm = products.filter(p => !p.id.includes('cover-genius') && p.type === 'API');

  return (
    <CollapsiblePanel header={header || 'Our Insurance Products'} name={['ourInsuranceProducts']} showSectionTitle>
      <InsuranceProductsBlock
        title="Insurance Products with Form"
        titleTip="Only published products will be shown on the public website."
        blockTip="Select Products that should be shown with a Quick Quote form on the Homepage."
        name={['ourInsuranceProducts', 'formViewProducts']}
        maxItems={3}
        products={productsWithForm}
        selectedInsuranceProducts={selectedInsuranceProducts}
        showHightlightProduct={false}
      />
      <InsuranceProductsBlock
        title="Insurance Products without Form"
        blockTip="Select Products that should be shown without the form on the Homepage."
        name={['ourInsuranceProducts', 'cardViewProducts']}
        maxItems={8}
        products={products}
        selectedInsuranceProducts={selectedInsuranceProducts}
        showHightlightProduct={false}
      />
    </CollapsiblePanel>
  );
};
