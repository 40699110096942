import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, IconsGallery } from 'shared/components';

import { DocumentsBlock } from './components/DocumentsBlock';
import { DocumentsProps } from './model';

import styles from './Documents.module.scss';

export const Documents: FC<DocumentsProps> = ({ header }) => {
  const sectionName = ['allYouNeedToKnow', 'subSections', 'document'];
  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel
        header={header || 'Documents'}
        sectionTitleLabel="Tab Title"
        name={sectionName}
        showSectionTitle
      >
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...sectionName, 'visible']);
            return (
              <>
                <Form.Item
                  preserve={false}
                  valuePropName="icon"
                  name={[...sectionName, 'thumbnailId']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            required: true,
                            message: 'Thumbnail is required',
                          },
                        ]
                      : []),
                  ]}
                >
                  <IconsGallery />
                </Form.Item>
                <Form.Item
                  name={[...sectionName, 'tabSubtitle']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            max: 100,
                            message: 'Tab Subtitle should be at most 100 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Input label="Tab Subtitle" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
        <ContentBlockContainer
          className={styles.content}
          maxItems={10}
          name={[...sectionName, 'documents']}
          addButtonText="Add document"
          title="Document"
          showChangePositionButton={true}
        >
          {props => <DocumentsBlock {...props} />}
        </ContentBlockContainer>
      </CollapsiblePanel>
    </div>
  );
};
