import { Rule } from '@saturn/uikit';

const rules = {
  title: {
    max: 100,
  },
  description: {
    max: 1000,
  },
} as const;

export const validationRules: Record<'description' | 'title', Rule[]> = {
  title: [
    {
      max: rules.title.max,
      message: `Section Title should be at most ${rules.title.max} characters`,
    },
    {
      required: true,
      message: 'Section Title is required',
      whitespace: true,
    },
  ],
  description: [
    {
      max: rules.description.max,
      message: `Description should be at most ${rules.description.max} characters`,
    },
    {
      required: true,
      message: 'Description is required',
      whitespace: true,
    },
  ],
};
