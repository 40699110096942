import { useState } from 'react';
import type { FC } from 'react';

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import { Button, Col, DatePicker, Form, Row } from '@saturn/uikit';

import { AnalyticsFormProps } from './model';

export const AnalyticsForm: FC<AnalyticsFormProps> = ({ onSubmit }) => {
  const [fromDate, setFromDate] = useState<Dayjs | null>();
  const [toDate, setToDate] = useState<Dayjs | null>();

  const disabledFromDate = (date: Dayjs) => {
    return (date && date.diff(dayjs().endOf('day'))) > 0 || (toDate !== undefined && dayjs(date).isSameOrAfter(toDate));
  };

  const disabledToDate = (date: Dayjs) => {
    return (
      (date && date.endOf('day').diff(dayjs().add(1, 'day').endOf('day'))) > 0 ||
      (fromDate !== undefined && dayjs(date).isSameOrBefore(fromDate))
    );
  };

  const onChangeFromDate = (date: Dayjs | null) => {
    setFromDate(date ? dayjs(date).startOf('day') : null);
  };

  const onChangeToDate = (date: Dayjs | null) => {
    setToDate(date ? dayjs(date).endOf('day') : null);
  };

  return (
    <Form onFinish={onSubmit}>
      <Row gutter={16}>
        <Col>
          <Form.Item name="from" normalize={date => date && dayjs(date).startOf('day')}>
            <DatePicker label="From" showNow={false} disabledDate={disabledFromDate} onChange={onChangeFromDate} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="to" normalize={date => date && dayjs(date).endOf('day')}>
            <DatePicker label="To" showNow={false} disabledDate={disabledToDate} onChange={onChangeToDate} />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">
        Export CSV
      </Button>
    </Form>
  );
};
