import { useCallback, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { Popconfirm, message } from 'antd';
import cn from 'classnames';

import { Button, Icon, Select } from '@saturn/uikit';
import { ComponentProps } from '@saturn/uikit/src/models/components';

import { prepareOptions } from 'shared/utils';

import styles from './ContentBlock.module.scss';

interface ContentBlockInnerProps extends ComponentProps {
  index: number;
  name?: Array<number | string>;
  title: string;
  onDelete?: () => void;
  onMove?: (from: number, to: number) => void;
  children?: ReactNode;
  hideDeleteButton?: boolean;
  toggleBlock?: ReactNode;
  showChangePositionButton?: boolean;
  length: number;
}

export const ContentBlock: FC<ContentBlockInnerProps> = ({
  style,
  name,
  title,
  index,
  onDelete,
  children,
  className,
  hideDeleteButton,
  toggleBlock,
  showChangePositionButton = false,
  length,
  onMove,
}) => {
  const [showChangePositionDropdown, setShowChangePositionDropdown] = useState(false);
  const [moveToIndex, setMoveToIndex] = useState<number | null>(null);
  const handleDelete = useCallback(() => {
    onDelete?.();
    message.info('Block has been successfully deleted');
  }, [onDelete]);

  const changePositionDropdown = (
    <div className={styles.changePositionDropdown}>
      <span className={styles.moveToLabel}>Move to: </span>
      <div className={styles.selectWrapper}>
        <Select
          placeholder="Position"
          onChange={value => {
            setMoveToIndex(value - 1);
          }}
          defaultValue={index + 1}
        >
          {prepareOptions(
            Array.from({ length }, (_, idx) => 1 + idx).map(pos => ({
              value: pos,
              label: pos,
              disabled: index + 1 === pos,
            })),
          )}
        </Select>
      </div>
      <Button
        onClick={() => {
          setShowChangePositionDropdown(false);
          if (typeof moveToIndex === 'number' && onMove) {
            onMove(index, moveToIndex);
            message.info('Blocks have been successfully rearranged');
          }
        }}
        type="primary"
      >
        Confirm
      </Button>
    </div>
  );

  return (
    <div className={cn(className, 'saturn-content-block', styles.wrapper)} style={style}>
      <div className={styles.header}>
        <span>{`${title} ${index !== undefined ? index + 1 : ''}`}</span>
        {onDelete && !hideDeleteButton ? (
          <Popconfirm
            placement="top"
            title="Are you sure you want to delete block?"
            onConfirm={handleDelete}
            okText="Delete"
            cancelText="Cancel"
          >
            <div className={styles.deleteBlockButton}>
              <Button data-key={`${name?.join('-')}-delete-block-btn`}>
                <Icon name="trash-2-outline" />
                Delete Block
              </Button>
            </div>
          </Popconfirm>
        ) : null}
        {toggleBlock}
        {showChangePositionButton && length > 1 && (
          <div className={styles.changePosition}>
            {!showChangePositionDropdown ? (
              <Button
                className={styles.changePositionButton}
                type="link"
                onClick={() => {
                  setShowChangePositionDropdown(true);
                }}
                isStaticBtn
              >
                Change position
              </Button>
            ) : (
              changePositionDropdown
            )}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};
