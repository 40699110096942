import { ElementType, PropsWithChildren, ReactChild, ReactElement, ReactNode, cloneElement } from 'react';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type NodeWithChildren = (ReactNode | { props?: PropsWithChildren<ReactNode> }) | any;

export const addPropsToChildren = (
  node: NodeWithChildren,
  elementType: ElementType,
  callback: (node: NodeWithChildren) => Record<string, unknown>,
): NodeWithChildren => {
  if (node?.type === elementType) {
    return cloneElement(node, {
      ...callback(node),
      ...node?.props,
    });
  }

  if (Array.isArray(node)) {
    return (node as ReactElement[]).map(child => addPropsToChildren(child, elementType, callback));
  }

  if (Array.isArray(node?.props?.children)) {
    return node.props.children.map((child: ReactChild) => addPropsToChildren(child, elementType, callback));
  } else if (node?.props?.children) {
    return addPropsToChildren(node.props.children, elementType, callback);
  }

  return node;
};
