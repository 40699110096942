import { useState } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { Icon, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import { CategoryList } from 'features/categories/components';

import styles from './styles.module.scss';

const CategoriesPage: FC = () => {
  const { adminLocation } = useAdminLocation();

  const [disabledCategories, setDisabledCategories] = useState<Record<string, boolean>>({});

  const { data: locationCategories, loading } = useRequest(() => admin.getCategoriesList(adminLocation.id), {
    refreshDeps: [adminLocation.id],
    onSuccess: () => {
      setDisabledCategories(Object.fromEntries(locationCategories?.map(cat => [cat.id, cat.hidden ?? false]) ?? []));
    },
  });

  const { runAsync: disableCategory } = useRequest(admin.disableCategory, {
    manual: true,
    onSuccess: ({ hidden }) => {
      notification.success({
        message: hidden ? 'Category has been successfully disabled' : 'Category has been successfully enabled',
      });
    },
  });

  const onDisableCategoryToggle = (id: string, checked: boolean) => {
    const categoryToDisable = locationCategories?.find(cat => cat.id === id);
    setDisabledCategories(state => ({ ...state, [id]: checked }));
    disableCategory({
      id,
      name: categoryToDisable?.name ?? '',
      hidden: checked,
      slugPart: categoryToDisable?.slugPart ?? null,
      hasLandingPage: categoryToDisable?.hasLandingPage ?? true,
    }).catch(() => setDisabledCategories(state => ({ ...state, [id]: !checked })));
  };

  return (
    <main>
      <PageHeader title="Category List" />
      <div className={styles.buttonContainer}>
        <Link type="primary" className="ant-btn ant-btn-primary" to="create">
          <span>Create new category</span>
        </Link>
      </div>
      <Spin spinning={loading} indicator={<Icon name="sync-outline" />}>
        <CategoryList
          categories={locationCategories ?? []}
          toggleCategoryState={(id: string, checked: boolean) => {
            onDisableCategoryToggle(id, checked);
          }}
          disabledCategories={disabledCategories}
        />
      </Spin>
    </main>
  );
};

export default CategoriesPage;
