import type { FC, ReactNode } from 'react';

import cn from 'classnames';

import { Tip } from 'shared/components/Tip';

import styles from './MarkdownTip.module.scss';

export const MarkdownTip: FC<{
  className?: string;
  children?: ReactNode;
  content?: ReactNode;
}> = ({ children, className, content }) => {
  const defaultTipContent = (
    <>
      Follow to{' '}
      <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
        https://www.markdownguide.org/basic-syntax/
      </a>{' '}
      for instructions
    </>
  );

  return (
    <div className={cn(styles.content, className)}>
      {children}
      <Tip>{content ? content : defaultTipContent}</Tip>
    </div>
  );
};
