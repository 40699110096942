import type { FC, ReactNode } from 'react';

import cn from 'classnames';

import { Icon } from '@saturn/uikit';

import styles from './Tip.module.scss';

export const Tip: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className={cn('tooltip-wrapper', styles.tooltipWrapper)}>
      <Icon className={styles.tooltipIcon} size={20} name="bulb-outline" />
      <span>
        <strong>Tip: </strong>
        {children}
      </span>
    </div>
  );
};
