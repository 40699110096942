import type { FC } from 'react';

import { Alert as AntAlert } from 'antd';
import cn from 'classnames';

import { Icon } from '@saturn/uikit';

import { AlertData, AlertProps, AlertType } from './models';

import styles from './Alert.module.scss';

const alertData: Record<AlertType, AlertData> = {
  success: {
    icon: <Icon color="#409d66" name="checkmark-circle-outline" />,
  },
  info: {
    icon: <Icon color="#006fcf" name="alert-circle-outline" />,
  },
  warning: {
    icon: <Icon color="#f48809" name="alert-circle-outline" />,
  },
  error: {
    icon: <Icon color="#b42c01" name="alert-triangle-outline" />,
  },
};

export const Alert: FC<AlertProps> = ({ className, type = 'success', icon, showIcon = true, ...props }) => {
  return (
    <div className={cn(styles.wrapper, 'saturn-alert', className)}>
      <AntAlert type={type} icon={icon ?? alertData[type].icon} showIcon={showIcon} {...props} />
    </div>
  );
};
