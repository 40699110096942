import { ANALYTICS_TOKEN } from '../constants';
import { AnalyticsRequest, ObfuscatedAnalyticsRequest } from '../models/dto/AnalyticsDto';

type ObfuscatingMap = Record<keyof AnalyticsRequest, keyof ObfuscatedAnalyticsRequest>;

export const obfuscatingMap: ObfuscatingMap = {
  eventType: 'a',
  locationId: 'c',
  categoryId: 'd',
  productId: 'e',
  planName: 'f',
  premiumAmount: 'g',
  premiumCurrency: 'h',
  addonNames: 'i',
  cardType: 'j',
  benefitName: 'l',
  eventData: 'm',
  frontendMode: 'n',
  backendMode: 'o',
  planId: 'p',
  benefitId: 'q',
};

export const obfuscateAnalyticsData = (data: AnalyticsRequest): ObfuscatedAnalyticsRequest => {
  return {
    ...Object.fromEntries(
      Object.entries(data).map(([key, value]) => [obfuscatingMap[key as keyof AnalyticsRequest], value]),
    ),
    ...{ k: ANALYTICS_TOKEN },
  };
};
