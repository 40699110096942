import type { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { PromoBenefitBlock } from './components/PromoBenefitBlock';

import styles from './PromoBenefit.module.scss';

type PromoBenefitProps = {
  header?: string;
};

export const PromoBenefit: FC<PromoBenefitProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Promotion Benefit'} name={['promotionBenefit']} showSectionTitle>
      <ContentBlockContainer
        className={styles.content}
        name={['promotionBenefit', 'promotionBenefitPlans']}
        title="Promo Benefit block"
        addButtonText="Add Promo Benefit"
        hideDeleteButton
        showVisibilityToggle
      >
        {props => <PromoBenefitBlock {...props} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
