// eslint-disable-next-line import/named
import { MessageDescriptor, defineMessage } from 'react-intl';

type WarningLabels = 'leaveAmexPageP1' | 'leaveAmexPageP2';

export const WARNING_LABELS: Record<WarningLabels, MessageDescriptor> = {
  leaveAmexPageP1: defineMessage({
    id: 'warning.leave-amex-page1',
    description: 'Warning text before user is redirected from Amex Site',
    defaultMessage:
      'You are now leaving the American Express website and entering a website operated by other parties.' +
      ' American Express does not control or endorse such websites and is not responsible for their contents.',
  }),
  leaveAmexPageP2: defineMessage({
    id: 'warning.leave-amex-page2',
    description: 'Warning text before user is redirected from Amex Site',
    defaultMessage:
      'The use of such website is also subject to the terms of use and other terms and guidelines, if any, contained within each such website. ' +
      'In the event that any of the terms contained herein conflict with the terms of use or other terms and guidelines contained within any such website, ' +
      'then the terms of use and other terms and guidelines for such website shall prevail.',
  }),
};
