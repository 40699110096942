import type { FC } from 'react';

import { Form, FormListChild, Input } from '@saturn/uikit';

import { BlockWithIcon, ContentBlockContainer } from 'shared/components';

export const CoveragesSubsection: FC<FormListChild & { section?: string; required: boolean }> = ({
  name: fieldName,
  section,
  required,
  ...parentProps
}) => {
  return (
    <>
      <Form.Item
        {...parentProps}
        name={[fieldName, 'subtitle']}
        rules={[
          ...(required
            ? [
                {
                  max: 255,
                  message: 'Section Subtitle should be at most 255 characters',
                },
              ]
            : []),
        ]}
      >
        <Input label="Section Subtitle" />
      </Form.Item>
      <ContentBlockContainer
        addButtonText="Add coverage"
        title="Coverage"
        name={[fieldName, 'coverage']}
        section={section}
      >
        {props => <BlockWithIcon {...props} />}
      </ContentBlockContainer>
    </>
  );
};
