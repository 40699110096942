import { PromocodeDetails, PromocodeDto, PromocodesDto } from '../../models/dto/PromocodeDto';
import { axiosClient as api } from '../axiosClient';

export async function getPromocodes(locationId: string): Promise<PromocodesDto> {
  const { data } = await api.get<PromocodesDto>(`/admin/promocode/${locationId}/list`);
  return data;
}

export async function deletePromocode(promocodeId: string): Promise<string> {
  const { data } = await api.delete<string>(`/admin/promocode/${promocodeId}`);
  return data;
}

export async function enablePromocode(promocodeId: string): Promise<string> {
  const { data } = await api.put<string>(`/admin/promocode/${promocodeId}/enable`);
  return data;
}

export async function disablePromocode(promocodeId: string): Promise<string> {
  const { data } = await api.put<string>(`/admin/promocode/${promocodeId}/disable`);
  return data;
}

export async function savePromocode(promocode: PromocodeDto): Promise<PromocodeDto> {
  const { data } = await api.post<PromocodeDto>('/admin/promocode', promocode);
  return data;
}

export async function savePromocodeBenefits(
  promocodeId: string,
  language: string,
  benefits: PromocodeDetails,
): Promise<PromocodeDetails> {
  const { data } = await api.post<PromocodeDetails>(`/admin/promocode/${promocodeId}/benefits/${language}`, benefits);
  return data;
}

export async function getPromocode(promocodeId: string): Promise<PromocodeDto> {
  const { data } = await api.get<PromocodeDto>(`/admin/promocode/${promocodeId}`);
  return data;
}

export async function getPromocodeBenefits(
  promocodeId: string,
  language?: string,
): Promise<PromocodeDetails | undefined> {
  if (!language) {
    return undefined;
  }
  const { data } = await api.get<PromocodeDetails>(`/admin/promocode/${promocodeId}/benefits/${language}`);
  return data;
}
