import { useEffect } from 'react';
import type { FC } from 'react';

import { Checkbox as AntCheckbox } from 'antd';
import noop from 'lodash/noop';

import { CheckboxProps } from './model';

import styles from './Checkbox.module.scss';

export const Checkbox: FC<CheckboxProps> = ({
  title,
  checked,
  isCheckboxOnLeft = false,
  valueSet = noop,
  children,
  readOnly,
  ...rest
}) => {
  useEffect(() => valueSet(checked), [checked, valueSet]);

  return (
    <div className={styles.wrapper}>
      {!isCheckboxOnLeft && title && <span className={'saturn-checkbox-title'}>{title}</span>}
      <AntCheckbox
        checked={checked}
        className={readOnly ? 'saturn-checkbox-read-only' : ''}
        {...(readOnly && { disabled: true })}
        {...rest}
      >
        {children}
      </AntCheckbox>
      {isCheckboxOnLeft && title && <span className={'saturn-checkbox-title'}>{title}</span>}
    </div>
  );
};
