import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC, ReactElement, ReactNode } from 'react';

import { Select as AntSelect } from 'antd';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { addPropsToChildren } from '../../utils';
import { Icon } from '../Icon';
import type { LabelComponentType, SelectProps } from './models';

const getDefaultLabelComponent: LabelComponentType = (value, label, id, loading) => {
  const labelClass = value ? 'label label-float' : 'label';
  return (
    <>
      <div className="float-label">
        <div className="content">{value}</div>
        <div className={labelClass}>
          <label className="select-label" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
      {loading ? (
        <span className="loading-indicator">
          <Icon name="sync-outline" />
        </span>
      ) : (
        <Icon name="arrow-ios-downward-alt" />
      )}
    </>
  );
};

const filterOption: SelectProps['filterOption'] = (input, option) =>
  String(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const Option: typeof AntSelect.Option = AntSelect.Option;

export const Select: FC<SelectProps> = props => {
  const {
    children,
    className,
    optionLabelProp = 'label',
    getLabelComponent = getDefaultLabelComponent,
    placeholder: label,
    valueSet = noop,
    value,
    onChange,
    mode,
    options,
    id,
    loading,
    ...rest
  } = props;

  // TODO: fix react-hooks/exhaustive-deps
  /* eslint-disable react-hooks/exhaustive-deps */
  const getChildrenProps = useCallback(
    (node: ReactElement) => {
      return {
        label: getLabelComponent(node?.props?.children, label, id),
      };
    },
    [label],
  );

  const modifiedChildren = useMemo(
    () => (optionLabelProp !== 'children' ? addPropsToChildren(children, Option, getChildrenProps) : children),
    [children],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    valueSet(value);
    isEmpty(value) && setIsLabelFloating(false);
    !isEmpty(value) && setIsLabelFloating(true);
  }, [valueSet, value]);

  const placeholder = useMemo(() => getLabelComponent('', label, id, loading), [getLabelComponent, label, id, loading]);

  const [isLabelFloating, setIsLabelFloating] = useState<boolean>(false);

  const internalOnChange: SelectProps['onChange'] = (newValue, currentOptions) => {
    onChange && onChange(newValue, currentOptions);
  };

  if (mode === 'multiple' && options) {
    return (
      <div className={cn(className, 'saturn-select-wrapper')}>
        <span
          className={cn(className, 'saturn-select-multi-label', {
            'label-float': isLabelFloating,
          })}
        >
          {label}
        </span>
        <AntSelect
          id={id}
          className={cn(className, 'saturn-select')}
          optionLabelProp={optionLabelProp}
          onChange={internalOnChange}
          showArrow={false}
          mode={mode}
          menuItemSelectedIcon={null}
          value={value}
          dropdownRender={(menu: ReactNode) => <div className="saturn-multiselect-dropdown">{menu}</div>}
          filterOption={filterOption}
          optionFilterProp="label"
          {...rest}
        >
          {options.map(({ label: optionLabel, value: optionValue }) => (
            <Option key={optionValue} label={optionLabel} value={optionValue}>
              <Icon className="saturn-icon-selected" name="checkmark" size={14} color="#006fcf" />
              <Icon className="saturn-icon-not-selected" name="square-outline" size={14} color="#006fcf" />
              {optionLabel}
            </Option>
          ))}
        </AntSelect>
      </div>
    );
  }

  return (
    <AntSelect
      id={id}
      className={cn(className, label ? 'saturn-select' : 'saturn-select-basic')}
      optionLabelProp={optionLabelProp}
      onChange={onChange}
      showArrow={false}
      value={value}
      filterOption={filterOption}
      optionFilterProp="label"
      options={options}
      {...(label && { placeholder })}
      {...rest}
    >
      {modifiedChildren}
    </AntSelect>
  );
};
