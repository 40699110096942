import type { FC } from 'react';

import { Switch } from '../Switch';

type NumberSwitchProps = {
  onChange?: (value: number) => void;
  value?: number;
  checkedValue: number;
  unCheckedValue: number;
};

export const NumberSwitch: FC<NumberSwitchProps> = ({ onChange, value, checkedValue, unCheckedValue, ...rest }) => {
  return (
    <Switch
      onChange={newValue => {
        onChange?.(newValue ? checkedValue : unCheckedValue);
      }}
      checkedChildren={checkedValue}
      unCheckedChildren={unCheckedValue}
      checked={value === checkedValue}
      {...rest}
    />
  );
};
