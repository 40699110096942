import { ProductTranslationDto } from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function getI18n(
  locationId: string,
  locale: string,
): Promise<
  Array<{
    key: string;
    defaultMessage: string;
    locale: string;
    message: string;
  }>
> {
  const { data } = await api.get(`/admin/locations/${locationId}/i18n`, { headers: { 'Accept-Language': locale } });
  return data;
}

export async function updateLocalizedMessage(
  key: string,
  message: string,
  locale: string,
  locationId: string,
): Promise<{ key: string; message: string; locale: string }> {
  const { data } = await api.put(
    `/admin/locations/${locationId}/i18n/${key}`,
    { message },
    { headers: { 'Accept-Language': locale } },
  );
  return data;
}

export async function getTranslationsList(productId: string, locale: string): Promise<ProductTranslationDto[]> {
  const { data } = await api.get<ProductTranslationDto[]>(`/admin/products/${productId}/i18n`, {
    headers: { 'Accept-Language': locale },
  });
  return data;
}

export async function updateTranslation(
  key: string,
  locale: string,
  productId: string,
  message: string,
): Promise<{ message?: string }> {
  const { data } = await api.put(
    `/admin/products/${productId}/i18n/${key}`,
    { message },
    { headers: { 'Accept-Language': locale } },
  );
  return data;
}
