import { Button, Modal } from '@saturn/uikit';

import styles from './DoYouWantToContentModal.module.scss';

type DoYouWantToExportContentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSaveAndExport: () => void;
};
export default function DoYouWantToExportContentModal({
  isOpen,
  onClose,
  onSaveAndExport,
}: DoYouWantToExportContentModalProps) {
  return (
    <Modal
      className={styles.modal}
      open={isOpen}
      onCancel={onClose}
      width={284}
      closable={false}
      footer={() => {
        return (
          <div className={styles.buttonsContainer}>
            <Button type="primary" onClick={onSaveAndExport}>
              Save & Export
            </Button>
            <Button type="text" className={styles.cancel} onClick={onClose}>
              Cancel
            </Button>
          </div>
        );
      }}
    >
      <p className={styles.title}>Do you want to export content?</p>
      <p className={styles.subtitle}>Please save your changes before exporting</p>
    </Modal>
  );
}
