import { FC, ReactNode } from 'react';

import { Collapse, Form, Input, Panel, Rule } from '@saturn/uikit';

import { LanguageTabConsumer } from 'shared/contexts';

import { SectionVisibilitySwitch } from '../SectionVisibilitySwitch/SectionVisibilitySwitch';

import styles from './CollapsiblePanel.module.scss';

interface CollapsiblePanelProps {
  children?: ReactNode;
  header?: ReactNode;
  name?: (string | number)[];
  withoutSwitch?: boolean;
  showSectionTitle?: boolean;
  isRequiredSectionTitle?: boolean;
  sectionTitleName?: string;
  sectionTitleLabel?: string;
  validationRules?: Rule[];
  onCollapse?: (key: string | string[]) => void;
}

export const CollapsiblePanel: FC<CollapsiblePanelProps> = ({
  children,
  header,
  name,
  withoutSwitch,
  showSectionTitle = false,
  isRequiredSectionTitle = true,
  validationRules = [],
  sectionTitleName = 'sectionTitle',
  sectionTitleLabel = 'Section title',
  onCollapse,
}) => {
  const headerId = name?.[name.length - 1]?.toString();

  return (
    <LanguageTabConsumer>
      {({ language }) => {
        return (
          <Collapse defaultActiveKey="1" expandIconPosition="start" expandIcon={undefined} onChange={onCollapse}>
            <Panel
              key="1"
              id={`${headerId}_${language}`}
              header={<div className="saturn-collapse-header">{header}</div>}
              extra={
                name !== undefined &&
                !withoutSwitch && (
                  <SectionVisibilitySwitch
                    onChange={(checked, e) => {
                      e.stopPropagation();
                    }}
                    switchName={[...name, name[0] === 'breadCrumb' ? 'enabled' : 'visible']}
                  />
                )
              }
            >
              {name !== undefined && (
                <Form.Item shouldUpdate>
                  {({ getFieldValue }) => {
                    const isFieldRequired = getFieldValue([...name, 'visible']);
                    return (
                      <Form.Item
                        name={[...name, sectionTitleName]}
                        className={styles.sectionTitleWrapper}
                        hidden={!showSectionTitle}
                        dependencies={[...name, 'visible']}
                        rules={[
                          {
                            required: showSectionTitle && isRequiredSectionTitle && isFieldRequired,
                            message: `${sectionTitleLabel} is required`,
                            whitespace: true,
                          },
                          ...(showSectionTitle && isRequiredSectionTitle && isFieldRequired
                            ? [
                                {
                                  max: 100,
                                  message: `${sectionTitleLabel} should be at most 100 character(s)`,
                                },
                              ]
                            : []),
                          ...(showSectionTitle && isRequiredSectionTitle && isFieldRequired
                            ? [...validationRules]
                            : []),
                        ]}
                      >
                        <Input type={showSectionTitle ? 'text' : 'hidden'} label={sectionTitleLabel} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}
              {children}
            </Panel>
          </Collapse>
        );
      }}
    </LanguageTabConsumer>
  );
};
