import type { FC } from 'react';

import { Collapse as AntCollapse } from 'antd';
import cn from 'classnames';

import { Icon } from '../Icon';
import { CollapseProps } from './models';

import styles from './Collapse.module.scss';

export const Collapse: FC<CollapseProps> = props => {
  const {
    className,
    expandIconPosition = 'end',
    expandIcon = ({ isActive }) => (
      <Icon color="#006fcf" name={isActive ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'} />
    ),
    bordered = false,
    ghost = true,
    children,
    prefixCls,
    ...rest
  } = props;

  return (
    <AntCollapse
      prefixCls={prefixCls}
      className={cn(className, styles.saturnCollapse, 'saturn-collapse')}
      expandIconPosition={expandIconPosition}
      expandIcon={expandIcon}
      bordered={bordered}
      ghost={ghost}
      {...rest}
    >
      {children}
    </AntCollapse>
  );
};

export const Panel = AntCollapse.Panel;
