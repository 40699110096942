import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FC, FocusEventHandler } from 'react';

import { Input as AntInput } from 'antd';
import type { InputNumberProps } from 'antd';
import cn from 'classnames';

import { getRandomUUID } from '../../utils/getRandomUUID';
import { Icon } from '../Icon';
import { CounterInput } from './components/CounterInput';
import { MaskedInput, MaskedInputExtraProps, MaskedInputProps } from './components/MaskedInput';
import { NumberInput } from './components/NumberInput';
import { InputElementProps } from './models';

export const Input: FC<InputElementProps & MaskedInputExtraProps & InputNumberProps> = props => {
  const {
    className,
    id,
    type,
    label,
    description,
    value,
    view = 'input',
    style = {},
    onClick,
    prefix,
    suffix,
    maskType,
    rawNumberInput,
    customErrorMessage,
    defaultValue,
    withDescriptionIcon,
    ...otherInputProps
  } = props;
  const isFieldInput = useMemo(() => !!(value || typeof value === 'number'), [value]);
  const [isActive, setIsActive] = useState<boolean>(isFieldInput);
  const [inputPrefixWidth, setInputPrefixWidth] = useState<number>(0);

  const inputProps = {
    ...otherInputProps,
    ...(customErrorMessage && { status: 'error' as '' | 'warning' | 'error' | undefined }),
  };

  const wrappedSuffix = suffix ? (
    <button
      type="button"
      style={{
        padding: 12,
        border: 0,
        display: 'flex',
        appearance: 'none',
        background: 'transparent',
        font: 'inherit',
      }}
      // @ts-ignore
      onClick={onClick}
    >
      {suffix}
    </button>
  ) : (
    suffix
  );

  // TODO: fix react-hooks/exhaustive-deps
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    isFieldInput !== isActive && setIsActive(isFieldInput);
  }, [isFieldInput]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    // Set input as active if defaultValue exists to render floating label correctly
    setIsActive(!!defaultValue);
  }, [defaultValue]);

  const onFocus: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setIsActive(true);
  }, []);
  const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(e => {
    !(e.target as HTMLInputElement).value && setIsActive(false);
  }, []);

  const fallbackId = useRef(getRandomUUID());

  const InputElement = (() => {
    if (view === 'password') {
      return (
        <AntInput.Password
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          value={value}
          defaultValue={defaultValue}
          onClick={onClick}
          suffix={wrappedSuffix}
          id={id ?? fallbackId.current}
          {...inputProps}
        />
      );
    } else if (view === 'mask' || type === 'tel' || maskType) {
      return (
        <MaskedInput
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          type={type}
          value={value}
          defaultValue={defaultValue}
          maskType={maskType}
          id={id ?? fallbackId.current}
          {...(inputProps as MaskedInputProps)}
        />
      );
    } else if (type === 'number') {
      return (
        <NumberInput
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          value={value}
          defaultValue={defaultValue}
          onClick={onClick}
          suffix={wrappedSuffix}
          id={id ?? fallbackId.current}
          rawNumberInput={rawNumberInput}
          {...inputProps}
        />
      );
    } else if (view === 'counter') {
      return <CounterInput id={id ?? fallbackId.current} {...inputProps} />;
    } else {
      return (
        <AntInput
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          value={value}
          defaultValue={defaultValue}
          onClick={onClick}
          prefix={prefix}
          suffix={wrappedSuffix}
          id={id ?? fallbackId.current}
          {...inputProps}
        />
      );
    }
  })();

  useEffect(() => {
    setInputPrefixWidth(document.querySelector('.ant-input-prefix')?.clientWidth ?? 0);
  }, []);

  return (
    <div
      className={cn(className, 'float-label', {
        active: isActive || isFieldInput || !label || view === 'phone-number',
        'no-label': !label,
      })}
      style={style}
    >
      {InputElement}
      {label && (
        <label
          htmlFor={id ?? fallbackId.current}
          style={prefix && view !== 'phone-number' ? { paddingLeft: `${inputPrefixWidth + 15}px` } : {}}
        >
          {label}
        </label>
      )}
      {description && (
        <span className="input-description">
          {withDescriptionIcon && <Icon name="file-text-outline" size={16} />}
          {description}
        </span>
      )}
      {customErrorMessage && <span className="ant-form-item-explain-error">{customErrorMessage}</span>}
    </div>
  );
};
