import { Menu as AntMenu } from 'antd';
import type { ItemType, MenuDividerType, MenuItemGroupType, MenuItemType } from 'rc-menu/lib/interface';

// eslint-disable-next-line  @typescript-eslint/no-unnecessary-type-assertion
export const Menu = AntMenu as typeof AntMenu;
export const MenuItemGroup = AntMenu.ItemGroup;
export const MenuItem = AntMenu.Item;
export const MenuDivider = AntMenu.Divider;

export type { ItemType, MenuDividerType, MenuItemGroupType, MenuItemType };
