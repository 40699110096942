import type { FC } from 'react';

import { Switch as AntSwitch } from 'antd';
import type { SwitchProps } from 'antd';
import cn from 'classnames';

import styles from './Switch.module.scss';

export const Switch: FC<SwitchProps> = props => {
  const { className, checkedChildren = 'on', unCheckedChildren = 'off', ...rest } = props;

  return (
    <div className={cn(styles.wrapper, 'saturn-switch-wrapper')}>
      <AntSwitch
        className={cn(className, 'saturn-switch')}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        {...rest}
      />
    </div>
  );
};
