import type { FC } from 'react';

import { Alert } from 'antd';

import { Icon } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

type AllYouNeedToKnowProps = {
  header?: string;
  onCollapse?: (key: string | string[]) => void;
};

export const AllYouNeedToKnow: FC<AllYouNeedToKnowProps> = ({ header, onCollapse }) => (
  <CollapsiblePanel
    header={header || 'All You Need To Know'}
    name={['allYouNeedToKnow']}
    showSectionTitle
    onCollapse={onCollapse}
  >
    <Alert
      showIcon
      icon={<Icon color="#f48809" name="alert-circle-outline" />}
      type="warning"
      message="Please note that visibility of the section impacts the sub-section parts - Who Is Covered, What Is Covered, Coverages, FAQs, Documents"
    />
  </CollapsiblePanel>
);
