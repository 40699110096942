import { useLocation } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';

export default function useExportImportEnabled() {
  const location = useLocation();
  const { data: features = {} } = useRequest(() => admin.loadFeatures(), {
    refreshDeps: [location],
  });
  return features['cms-content-import-export-functionality'];
}
