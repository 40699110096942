import { useEffect, useState } from 'react';
import type { FC, SVGProps } from 'react';

import AntdIcon, { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import cn from 'classnames';
import isNumber from 'lodash/isNumber';

import { IconName, iconsMap } from '../../icons';

const sizesMap = {
  small: '18px',
  medium: '24px',
  big: '32px',
};

export interface IconProps extends Omit<IconComponentProps, 'size'> {
  name: IconName;
  size?: number | keyof typeof sizesMap;
  color?: string;
}

type IconWrapper = {
  current: FC<SVGProps<SVGSVGElement>>;
};

const useDynamicSVGImport = (name: string, fontSize?: string) => {
  const [importedIcon, setImportedIcon] = useState<IconWrapper>();

  useEffect(() => {
    const importIcon = async (): Promise<void> => {
      try {
        let icon = (await import(`../../icons/${iconsMap[name as keyof typeof iconsMap]}`)).ReactComponent;
        if ('STORYBOOK_HOOKS_CONTEXT' in window) {
          // storybook can not load uikit icons
          icon = function mockIcon() {
            return (
              <svg
                viewBox="0 0 48 48"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                width={fontSize}
                height={fontSize}
              >
                <path d="m24 0c13.255 0 24 10.745 24 24s-10.745 24-24 24-24-10.745-24-24 10.745-24 24-24zm0 3c-11.579 0-21 9.4205-21 21 0 11.579 9.4205 21 21 21 11.579 0 21-9.4206 21-21 0-11.579-9.4205-21-21-21zm-5.713 12.469 0.12721 0.11683 5.5858 5.5852 5.5858-5.5852c0.73994-0.73994 1.9154-0.77888 2.7012-0.11683l0.12721 0.11683c0.73994 0.73994 0.77888 1.9154 0.11683 2.7012l-0.11683 0.12721-5.5852 5.5858 5.5852 5.5858c0.73994 0.73994 0.77888 1.9154 0.11683 2.7012l-0.11683 0.12721c-0.73994 0.73994-1.9154 0.77888-2.7012 0.11683l-0.12721-0.11683-5.5858-5.5852-5.5858 5.5852c-0.73994 0.73994-1.9154 0.77888-2.7012 0.11683l-0.12721-0.11683c-0.73994-0.73994-0.77888-1.9154-0.11683-2.7012l0.11683-0.12721 5.5852-5.5858-5.5852-5.5858c-0.69883-0.69883-0.77239-1.7862-0.22068-2.5668l0.10385-0.13443 0.11683-0.12721c0.73994-0.73994 1.9154-0.77888 2.7012-0.11683z" />
              </svg>
            );
          };
        }
        setImportedIcon({ current: icon });
      } catch {
        if (process.env.NODE_ENV === 'development') {
          console.warn(`No icon with name ${name} is found`);
        }
      }
    };

    if (process.env.NODE_ENV !== 'test') {
      importIcon();
    }
  }, [name]);

  return { SvgIcon: importedIcon?.current };
};

export const Icon: FC<IconProps> = ({ color, name, className, style, size = 'medium', ...restProps }) => {
  const fontSize = isNumber(size) ? `${size}px` : sizesMap[size];
  const { SvgIcon } = useDynamicSVGImport(name, fontSize);

  if (!SvgIcon) {
    return null;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AntdIcon
      role="presentation"
      className={cn(className, 'saturn-icon')}
      style={{ fontSize, ...style, color }}
      component={SvgIcon}
      {...restProps}
    />
  );
};
