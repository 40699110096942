import { detect } from 'detect-browser';

export function downloadDocument(data: BlobPart, name: string, isBase64Pdf?: boolean): void {
  const browser = detect();
  const link = document.createElement('a');

  let url;
  if (isBase64Pdf) {
    url = `data:application/pdf;base64,${data as string}`;
    link.setAttribute('download', `${name || 'file'}.pdf`);
  } else {
    url = URL.createObjectURL(new Blob([data]));
    link.setAttribute('download', name);
  }

  if (browser && browser.name === 'firefox') {
    window.open(url, '_blank');
    return;
  }

  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
