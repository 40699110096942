import { useEffect, useState } from 'react';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import uniq from 'lodash/uniq';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN, admin } from '@saturn/api';
import type { UserDto } from '@saturn/api';
import { Icon, Popconfirm, Switch, Table, Tooltip } from '@saturn/uikit';

import { useAuth } from 'shared/utils';

interface UsersListProps {
  users: UserDto[];
  loading: boolean;
  onDeleteUser: (user: UserDto) => void;
}

const ellipsisTooltipColumn = (text: string) => {
  return text?.length > 20 ? (
    <Tooltip placement="bottomLeft" title={text}>
      {text}
    </Tooltip>
  ) : (
    <span>{text}</span>
  );
};

export function UsersList({ users, loading, onDeleteUser }: UsersListProps): JSX.Element {
  const { isAuthorized, user } = useAuth();
  const [activeUsers, setActiveUsers] = useState<Record<string, boolean>>(
    Object.fromEntries(users.map(({ id, active }) => [id, active])),
  );

  useEffect(() => {
    setActiveUsers(Object.fromEntries(users.map(({ id, active }) => [id, active])));
  }, [users]);

  const { runAsync: enableUser } = useRequest(admin.enableUser, { manual: true });
  const { runAsync: disableUser } = useRequest(admin.disableUser, { manual: true });
  const columns = [
    {
      title: 'Name',
      ellipsis: { showTitle: false },
      render: (rowUser: UserDto) => ellipsisTooltipColumn(`${rowUser.firstName} ${rowUser.lastName}`),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: { showTitle: false },
      render: ellipsisTooltipColumn,
    },
    {
      title: 'Locations',
      dataIndex: 'attachedPolicies',
      ellipsis: { showTitle: false },
      render: (attachedPolicies: string[]) => {
        const locations = attachedPolicies?.map(police => police.split(':').splice(2, 1)[0]) ?? [];
        const uniqLocations = uniq(locations);
        return ellipsisTooltipColumn(uniqLocations?.length ? uniqLocations.join(', ').toLocaleUpperCase() : '-');
      },
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      width: 150,
      render: (text: string | undefined) => dayjs(text).format('MMM D, YYYY'),
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      width: 150,
      render: (roles: string[]) => roles?.join(' ')?.toUpperCase(),
    },
    {
      title: 'Disabled',
      dataIndex: 'active',
      width: 150,
      render: (_: boolean, record: UserDto) => {
        const { id } = record;
        const isAdminDisablingSuperAdmin = user?.roles.includes(ROLE_ADMIN) && record.roles.includes(ROLE_SUPER_ADMIN);
        if (isAuthorized && isAdminDisablingSuperAdmin) {
          return null;
        }
        return (
          <Switch
            checkedChildren="yes"
            unCheckedChildren="no"
            checked={!activeUsers[id]}
            disabled={user?.id === id}
            data-key="action-disable"
            onChange={() => {
              const active = activeUsers[id];
              setActiveUsers(state => ({ ...state, [id]: !active }));
              if (active) {
                void disableUser(id).catch(() => setActiveUsers(state => ({ ...state, [id]: active })));
              } else {
                void enableUser(id).catch(() => setActiveUsers(state => ({ ...state, [id]: active })));
              }
            }}
          />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: 80,
      render: function deleteUserAction(text: string, record: UserDto) {
        const isDeletingMyself = user?.id === record.id;
        const isAdminDeletingSuperadmin = user?.roles.includes(ROLE_ADMIN) && record.roles.includes(ROLE_SUPER_ADMIN);
        if (isAuthorized && (isDeletingMyself || isAdminDeletingSuperadmin)) {
          return null;
        }
        return (
          <Popconfirm
            placement="bottomRight"
            title="Are you sure you want to delete user?"
            onConfirm={() => onDeleteUser(record)}
            okText="Confirm"
            cancelText="Cancel"
          >
            <Tooltip placement="left" title="Delete">
              <Icon
                data-key="action-delete"
                color="red"
                size={20}
                name="close-circle-outlined"
                className="action-delete"
              />
            </Tooltip>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey={record => record.id}
      columns={columns}
      dataSource={users}
      pagination={false}
      tableLayout="fixed"
    />
  );
}
