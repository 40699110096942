import { useContext } from 'react';

import get from 'lodash/get';

import { Form } from '@saturn/uikit';

import ImportPageContext from '../contexts/ImportPageContext';
import { findPath } from '../utils/processImportedImages';

export default function useImportedInlineBody(name: (string | number)[]) {
  const importContext = useContext(ImportPageContext);
  const form = Form.useFormInstance();
  const fieldId = form.getFieldValue(name)?.id;
  return importContext?.content?.landingMediaFiles.find(f => f.id === fieldId)?.inlineBody;
}

export function useImportedFile(name: (string | number)[]) {
  const importContext = useContext(ImportPageContext);
  const form = Form.useFormInstance();
  const fieldId = form.getFieldValue(name)?.id;
  const path = importContext ? findPath(importContext, fieldId) : [];
  const filteredPathToId = path.filter(subArray => subArray.includes('landingContent'));
  const filteredPathToContent = filteredPathToId?.length > 0 ? filteredPathToId[0].slice(0, -1) : [];
  const fileMetaData = get(importContext, filteredPathToContent);
  const inlineBody = importContext?.content?.landingMediaFiles.find(f => f.id === fieldId)?.inlineBody;
  return { inlineBody, fileMetaData };
}
