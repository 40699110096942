import type { FC } from 'react';

import { VisibilitySwitcher } from 'shared/components';

import styles from './LocationTogglers.module.scss';

export const LocationTogglers: FC = () => {
  return (
    <div className={styles.togglersWrapper}>
      <VisibilitySwitcher
        title={'"Scroll Top" button visibility (Desktop)'}
        name={['scrollTopSection', 'desktopEnabled']}
      />
      <VisibilitySwitcher
        title={'"Scroll Top" button visibility (Mobile)'}
        name={['scrollTopSection', 'mobileEnabled']}
      />
    </div>
  );
};
