import axiosClient, { AxiosError, AxiosRequestHeaders, AxiosResponse, admin } from '@saturn/api';
import { notification } from '@saturn/uikit';

async function refreshAccessToken(): Promise<string | undefined> {
  try {
    const res = await admin.refreshToken();
    if (res.status === 200) {
      const accessToken = res.data.accessToken;
      axiosClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      localStorage.setItem('accessToken', accessToken);
      return accessToken;
    }
  } catch (e) {
    notification.error({
      message: 'Session is expired, sign in again',
    });
    localStorage.removeItem('accessToken');
    setTimeout(() => {
      window.location.reload();
    }, 250);
  }
}

async function retryRequest<T>({ config }: AxiosError<T>, newAccessToken: string): Promise<AxiosResponse<T>> {
  if (config) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${newAccessToken}`;
    } else {
      config.headers = { Authorization: `Bearer ${newAccessToken}` } as AxiosRequestHeaders;
    }
    return axiosClient(config);
  }
  return Promise.reject();
}

export async function refreshAndRetry<T>(error: AxiosError<T>): Promise<AxiosResponse<T>> {
  const newAccessToken = await refreshAccessToken();
  if (newAccessToken) {
    return retryRequest(error, newAccessToken);
  }
  return Promise.reject(error);
}
