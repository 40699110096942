import type { FC } from 'react';

import { Button, Popconfirm, Title } from '@saturn/uikit';

import { useAuth } from '../../utils';
import { PageHeaderProps } from './models';

import styles from './PageHeader.module.scss';

export const PageHeader: FC<PageHeaderProps> = ({ title, subTitle }) => {
  const { isAuthorized, user, logout } = useAuth();

  const onLogout = () => {
    if (isAuthorized) {
      logout();
    }
  };

  return (
    <header>
      <div className="flex">
        <div>
          <Title offset="short" level={3}>
            {title}
          </Title>
          <p className={styles.subTitle}>{subTitle}</p>
        </div>
        {isAuthorized ? (
          <div className="dropdown">
            <span className={styles.name}>
              {user?.firstName} {user?.lastName}
            </span>
            <Popconfirm
              placement="bottomRight"
              title="Are you sure you want to sign out from the current session on Saturn?"
              onConfirm={onLogout}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button type="link">Sign Out</Button>
            </Popconfirm>
          </div>
        ) : null}
      </div>
    </header>
  );
};
