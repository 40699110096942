import { Form, Icon, Input, Tooltip } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

import styles from './CategorySystemInfo.module.scss';

interface CategorySystemInfoProps {
  isLandingPageEnabled?: boolean;
}

export function CategorySystemInfo({ isLandingPageEnabled }: CategorySystemInfoProps) {
  return (
    <CollapsiblePanel header="System Info" withoutSwitch>
      <div className={styles.content}>
        <Form.Item name="hidden" valuePropName="checked" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="locationId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
              whitespace: true,
            },
            {
              max: 100,
              message: 'Name should be at most 100 characters',
            },
          ]}
        >
          <Input label="Name" />
        </Form.Item>
        {isLandingPageEnabled ? (
          <Form.Item
            name="slugPart"
            rules={[
              { required: true, message: 'Slug is required' },
              { max: 40, message: 'Slug should be at most 40 characters' },
              { min: 3, message: 'Slug should be at least 3 characters' },
              {
                pattern: /^[A-Za-z\d-]*$/,
                message: 'There are restricted symbols in the value',
              },
            ]}
          >
            <Input
              label="Slug"
              addonAfter={
                <Tooltip
                  title={
                    'SLUG is the last part of url after the last "/". Please note that the page of a category is not available by old url after change of slug. The value may contain Roman characters, digits, and dashes. Min-max size of the value is 3-40 chars and it must be unique.'
                  }
                >
                  <Icon size={35} name="question-mark-circle-outline" />
                </Tooltip>
              }
            />
          </Form.Item>
        ) : null}
      </div>
    </CollapsiblePanel>
  );
}
