import { Form, Input } from '@saturn/uikit';

import { ContentBlockProps, IconsGallery, MarkdownTip } from 'shared/components';

export default function BenefitsBlock({ required, ...fieldProps }: ContentBlockProps) {
  const blockName = fieldProps.name;
  return (
    <div>
      <Form.Item
        name={[blockName, 'title']}
        rules={[
          {
            required,
            message: 'Title is required',
            whitespace: true,
          },
          ...(required
            ? [
                {
                  min: 1,
                  message: 'Title should be at least 1 character',
                },
                {
                  max: 255,
                  message: 'Title should be at most 255 characters',
                },
              ]
            : []),
        ]}
      >
        <Input label="Title" />
      </Form.Item>
      <MarkdownTip>
        <Form.Item
          name={[blockName, 'description']}
          rules={[
            {
              required,
              message: 'Description is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    min: 1,
                    message: 'Description should be at least 1 character',
                  },
                  {
                    max: 255,
                    message: 'Description should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <Input label="Description" />
        </Form.Item>
      </MarkdownTip>
      <Form.Item
        preserve={false}
        rules={[
          {
            required,
            message: 'Benefit icon is required',
            whitespace: true,
          },
        ]}
        valuePropName="icon"
        name={[blockName, 'thumbnailId']}
      >
        <IconsGallery />
      </Form.Item>
    </div>
  );
}
