import { ReactNode } from 'react';

import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import uniqWith from 'lodash/uniqWith';

import { FormField, FormInstance, FormSubmitFailed, Option, SelectProps, notification } from '@saturn/uikit';

export function prepareOptions(data?: SelectProps['options']): ReactNode {
  return data?.map(({ value, label, disabled = false }) => (
    <Option key={value} data-key={value} value={value} disabled={disabled}>
      {label}
    </Option>
  ));
}

export function filterVisibilityErrors(
  changedFields: FormField[],
  allFields: FormField[],
): FormField['name'][] | undefined {
  const visibilityToggled = changedFields.filter(
    field => last(field.name as string[]) === 'visible' || last(field.name as string[]) === 'enabled',
  );
  if (isEmpty(visibilityToggled)) {
    return [];
  }
  const sections = visibilityToggled.map(({ name }) => first(name as string[]));
  return allFields
    .filter(({ name, errors }) => !isEmpty(errors) && sections.includes((name as string[])[0]))
    .map(({ name }) => name);
}

export function revalidateAfterVisibilityChanged(
  form: FormInstance,
  changedFields: FormField[],
  allFields: FormField[],
): void {
  const b = filterVisibilityErrors(changedFields, allFields);
  if (!isEmpty(b)) {
    form.validateFields(b);
  }
}

export const onFormFinishFailed = ({ errorFields }: FormSubmitFailed): void => {
  uniqWith(errorFields, isEqual).forEach(err => {
    notification.error({
      message: err.errors.join('\n') || 'Something went wrong',
    });
  });
};
// eslint-disable-next-line
export const getVisibleSections = (data: any): Record<string, boolean | null> => {
  const flattedData = {
    ...data,
    ...('allYouNeedToKnow' in data ? data['allYouNeedToKnow'].subSections : {}),
  };
  return Object.fromEntries(
    // @ts-ignore
    Object.entries(flattedData)
      .filter(([, value]) => value && typeof value === 'object')
      // @ts-ignore
      .map(([key, value]) => [key, 'visible' in value ? (value.visible === undefined ? false : value.visible) : null]),
  ) as Record<string, boolean | null>;
};
