import type { FC } from 'react';

import { Button, Form, FormSubmitFailed, Input, notification } from '@saturn/uikit';

import { LoginFormProps } from './models';

export const LoginForm: FC<LoginFormProps> = ({ onLoginFormFinish }) => {
  const [form] = Form.useForm();

  const onFinishFailed = ({ errorFields }: FormSubmitFailed) => {
    errorFields.forEach(err => {
      notification.error({
        message: err.errors || 'Something went wrong',
      });
    });
  };

  return (
    <Form form={form} onFinish={onLoginFormFinish} onFinishFailed={onFinishFailed} preserve={false}>
      <Form.Item
        name="email"
        normalize={(value: string) => value && value.trim()}
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Email is required',
          },
        ]}
      >
        <Input label="Email" />
      </Form.Item>
      <Form.Item colon={false}>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </Form.Item>
    </Form>
  );
};
