import type { FC } from 'react';

import { Button as AntButton } from 'antd';
import cn from 'classnames';

import { Icon } from '../Icon';
import { ButtonProps } from './models';

const Button: FC<ButtonProps> = ({ type = 'default', children, isStaticBtn = false, className, ...rest }) => {
  return (
    <AntButton
      type={type}
      className={cn(className, 'saturn-button', {
        ['static-button-link']: isStaticBtn,
      })}
      {...rest}
    >
      {children}
      {type === 'link' && !isStaticBtn && <Icon className="ant-btn-link-arrow" size={12} name="arrow-right-alt" />}
    </AntButton>
  );
};

export { Button };
