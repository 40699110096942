import type { PRODUCT_TYPE, ProductDetailsDto, ProductDto } from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function getProductList(locationId: string): Promise<ProductDto[]> {
  const { data } = await api.get<ProductDto[]>(`/admin/locations/${locationId}/products`);
  return data;
}

export async function getProductForLocation(locationId: string, categoryId?: string): Promise<Array<ProductDto>> {
  const data = await getProductList(locationId);
  return categoryId ? data.filter(product => product.categoryId === categoryId) : data;
}

export async function getProduct(productId: string): Promise<ProductDto> {
  const { data } = await api.get<ProductDto>(`/admin/products/${productId}`);
  return data;
}

export async function getProductDetails(
  productId: string,
  language: string | undefined,
): Promise<ProductDetailsDto | undefined> {
  if (!language) {
    return undefined;
  }
  const { data } = await api.get<ProductDetailsDto>(`/admin/products/${productId}/landing/${language}`);
  return data;
}

export async function updateProduct(productData: ProductDto): Promise<ProductDto> {
  const { data } = await api.put(`/admin/products/${productData.id}`, productData);
  return data;
}

export async function updateProductDetails(
  productId: string,
  language: string,
  productDetails: ProductDetailsDto & { type: PRODUCT_TYPE },
): Promise<ProductDetailsDto> {
  const { data } = await api.put(`/admin/products/${productId}/landing/${language}`, productDetails);
  return data;
}

export async function createProduct(product: ProductDto): Promise<ProductDto> {
  const { data } = await api.post('/admin/products', product);
  return data;
}

export async function deleteProduct(productId: string): Promise<void> {
  await api.delete(`/admin/products/${productId}`);
}
