import { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, Icon, ImageUploader, Input, Textarea, Title, Tooltip } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import { BenefitsOfInsuranceCard } from './BenefitsOfInsuranceCard';

import styles from './BenefitsOfInsurance.module.scss';

export type BenefitsOfInsuranceProps = {
  header?: string;
};

export const BenefitsOfInsurance: FC<BenefitsOfInsuranceProps> = ({ header }) => {
  const benefitsOfInsuranceInlineBody = useImportedInlineBody(['benefitsOfInsurance', 'fileMetadata']);

  return (
    <CollapsiblePanel header={header || 'What Is Included'} name={['benefitsOfInsurance']} showSectionTitle>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isSectionVisible = getFieldValue(['benefitsOfInsurance', 'visible']);
          return (
            <>
              <Form.Item
                name={['benefitsOfInsurance', 'sectionDescription']}
                rules={[
                  {
                    required: isSectionVisible,
                    message: 'Section description is required',
                    whitespace: true,
                  },
                  ...(isSectionVisible
                    ? [
                        {
                          max: 500,
                          message: 'Section description should be at most 500 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <Textarea label="Section description" />
              </Form.Item>
              <Title className={styles.title} level={4}>
                Section Image
              </Title>
              <Form.Item
                name={['benefitsOfInsurance', 'fileMetadata']}
                rules={[
                  {
                    required: isSectionVisible,
                    message: 'Image is required',
                  },
                ]}
              >
                <ImageUploader
                  inlineBody={benefitsOfInsuranceInlineBody}
                  content="Upload Picture"
                  tooltip="Recommended aspect ratio 1:1"
                  valueFormatter={fileUrlFormatter}
                  action={uploadFileUrl('benefitsOfInsurance_fileMetadata')}
                  customRequest={onCustomRequest}
                  maxSize={500}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['benefitsOfInsurance', 'seoAltTag']}
                rules={[
                  ...(isSectionVisible
                    ? [
                        {
                          max: 255,
                          message: 'Alt Tag for SEO should be at most 255 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <Input
                  label="Alt Tag for SEO"
                  addonAfter={
                    <Tooltip title="Write image alternative text as plain text, HTML syntax is not required">
                      <Icon size={35} name="question-mark-circle-outline" />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <ContentBlockContainer
                name={['benefitsOfInsurance', 'cards']}
                title="Card"
                addButtonText="Add Card"
                showChangePositionButton
                maxItems={4}
              >
                {props => <BenefitsOfInsuranceCard {...props} />}
              </ContentBlockContainer>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
