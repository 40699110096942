import type { ClipboardEventHandler, DragEventHandler, FC, KeyboardEvent } from 'react';

import { Input } from 'antd';

import { InputElementProps } from '../models';

const preventPast: ClipboardEventHandler<HTMLInputElement> = event => {
  event.preventDefault();
  return false;
};
const dropPast: DragEventHandler<HTMLInputElement> = event => {
  event.preventDefault();
  return false;
};

const handleAllowOnlyNumbers = (e: KeyboardEvent<HTMLInputElement>) => {
  // Prevent from entering something except digits in Safari and Firefox
  if (!e.key.match(/^\d+$/)) e.preventDefault();
};

export const NumberInput: FC<InputElementProps> = ({ rawNumberInput, ...rest }) => {
  if (rawNumberInput) {
    return <Input type="number" pattern="[0-9]*" onKeyPress={handleAllowOnlyNumbers} {...rest} />;
  }

  return (
    <Input
      type="number"
      pattern="[0-9]*"
      onKeyPress={handleAllowOnlyNumbers}
      onPaste={preventPast}
      onDrop={dropPast}
      {...rest}
    />
  );
};
