import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import OurPlansBlock from './OurPlansBlock';

import styles from './OurPlans.module.scss';

type OurPlansProps = {
  header?: string;
};
export default function OurPlans({ header }: OurPlansProps) {
  return (
    <CollapsiblePanel header={header || 'Our Plans'} name={['ourPlans']} showSectionTitle>
      <ContentBlockContainer
        name={['ourPlans', 'plans']}
        className={styles.content}
        title="Plan"
        addButtonText="Add Plan"
        showChangePositionButton={true}
        maxItems={4}
        isCollapsible
      >
        {props => {
          return (
            <div className={styles.blocksContentWrapper}>
              <OurPlansBlock {...props} />
            </div>
          );
        }}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
}
