import { useCallback } from 'react';
import type { ChangeEvent, FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, MarkdownTip } from 'shared/components';

import { ProviderOption } from '../../utils';
import InsurancePartnersBlock from '../InsurancePartnersBlock/InsurancePartnersBlock';

type InsurancePartnersProps = {
  header?: string;
  providers: ProviderOption[];
  selectedInsurancePartners: string[];
};

export const InsurancePartners: FC<InsurancePartnersProps> = ({ header, selectedInsurancePartners, providers }) => {
  const getValueFromEvent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    return e.target.value.split(',') || [];
  }, []);

  return (
    <CollapsiblePanel header={header || 'Insurance Partners'} name={['insurancePartners']} showSectionTitle>
      <Form.Item hidden getValueFromEvent={getValueFromEvent} name={['insurancePartners', 'insuranceProviderIds']}>
        <Input label="Insurance Provider Ids" />
      </Form.Item>
      <MarkdownTip>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue(['insurancePartners', 'visible']);
            return (
              <Form.Item
                name={['insurancePartners', 'description']}
                rules={[
                  ...(isFieldRequired
                    ? [
                        {
                          max: 800,
                          message: 'Partner description should be at most 800 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <Textarea label="Description" />
              </Form.Item>
            );
          }}
        </Form.Item>
      </MarkdownTip>
      <InsurancePartnersBlock
        title="Insurance Partners"
        blockTip="Select Partners that should be shown with image"
        name={['insurancePartners', 'insuranceProviderIds']}
        maxItems={providers.length}
        providers={providers}
        selectedInsurancePartners={selectedInsurancePartners}
      />
    </CollapsiblePanel>
  );
};
