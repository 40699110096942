import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';
import type { RuleType } from '@saturn/uikit';

import { IconsGallery, MarkdownTip, Tip } from 'shared/components';
import type { ContentBlockProps } from 'shared/components';

import styles from '../../Contacts.module.scss';

export const ContactsBlock: FC<ContentBlockProps> = ({ name, required, ...fieldProps }) => {
  return (
    <>
      <div className={styles.inputsWrapper}>
        <Tip>
          If any of the fields in the contacts section are left blank, then they will not be shown on the public
          website.
        </Tip>
        <Form.Item
          {...fieldProps}
          name={[name, 'subtitle']}
          rules={[
            ...(required
              ? [
                  {
                    max: 255,
                    message: 'Contact Subtitle should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <Input label="Contact Subtitle" />
        </Form.Item>
        <div className={styles.contactsWrapper}>
          <Form.Item
            {...fieldProps}
            name={[name, 'email']}
            normalize={(value: string) => value?.trim()}
            rules={[
              ...(required
                ? [
                    {
                      type: 'email' as RuleType,
                      message: 'Email is not valid',
                    },
                    {
                      max: 100,
                      message: 'Email should be at most 100 characters.',
                    },
                  ]
                : []),
            ]}
          >
            <Input label="Email (optional)" />
          </Form.Item>
          <Form.Item {...fieldProps} name={[name, 'phoneNumber']}>
            <Input label="Phone Number (optional)" />
          </Form.Item>
        </div>
        <MarkdownTip>
          <Form.Item
            {...fieldProps}
            name={[name, 'schedule']}
            rules={[
              ...(required
                ? [
                    {
                      max: 1000,
                      message: 'Schedule Note should be at most 1000 characters',
                    },
                  ]
                : []),
            ]}
          >
            <Textarea label="Note (Optional)" />
          </Form.Item>
        </MarkdownTip>
        <Form.Item {...fieldProps} preserve={false} valuePropName="icon" name={[name, 'thumbnailId']}>
          <IconsGallery />
        </Form.Item>
      </div>
    </>
  );
};
