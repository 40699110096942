import { InsuranceProviderDto } from '../../models';
import { AdminInsuranceProviderDto } from '../../models/dto/InsuranceProviderDto';
import { axiosClient as api } from '../axiosClient';

export async function getProvidersList(locationId: string | undefined): Promise<InsuranceProviderDto[]> {
  if (!locationId) {
    return [];
  }
  const { data } = await api.get<InsuranceProviderDto[]>(`/admin/locations/${locationId}/insurance-providers`);
  return data;
}

export async function getProvidersByIds(ids: string[]): Promise<InsuranceProviderDto[]> {
  const params = new URLSearchParams();
  ids.forEach(id => {
    params.append('ids', id);
  });
  const { data } = await api.get<InsuranceProviderDto[]>('/insurance-providers', { params });
  return data;
}

export async function updateInsuranceProvider({
  insuranceProviderId,
  partnerDetails,
}: {
  insuranceProviderId: string;
  partnerDetails: AdminInsuranceProviderDto;
}): Promise<InsuranceProviderDto> {
  const { data } = await api.put<InsuranceProviderDto>(
    `/admin/insurance-providers/${insuranceProviderId}`,
    partnerDetails,
  );
  return data;
}
