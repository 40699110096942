import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import { Upload as AntUpload, Tooltip, message, notification } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import cn from 'classnames';
import noop from 'lodash/noop';

import { getAccessToken } from '@saturn/api';

import { getBase64 } from '../../utils';
import { Icon } from '../Icon';
import { ImageContent } from './components/ImageContent';
import { UploadContent } from './components/UploadContent';
import { ImageUploaderProps } from './models';

import styles from './ImageUploader.module.scss';

export const ImageUploader: FC<ImageUploaderProps> = props => {
  const {
    style,
    content,
    tooltip = '',
    listType = 'picture-card',
    className,
    value,
    showUploadList = false,
    valueFormatter,
    onChange = noop,
    beforeUpload,
    isPartnerUploader,
    maxSize = 300,
    inlineBody,
    ...rest
  } = props;
  const [percent, setPercent] = useState<number | undefined>();
  const [imageSrc, setImageSrc] = useState<string | undefined>(() => {
    return valueFormatter(value);
  });

  // TODO: fix react-hooks/exhaustive-deps
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const url = valueFormatter(value);
    setImageSrc(url);
  }, [value]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const updateImage = async (info: UploadChangeParam) => {
    const { status, percent: newParent, response, originFileObj } = info.file;

    switch (status) {
      case 'uploading': {
        setPercent(Number(newParent?.toFixed(0) ?? '0'));
        break;
      }
      case 'done': {
        try {
          const newImageSrc = response?.url ?? (originFileObj ? await getBase64(originFileObj) : '');
          setImageSrc(newImageSrc);
        } catch (e) {
          setImageSrc(undefined);
        }

        setPercent(undefined);
        break;
      }
      case 'error': {
        setImageSrc(undefined);
        setPercent(undefined);
        break;
      }
    }
  };

  const handleChange = async (info: UploadChangeParam) => {
    let infoFormItem;

    const { status, response } = info?.file ?? {};

    if (status === 'done' && (response?.id || response === 'ok')) {
      if (isPartnerUploader) {
        infoFormItem = {
          mimeType: info?.file.originFileObj?.type ?? 'image/svg+xml',
          content: info?.file.originFileObj ? await getBase64(info?.file.originFileObj) : '',
        };
      } else {
        infoFormItem = { id: response.id };
      }
    } else {
      infoFormItem = null;
    }
    onChange(infoFormItem);
    await updateImage(info);
  };

  const defaultBeforeUpload = (file: File) => {
    if (!/\.(jpe?g|png|webp)$/i.test(file.name)) {
      notification.error({
        message: 'Picture has not been uploaded',
        description: 'Only files with these extensions are allowed: png, jpg, jpeg, webp.',
      });
      return false;
    }
    if (Math.ceil(file.size / 1024) > maxSize) {
      notification.error({
        message: 'Picture has not been uploaded',
        description: `The image size should be at most ${maxSize} kB`,
      });
      return false;
    }
    return true;
  };

  const indicatorIcon = useMemo(() => {
    return tooltip ? (
      <Tooltip title={tooltip}>
        <Icon name={'question-mark-circle-outline'} />
      </Tooltip>
    ) : (
      <Icon name={'question-mark-circle-outline'} />
    );
  }, [tooltip]);

  const imgSource = inlineBody || imageSrc;

  return (
    <div style={style} className={cn(className, styles.wrapper, 'saturn-image-uploader')}>
      {imgSource ? (
        <ImageContent
          imageSrc={imgSource}
          onDelete={() => {
            message.info('Image has been successfully deleted');
            setImageSrc(undefined);
            setPercent(undefined);
            onChange(null);
          }}
        />
      ) : (
        <AntUpload
          showUploadList={showUploadList}
          beforeUpload={beforeUpload ?? defaultBeforeUpload}
          listType={listType}
          onChange={handleChange}
          headers={{ authorization: getAccessToken() }}
          {...rest}
        >
          <UploadContent percent={percent} content={content} />

          {indicatorIcon}
        </AntUpload>
      )}
    </div>
  );
};
