import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

export const ListOfPolicies = ({ header }: { header: string }) => {
  return (
    <CollapsiblePanel header={header || 'List of policies'} name={['policyListContent']}>
      <Form.Item
        name={['policyListContent', 'banner', 'title']}
        rules={[
          {
            required: true,
            message: 'Title is required!',
          },
          {
            max: 255,
            message: 'Title should be at most 255 characters',
          },
        ]}
      >
        <Input label="Title" />
      </Form.Item>
      <Form.Item
        name={['policyListContent', 'banner', 'subtitle']}
        rules={[
          {
            required: true,
            message: 'Subtitle is required!',
          },
          {
            max: 255,
            message: 'Subtitle should be at most 255 characters',
          },
        ]}
      >
        <Textarea label="Subtitle" />
      </Form.Item>
      <Form.Item
        name={['policyListContent', 'banner', 'url']}
        rules={[
          {
            type: 'url',
            message: 'Link is not valid',
          },
        ]}
      >
        <Input label="Link (optional)" />
      </Form.Item>
    </CollapsiblePanel>
  );
};
