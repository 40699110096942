import type { FC, ReactNode } from 'react';

import { Title } from '@saturn/uikit';

import styles from './SubTitle.module.scss';

interface SubTitleProps {
  title?: string;
  children?: ReactNode;
}

export const SubTitle: FC<SubTitleProps> = ({ title, children }) => {
  return (
    <Title className={styles.title} offset="short" level={4}>
      {title || children}
    </Title>
  );
};
