import type { FC } from 'react';

import { VersionInfoContainer } from '@saturn/uikit';

import { LayoutProps } from '../../models';

import styles from './LoginLayout.module.scss';

export const LoginLayout: FC<LayoutProps> = ({ children }) => {
  const infoFE = {
    tag: process.env.REACT_APP_BUILD_ARG,
    commitHash: process.env.REACT_APP_COMMIT_HASH_ARG,
    deploymentTimeStamp: process.env.REACT_APP_TIME_STAMP_ARG,
  };

  return (
    <VersionInfoContainer infoFE={infoFE} project="admin">
      <div className={styles.loginWrapper}>{children}</div>
    </VersionInfoContainer>
  );
};
