import type { FC } from 'react';

import { Popconfirm } from 'antd';

import { Button } from '../../../Button';
import { ImageContentProps } from './models';

export const ImageContent: FC<ImageContentProps> = ({ imageSrc, imageAlt, onDelete }) => {
  return (
    <div className="image-content">
      <div className="image-content__button-wrapper">
        <Popconfirm
          placement="top"
          title="Are you sure you want to delete image?"
          onConfirm={onDelete}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button type="primary">Delete Image</Button>
        </Popconfirm>
      </div>
      <img src={imageSrc} alt={imageAlt} />
    </div>
  );
};
