import { useMemo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Tabs } from '@saturn/uikit';

import { LanguageTabProvider } from 'shared/contexts';

import { useAdminLocation } from '../../utils';
import { LanguageTabsProps } from './models';

export const LanguageTabs: FC<LanguageTabsProps> = ({ children, isShowLeaveModal }) => {
  const { formatDisplayName } = useIntl();
  const { adminLocation, locationLanguage, setSupportedLocationLanguage } = useAdminLocation();

  const onLanguageChange = (languageId: string) => {
    if (!isShowLeaveModal) {
      setSupportedLocationLanguage(languageId);
    } else {
      const confirmation = confirm('Your changes are not saved. Are you sure you want to go to another language?');
      if (confirmation) {
        setSupportedLocationLanguage(languageId);
      }
    }
  };

  const tabs = useMemo(
    () =>
      adminLocation?.supportedLanguages?.map(language => {
        return {
          label: formatDisplayName(language, { type: 'language' }),
          key: language,
          children: <LanguageTabProvider value={{ language }}>{children}</LanguageTabProvider>,
        };
      }),
    [adminLocation?.supportedLanguages, children, formatDisplayName],
  );
  return <Tabs destroyInactiveTabPane onChange={onLanguageChange} activeKey={locationLanguage} items={tabs} />;
};
