import type { FC } from 'react';

import dayjs from 'dayjs';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN, admin } from '@saturn/api';
import { FormProps, downloadDocument } from '@saturn/uikit';

import { AnalyticsForm, PageHeader } from 'shared/components';
import { useAdminLocation, useAuth } from 'shared/utils';

const LogsPage: FC = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes(ROLE_SUPER_ADMIN) || user?.roles.includes(ROLE_ADMIN);
  const { adminLocation } = useAdminLocation();
  const onSubmit: FormProps['onFinish'] = async values =>
    admin
      .sendActionlogReport({
        from: dayjs(values.from).format('YYYY-MM-DD'),
        to: dayjs(values.to).format('YYYY-MM-DD'),
        locationId: adminLocation.id,
      })
      .then(({ data, name }) => downloadDocument(data, name))
      .catch(console.error);

  if (!isAdmin) {
    return null;
  }

  return (
    <main>
      <PageHeader title="System Action Logs" />
      <AnalyticsForm onSubmit={onSubmit} />
    </main>
  );
};

export default LogsPage;
