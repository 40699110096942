import { getDocumentNameFromHeaders } from '../../utils';
import { axiosClient as api } from '../axiosClient';

export async function sendAnalyticsReport({
  from,
  to,
}: {
  from: string;
  to: string;
}): Promise<{ data: BlobPart; name: string }> {
  const params = new URLSearchParams();
  params.append('from', from);
  params.append('to', to);
  return api
    .post('/admin/analytics/report', params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
    })
    .then(({ data, headers }) => ({ data, name: getDocumentNameFromHeaders(headers) }));
}

export const sendActionlogReport = (params: {
  from: string;
  to: string;
  locationId: string;
}): Promise<{ data: BlobPart; name: string }> =>
  api
    .get('/admin/actionlog/report', { params })
    .then(({ data, headers }) => ({ data, name: getDocumentNameFromHeaders(headers) }));

export const sendErrorReport = ({
  providerId,
  from,
  to,
}: {
  from: string;
  to: string;
  providerId: string;
}): Promise<unknown> => {
  const params = new URLSearchParams();
  params.append('from', from);
  params.append('to', to);
  return api.post(`/admin/analytics/${providerId}/error-report`, params, {
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
  });
};
