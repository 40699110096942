import type { FC } from 'react';

import { Form, NumberSwitch } from '@saturn/uikit';

import { ProductsPerRowToggleProps } from './models';

import styles from './ProductsPerRowToggle.module.scss';

export const ProductsPerRowToggle: FC<ProductsPerRowToggleProps> = ({
  switchName,
  checkedValue,
  unCheckedValue,
  text,
  isChecked,
}) => {
  return (
    <span className={styles.switch}>
      <span className={styles.switchText}>{text}</span>
      <Form.Item className={styles.toggler} name={switchName} initialValue={isChecked ? checkedValue : unCheckedValue}>
        <NumberSwitch checkedValue={checkedValue} unCheckedValue={unCheckedValue} />
      </Form.Item>
    </span>
  );
};
