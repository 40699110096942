import { useState } from 'react';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import { admin } from '@saturn/api';
import { Button, Col, DatePicker, Form, Option, Row, Select } from '@saturn/uikit';

import { useAdminLocation } from 'shared/utils';

type ErrorReportFormProps = {
  onSubmit: (values: unknown) => void;
};

export function ErrorReportForm({ onSubmit }: ErrorReportFormProps): JSX.Element {
  const { adminLocation } = useAdminLocation();
  const [fromDate, setFromDate] = useState<Dayjs | null>();
  const [toDate, setToDate] = useState<Dayjs | null>();
  const { data: providers = [] } = useRequest(
    () =>
      admin
        .getProvidersList(adminLocation?.id)
        .then(data => data.map(({ title, id }) => ({ label: title, value: id }))),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  return (
    <Form onFinish={onSubmit}>
      <Form.Item
        name="providerId"
        rules={[
          {
            required: true,
            message: 'Insurance Provider is required',
          },
        ]}
      >
        <Select placeholder="Insurance Provider" style={{ maxWidth: 290 }}>
          {providers?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Form.Item
            name="from"
            normalize={date => date && dayjs(date).startOf('day')}
            rules={[
              {
                required: true,
                message: 'Start date is required',
              },
            ]}
          >
            <DatePicker
              label="From"
              showNow={false}
              disabledDate={(date: Dayjs) =>
                (date && date.isAfter(dayjs().endOf('day'))) || dayjs(date).isSameOrAfter(toDate)
              }
              onChange={(date: Dayjs | null) => setFromDate(dayjs(date).startOf('day'))}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="to"
            normalize={date => date && dayjs(date).endOf('day')}
            rules={[
              {
                required: true,
                message: 'End date is required',
              },
            ]}
          >
            <DatePicker
              label="To"
              showNow={false}
              disabledDate={(date: Dayjs) =>
                date && date.isBefore(dayjs().endOf('day')) && dayjs(date).isSameOrBefore(fromDate?.endOf('day'))
              }
              onChange={(date: Dayjs | null) => setToDate(dayjs(date).endOf('day'))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">
        Send Report
      </Button>
    </Form>
  );
}
