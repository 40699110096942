import { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { PromocodeBasic, PromocodeDetails } from '@saturn/api/src/models/dto/PromocodeDto';
import { formatPayloadForPromocodeApi } from '@saturn/api/src/utils/timezone.utils';
import { Button, Card, Form, FormSubmitFailed, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { onFormFinishFailed, useAdminLocation } from 'shared/utils';

import { PromocodeInfo } from '../features/promocodes/PromocodeInfo/PromocodeInfo';
import { PromotionBenefits } from '../features/promocodes/PromotionBenefits/PromotionBenefits';

import styles from './styles.module.scss';

const PromocodeCreatePage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromocodeBasic>();
  const [formDetails] = Form.useForm<PromocodeDetails>();

  useEffect(() => {
    form.setFieldsValue({
      isStartNow: true,
      isNoExpired: true,
    });
    formDetails.setFieldsValue({
      benefits: [{ planName: '', benefitTitle: '', benefitDescription: '', thumbnailId: '' }],
    });
  }, []);

  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const apiProducts = locationProducts.filter(p => p.type === 'API');

  const { runAsync: savePromocode } = useRequest(admin.savePromocode, { manual: true });
  const { runAsync: savePromocodeBenefits } = useRequest(admin.savePromocodeBenefits, { manual: true });

  const onError = () => {
    notification.error({
      message: 'Error during promocode creation has happened',
    });
  };

  const formFinished = async () => {
    try {
      const promocodeData = await form.validateFields();
      const promocodeDetails = await formDetails.validateFields();

      const promocodesWithFormattedTimeFields = formatPayloadForPromocodeApi({
        isActive: true,
        promocodeData,
        language: locationLanguage as string,
        locationId,
      });
      const mapedBenefits: PromocodeDetails = {
        benefits: promocodeDetails.benefits,
      };

      const newPromocode = await savePromocode(promocodesWithFormattedTimeFields).catch(onError);
      if (newPromocode && newPromocode.id) {
        await savePromocodeBenefits(newPromocode.id, locationLanguage as string, mapedBenefits),
          notification.success({
            message: 'Promocode has been successfully created',
          });
        const [, locationInPath, promocodes] = location.pathname.split('/');
        navigate(`/${locationInPath}/${promocodes}`, { replace: true });
      }
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Create Promocodes" subTitle="Create Promocode" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Form name="promocode" form={form} onFinishFailed={onFormFinishFailed} preserve={false}>
              <PromocodeInfo products={apiProducts} loadingProducts={loadingProducts} />
            </Form>
            <Form name="promocodeDetails" form={formDetails} onFinishFailed={onFormFinishFailed} preserve={false}>
              <PromotionBenefits />
            </Form>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Save
                </Button>
              </Form.Item>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default PromocodeCreatePage;
