import { BannersDto, LocationDto } from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function getPoliciesMarketingContent(
  location: LocationDto,
  language: string | undefined,
  type: 'dashboard' | 'policyDetails',
): Promise<BannersDto | undefined> {
  if (language && location.supportedLanguages.includes(language)) {
    const { data } = await api.get<BannersDto>(
      `/admin/locations/${location.id}/policies-marketing-content/${language}/${type}`,
    );
    return data;
  } else {
    return undefined;
  }
}

export async function updatePoliciesMarketingContent(
  locationId: string,
  language: string,
  detailsDto: Omit<BannersDto, 'id' | 'language' | 'locationId'>,
  type: 'dashboard' | 'policyDetails',
): Promise<void> {
  const { data } = await api.put(
    `/admin/locations/${locationId}/policies-marketing-content/${language}/${type}`,
    detailsDto,
  );
  return data;
}
