import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import { BannersDto, admin } from '@saturn/api';
import { Button, Card, Empty, Form, FormProps, Icon, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { PROMPT_MESSAGE } from 'shared/constants';
import { onFormFinishFailed, revalidateAfterVisibilityChanged, useAdminLocation, usePrompt } from 'shared/utils';

import { PolicyDetailsTabs } from '../features/hero-banners/components/PolicyDetailsTabs/PolicyDetailsTabs';

import styles from './styles.module.scss';

const PolicyDetailsPage: FC = () => {
  const { adminLocation, locationLanguage } = useAdminLocation();
  const [form] = Form.useForm<BannersDto>();
  PolicyDetailsTabs;
  const locationId = adminLocation?.id;
  const [isErrorExists, setIsErrorExists] = useState<boolean>(false);
  const [isShowLeaveModal, setShowLeaveModal] = useState<boolean>(false);

  usePrompt(PROMPT_MESSAGE, isShowLeaveModal);

  //<editor-fold desc="API Requests">
  const { data: policyDetailsData, loading: policyDetailsDataLoading } = useRequest(
    async () => admin.getPoliciesMarketingContent(adminLocation, locationLanguage, 'policyDetails'),
    {
      refreshDeps: [locationLanguage, adminLocation],
      onSuccess: data => {
        if (data) {
          form.setFieldsValue(data);
          setIsErrorExists(false);
        }
      },
      onError: () => {
        setIsErrorExists(true);
      },
    },
  );

  useEffect(() => {
    setIsErrorExists(false);
  }, [locationLanguage, adminLocation]);

  const { run: updatePolicyDetails } = useRequest(admin.updatePoliciesMarketingContent, {
    manual: true,
    onSuccess: () => {
      notification.success({
        message: 'Policy details page has been successfully updated',
      });
    },
  });
  //</editor-fold>

  const onValuesChange: FormProps<BannersDto>['onValuesChange'] = useCallback(() => {
    !isShowLeaveModal && setShowLeaveModal(true);
  }, [isShowLeaveModal]);

  if (isErrorExists) {
    return <Empty description="Section is not configured" />;
  }

  return (
    <main>
      {adminLocation && <PageHeader title="Policy details page" subTitle="Edit policy details banner and contacts" />}
      <Form
        form={form}
        onFinish={values => {
          locationLanguage && updatePolicyDetails(locationId, locationLanguage, values, 'policyDetails');
          setShowLeaveModal(false);
        }}
        onFinishFailed={onFormFinishFailed}
        preserve={false}
        onValuesChange={onValuesChange}
        onFieldsChange={(changedFields, allFields) => revalidateAfterVisibilityChanged(form, changedFields, allFields)}
        initialValues={policyDetailsData}
      >
        <Spin spinning={policyDetailsDataLoading} indicator={<Icon name="sync-outline" />}>
          {policyDetailsData && (
            <div className={styles.stickyContainer}>
              <div className={styles.content}>
                <PolicyDetailsTabs isShowLeaveModal={isShowLeaveModal} />
              </div>
              <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
                <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Card>
              </StickyBox>
            </div>
          )}
        </Spin>
      </Form>
    </main>
  );
};

export default PolicyDetailsPage;
