import { LocationDetailsDto, LocationDto } from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function getLocationDetails(
  location: LocationDto,
  language: string | undefined,
): Promise<LocationDetailsDto | undefined> {
  if (language && location.supportedLanguages.includes(language)) {
    const { data } = await api.get<LocationDetailsDto>(`/admin/locations/${location.id}/landing/${language}`);
    return data;
  } else {
    return undefined;
  }
}

export async function updateLocationDetails(
  locationId: string,
  language: string,
  detailsDto: LocationDetailsDto,
): Promise<void> {
  const { data } = await api.put(`/admin/locations/${locationId}/landing/${language}`, detailsDto);
  return data;
}

export async function getLocationList(): Promise<LocationDto[]> {
  const { data } = await api.get<LocationDto[]>('/admin/locations');
  return data;
}

export async function getLocation(locationId: string): Promise<LocationDto> {
  const { data } = await api.get<LocationDto>(`/admin/locations/${locationId}`);
  return data;
}

export async function updateLocation(locationId: string, locationData: LocationDto): Promise<void> {
  const { data } = await api.put(`/admin/locations/${locationId}`, locationData);
  return data;
}
