import { useEffect } from 'react';
import type { FC, ReactNode } from 'react';

import { Radio } from 'antd';
import type { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio/interface';
import cn from 'classnames';
import noop from 'lodash/noop';

import styles from './Radio.module.scss';

const { Group } = Radio;
export interface RadioGroupProps extends AntRadioGroupProps {
  valueSet?: (value: this['value']) => void;
  title?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  containerClassName?: string;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  title,
  buttonStyle,
  value,
  valueSet = noop,
  children,
  direction = 'horizontal',
  containerClassName,
  ...rest
}) => {
  useEffect(() => valueSet(value), [value, valueSet]);
  return (
    <div
      className={cn(
        styles.wrapper,
        containerClassName === 'buttons_with_icon' && styles.buttonsWithIcon,
        buttonStyle === 'solid' && styles.buttons,
        buttonStyle === 'solid' ? 'saturn-solid-radio-buttons' : 'saturn-radio-buttons',
        styles[direction],
        containerClassName,
      )}
    >
      {title && <span className="saturn-radio-title">{title}</span>}
      <Group value={value} buttonStyle={buttonStyle} {...rest}>
        {children}
      </Group>
    </div>
  );
};
