import { uploadFileUrl } from '@saturn/api';
import { Form, Icon, ImageUploader, Input, Tooltip, notification } from '@saturn/uikit';

import { CollapsiblePanel, SectionVisibilitySwitch, SubTitle } from 'shared/components';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import { getBannerRules, getPhoneNumberRules, getSubtitleRules, getTimeoutRules, getTitleRules } from './utils';

import styles from './CallbackMobile.module.scss';

const SECTION_NAME = 'callback';
const SUB_SECTION_NAME = 'mobileNumberPopUp';
const maxSize = 100;

export const CallbackMobile = () => {
  const callbackMobileImageName = [SECTION_NAME, SUB_SECTION_NAME, 'fileMetadata'];
  const callbackMobileImageInline = useImportedInlineBody(callbackMobileImageName);

  const beforeUpload = (file: File) => {
    if (!/\.(png|webp)$/i.test(file.name)) {
      notification.error({
        message: 'Picture has not been uploaded',
        description: 'Only files with these extensions are allowed: png,webp.',
      });
      return false;
    }
    if (Math.ceil(file.size / 1024) > maxSize) {
      notification.error({
        message: 'Picture has not been uploaded',
        description: `The image size should be at most ${maxSize} kB`,
      });
      return false;
    }
    return true;
  };

  return (
    <CollapsiblePanel withoutSwitch header="Callback" name={[SECTION_NAME]}>
      <SubTitle>Callback button/form visibility</SubTitle>
      <div className={styles.enableCallback}>
        <SectionVisibilitySwitch switchName={[SECTION_NAME, 'enableCallBack']} withoutLabel />

        <Tooltip title="Displays a callback button widget. Click to open a pop-up where you can enter your details for a call-back from our team. Note: Callback availability depends on integration with your location.">
          <Icon color="#8E9092" size={25} name="question-mark-circle-outline" />
        </Tooltip>
      </div>

      <SubTitle>Mobile number pop-up</SubTitle>
      <div className={styles.wrapper}>
        <Form.Item className={styles.disableMargins} shouldUpdate>
          {({ getFieldValue }) => {
            return (
              <Form.Item
                rules={getBannerRules(getFieldValue([SECTION_NAME, SUB_SECTION_NAME, 'isVisible']))}
                name={callbackMobileImageName}
              >
                <ImageUploader
                  maxSize={maxSize}
                  inlineBody={callbackMobileImageInline}
                  content="Upload Logo"
                  tooltip="120x180 recommended resolution or 3:2 aspect ratio"
                  action={uploadFileUrl('banner_image')}
                  customRequest={onCustomRequest}
                  valueFormatter={fileUrlFormatter}
                  beforeUpload={beforeUpload}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <div>
          <div className={styles.popupVisibility}>
            Pop-up visibility
            <Form.Item shouldUpdate>
              {({ validateFields }) => {
                return (
                  <SectionVisibilitySwitch
                    onChange={() => {
                      validateFields();
                    }}
                    withoutLabel
                    switchName={[SECTION_NAME, SUB_SECTION_NAME, 'isVisible']}
                  />
                );
              }}
            </Form.Item>
            <Tooltip title="Shows a pop-up if you're inactive for specified time, suggesting you call a specified number for faster assistance.">
              <Icon color="#8E9092" size={25} name="question-mark-circle-outline" />
            </Tooltip>
          </div>
          <Form.Item className={styles.disableMargins} shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                rules={getTimeoutRules(getFieldValue([SECTION_NAME, SUB_SECTION_NAME, 'isVisible']))}
                name={[SECTION_NAME, SUB_SECTION_NAME, 'visibleTimeout']}
              >
                <Input className={styles.timeoutInput} label="Timeout before pop-up appears, sec" />
              </Form.Item>
            )}
          </Form.Item>
        </div>
      </div>
      <div>
        <Form.Item className={styles.disableMargins} shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              rules={getTitleRules(getFieldValue([SECTION_NAME, SUB_SECTION_NAME, 'isVisible']))}
              name={[SECTION_NAME, SUB_SECTION_NAME, 'title']}
            >
              <Input className={styles.longInputs} label="Title" />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item className={styles.disableMargins} shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              rules={getPhoneNumberRules(getFieldValue([SECTION_NAME, SUB_SECTION_NAME, 'isVisible']))}
              name={[SECTION_NAME, SUB_SECTION_NAME, 'phoneNumber']}
            >
              <Input className={styles.longInputs} label="Mobile Number" />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item className={styles.disableMargins} shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              rules={getSubtitleRules(getFieldValue([SECTION_NAME, SUB_SECTION_NAME, 'isVisible']))}
              name={[SECTION_NAME, SUB_SECTION_NAME, 'subtitle']}
            >
              <Input className={styles.longInputs} label="Sub title" />
            </Form.Item>
          )}
        </Form.Item>
      </div>
    </CollapsiblePanel>
  );
};
