import { useRef, useState } from 'react';
import type { ReactNode } from 'react';

import { TimePicker as AntTimePicker } from 'antd';
import type { TimePickerProps as AntTimePickerProps } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import styles from './TimePicker.module.scss';

type TimePickerProps = AntTimePickerProps & {
  label?: ReactNode;
  value?: Dayjs;
  className?: string;
  timezone?: string;
  placeholder?: string;
};

export function TimePicker({
  id,
  className,
  label,
  value,
  format = 'HH:mm:ss',
  timezone = '+00:00',
  onChange,
  placeholder,
  ...rest
}: TimePickerProps): JSX.Element {
  const [isActive, setIsActive] = useState(Boolean(value));
  const pickerRef = useRef<typeof AntTimePicker | null>(null);
  const timePickerTitles = format
    .toString()
    .split(':')
    .map((el, idx) => <div key={`${el}_${idx}`}>{el.toUpperCase()}</div>);

  return (
    <div
      className={cn(
        className,
        styles.timePickerContainer,
        'saturn-time-picker',
        'float-label',
        { active: isActive || !!value },
        { 'no-label': !label },
      )}
    >
      <AntTimePicker
        ref={pickerRef}
        id={id}
        onBlur={e => !(e.target as HTMLInputElement).value && setIsActive(false)}
        onFocus={() => setIsActive(true)}
        placeholder={placeholder ?? ''}
        format={format}
        allowClear={false}
        onChange={date => {
          if (date) {
            const newValue = dayjs(date).utcOffset(timezone, true);
            if (onChange) onChange(newValue, newValue.format());
          }
        }}
        value={value}
        getPopupContainer={trigger => trigger.parentElement as HTMLElement}
        renderExtraFooter={() => (
          <div>
            <div
              className={styles.timePickerTitles}
              style={{ gridTemplateColumns: timePickerTitles.length === 3 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)' }}
            >
              {timePickerTitles}
            </div>
            <div className={styles.nowButtonContainer}>
              <button
                type="button"
                className="ant-btn ant-btn-primary ant-btn-sm"
                onClick={() => {
                  const now = dayjs().utcOffset(timezone);
                  if (onChange) onChange(now, now?.format() ?? '');
                }}
              >
                Now
              </button>
            </div>
          </div>
        )}
        {...rest}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
}
