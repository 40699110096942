import type { FC } from 'react';

import { Progress as AntProgress } from 'antd';
import type { ProgressProps } from 'antd';
import cn from 'classnames';

export const Progress: FC<ProgressProps> = props => {
  const { className, ...rest } = props;

  return <AntProgress className={cn(className, 'saturn-progress')} {...rest} />;
};
