import { createContext, useCallback, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRequest } from 'ahooks';
import noop from 'lodash/noop';

import { LocationDto, admin } from '@saturn/api';
import { Icon, Spin, notification } from '@saturn/uikit';

import { useAuth } from 'shared/utils';

import { LocationStore } from './models';

const defaultState: LocationStore = {
  adminLocation: {
    id: 'fake',
    name: 'fake',
    scrollTopSection: {
      desktopEnabled: true,
      mobileEnabled: false,
    },
    supportedLanguages: [],
    status: 'DRAFT',
    slugPart: 'main',
  },
  setAdminLocation: noop,
  setAvailableLocation: noop,
  locations: [],
  locationLanguage: undefined,
  setSupportedLocationLanguage: noop,
};

export const AdminLocationContext = createContext(defaultState);

export const AdminLocationContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthorized, isAuthEnabled } = useAuth();
  const [adminLocation, setAdminLocation] = useState<LocationDto>(defaultState.adminLocation);
  const [locationLanguage, setLocationLanguage] = useState<string>();

  const [, pathLocationId, ...restPath] = location.pathname.split('/');

  useEffect(() => {
    adminLocation && setLocationLanguage(adminLocation.supportedLanguages[0]);
  }, [adminLocation]);

  useEffect(() => {
    if (
      pathLocationId &&
      adminLocation &&
      adminLocation.id !== defaultState.adminLocation.id &&
      pathLocationId !== adminLocation.id
    ) {
      if (location.pathname.split('/').slice(-2).join('/') === 'location/settings') {
        navigate(`/${adminLocation?.id || pathLocationId}/location/settings`, { replace: true });
        return;
      }

      const path = [...restPath];
      if (!(path.length % 2)) {
        path.pop();
      }
      navigate(`/${adminLocation?.id || pathLocationId}/${path.join('/')}`.replaceAll(/\/{2,}/gi, '/'), {
        replace: true,
      });
    }
  }, [adminLocation]);

  const {
    run: fetchGetLocationList,
    data: locations = [],
    loading: locationsLoading,
  } = useRequest(admin.getLocationList, {
    manual: true,
    onSuccess: locationsData => {
      const initialLocation = pathLocationId
        ? locationsData?.find(({ id }) => id === pathLocationId) || locationsData[0]
        : locationsData[0];
      setAdminLocation(initialLocation);
    },
    onError: () => {
      isAuthorized &&
        notification.error({
          message: 'No locations',
        });
    },
  });

  useEffect(() => {
    if (isAuthorized) {
      fetchGetLocationList();
    }
  }, [isAuthorized]);

  const setSupportedLocationLanguage = useCallback(
    (languageId: string) => {
      if (adminLocation?.supportedLanguages.includes(languageId)) {
        setLocationLanguage(languageId);
      }
    },
    [adminLocation],
  );

  const setAvailableLocation = useCallback(
    (locationId: string) => {
      if (locationId === adminLocation?.id) {
        return;
      }

      const availableLocation = locations.find(newLocation => newLocation.id === locationId);

      if (availableLocation) {
        setAdminLocation(availableLocation);
      }
    },
    [adminLocation, locations],
  );

  return (
    <AdminLocationContext.Provider
      value={{
        adminLocation,
        locations,
        setAdminLocation,
        locationLanguage,
        setAvailableLocation,
        setSupportedLocationLanguage,
      }}
    >
      <Spin spinning={locationsLoading} indicator={<Icon name="sync-outline" />}>
        {isAuthEnabled && !isAuthorized
          ? children
          : locations && adminLocation && adminLocation.id !== defaultState.adminLocation.id && children}
      </Spin>
    </AdminLocationContext.Provider>
  );
};
