import type { FC } from 'react';

import { CollapsiblePanel, Tip } from 'shared/components';

interface ProductQuoteFormsProps {
  header?: string;
}

export const ProductQuoteForms: FC<ProductQuoteFormsProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header ?? 'Product Quote Forms'} name={['productQuoteForms']} showSectionTitle>
      <Tip>
        The section effective only for categories with products that have Quote Forms built with front-end approach
      </Tip>
    </CollapsiblePanel>
  );
};
