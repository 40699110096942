import type { FC } from 'react';

import { BlockWithIcon, CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { NextStepsProps } from './model';

import styles from './NextSteps.module.scss';

export const NextSteps: FC<NextStepsProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Next Steps'} name={['nextSteps']}>
      <ContentBlockContainer
        className={styles.content}
        name={['nextSteps', 'steps']}
        addButtonText="Add next step"
        title="Step"
        maxItems={4}
        showChangePositionButton={true}
      >
        {props => <BlockWithIcon showTitle={false} {...props} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
