import { useMemo } from 'react';
import type { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';

import { PRODUCT_TYPE_API, ProductDto } from '@saturn/api';
import { Icon, Popconfirm, Table } from '@saturn/uikit';

import { ProductsListProps } from './models';

export const ProductsList: FC<ProductsListProps> = ({ products, onDeleteProduct }) => {
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Category',
        dataIndex: 'categoryId',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        render: (text: string) => dayjs(text).format('MMM D, YYYY'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Edit',
        dataIndex: 'id',
        render: (id: string) => {
          const path = `${location.pathname}/${id}`.replaceAll(/\/{2,}/gi, '/');

          return (
            <Link data-key="action-edit" to={path}>
              <Icon name="edit-2-outline" />
            </Link>
          );
        },
        width: 100,
      },
      {
        title: 'Translate',
        dataIndex: 'id',
        render: (id: string, product: ProductDto) => {
          const path = `${location.pathname}/${id}/translate`.replaceAll(/\/{2,}/gi, '/');

          return product.type === PRODUCT_TYPE_API && product.customLocalizationMessages ? (
            <Link data-key="action-translate" to={path}>
              <Icon name="file-text-outline" />
            </Link>
          ) : null;
        },
        width: 100,
      },
      {
        title: 'Delete',
        dataIndex: 'id',
        render: (id: string, product: ProductDto) => {
          return product.type === 'NON_API' ? (
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this product?"
              onConfirm={() => onDeleteProduct(id)}
              okText="Delete"
              cancelText="Cancel"
            >
              <Icon data-key="action-delete" color="red" name="trash-2-outline" />
            </Popconfirm>
          ) : null;
        },
        width: 100,
      },
    ],
    [location.pathname, onDeleteProduct],
  );

  return <Table rowKey={record => record.id} columns={columns} dataSource={products} pagination={false} />;
};
