import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

import { LegalDisclaimerProps } from './model';

export const LegalDisclaimer: FC<LegalDisclaimerProps> = ({ header }) => {
  return (
    <CollapsiblePanel
      header={header || 'Legal Disclaimer'}
      name={['planLegalDisclaimer']}
      isRequiredSectionTitle={false}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue(['planLegalDisclaimer', 'visible']);
          return (
            <Form.Item
              name={['planLegalDisclaimer', 'summaryDisclaimer']}
              rules={[
                {
                  required: isFieldRequired,
                  message: 'Disclaimer is required',
                  whitespace: true,
                },
                ...(isFieldRequired
                  ? [
                      {
                        max: 255,
                        message: 'Disclaimer should be at most 255 character(s)',
                      },
                    ]
                  : []),
              ]}
            >
              <Input type="text" label="Disclaimer" />
            </Form.Item>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
