import { FC } from 'react';

import isNil from 'lodash/isNil';

import { PRODUCT_TYPE_NON_API, uploadFileUrl } from '@saturn/api';
import { Form, Icon, ImageUploader, Input, Textarea, Tooltip } from '@saturn/uikit';

import { CollapsiblePanel, MarkdownTip } from 'shared/components';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import { GeneralInfoProps, GeneralInfoType, TypeStrings } from './model';

import styles from './GeneralInfo.module.scss';

const strings: Record<GeneralInfoType, TypeStrings> = {
  product: {
    inputName: 'Product Name',
    inputMarketingName: 'Product Marketing Name',
    imageTooltip: 'This image will appear where the product is mentioned on pages other than the Product Landing page',
    descriptionTooltip: 'Description for the product card',
  },
  category: {
    inputName: 'Category Name',
    imageTooltip:
      'This image will appear where the category is mentioned on pages other than the Category Landing page',
    descriptionTooltip: 'Description for the category card',
  },
};

export const GeneralInfo: FC<GeneralInfoProps> = ({
  type,
  productType,
  landingType,
  header,
  isNewProduct = false,
  isLandingPageEnabled,
}) => {
  const name = ['info'];
  const generalInfoImageInline = useImportedInlineBody([...name, 'fileMetadata']);

  return (
    <CollapsiblePanel header={header || 'General Info'} name={[...name]} withoutSwitch>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <Form.Item
            name={[...name, 'fileMetadata']}
            rules={[
              {
                required: true,
                message: 'Card Image is required',
              },
            ]}
          >
            <ImageUploader
              inlineBody={generalInfoImageInline}
              content="Upload Card Image"
              tooltip={strings[type].imageTooltip}
              className={styles.image_container}
              valueFormatter={fileUrlFormatter}
              action={uploadFileUrl('info_image')}
              customRequest={onCustomRequest}
              style={{
                width: 265,
                height: 225,
              }}
            />
          </Form.Item>
          <div className={styles.inputsWrapper}>
            <Form.Item
              name={[...name, 'name']}
              rules={[
                {
                  required: true,
                  message: `${strings[type].inputName} is required`,
                  whitespace: true,
                },
                {
                  max: 100,
                  message: `${strings[type].inputName} should be at most 100 characters`,
                },
              ]}
            >
              <Input
                label={strings[type].inputName}
                addonAfter={
                  <Tooltip title="This is the name that will be displayed on the public website">
                    <Icon size={35} name="question-mark-circle-outline" />
                  </Tooltip>
                }
              />
            </Form.Item>
            {type === 'product' && productType === 'API' && (
              <Form.Item
                name={[...name, 'marketingName']}
                rules={[
                  {
                    max: 100,
                    message: `${strings[type].inputMarketingName} should be at most 100 characters`,
                  },
                ]}
              >
                <Input
                  label={strings[type].inputMarketingName}
                  addonAfter={
                    <Tooltip title="This is the name that will be displayed as the card title, if not empty">
                      <Icon size={35} name="question-mark-circle-outline" />
                    </Tooltip>
                  }
                />
              </Form.Item>
            )}
            <MarkdownTip>
              <div className={styles.textArea_wrapper}>
                <Form.Item
                  className={styles.formItem}
                  name={[...name, 'description']}
                  rules={[
                    {
                      required: true,
                      message: 'Description is required',
                      whitespace: true,
                    },
                    {
                      max: 500,
                      message: 'Description should be at most 500 characters',
                    },
                  ]}
                >
                  <Textarea label="Description" />
                </Form.Item>
                <Tooltip title={strings[type].descriptionTooltip}>
                  <Icon color="black" name="question-mark-circle-outline" />
                </Tooltip>
              </div>
            </MarkdownTip>
            <div className={styles.textArea_wrapper}>
              <Form.Item
                className={styles.formItem}
                name={[...name, 'seoAltTag']}
                rules={[
                  {
                    max: 255,
                    message: 'Alt Tag for SEO should be at most 255 characters',
                  },
                ]}
              >
                <Textarea label="Alt Tag for SEO" />
              </Form.Item>
              <Tooltip title="Write image alternative text as plain text, HTML syntax is not required">
                <Icon color="black" name="question-mark-circle-outline" />
              </Tooltip>
            </div>
            {productType === PRODUCT_TYPE_NON_API && (
              <Form.Item
                shouldUpdate={(prevValues, nextValues) => {
                  const [path] = [...name];
                  return (
                    (!prevValues[path]?.url && !!nextValues[path]?.url) ||
                    (!prevValues[path]?.learnMoreUrl && !!nextValues[path]?.learnMoreUrl) ||
                    (!prevValues[path]?.quoteUrl && !!nextValues[path]?.quoteUrl)
                  );
                }}
              >
                {({ getFieldValue }) => {
                  return (
                    <>
                      {isNil(getFieldValue([...name, 'learnMoreUrl'])) && !isNil(getFieldValue([...name, 'url'])) && (
                        <Form.Item
                          name={[...name, 'url']}
                          rules={[
                            {
                              required: true,
                              message: 'Url is required for non-API products',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input
                            label="URL"
                            addonAfter={
                              <Tooltip title="Public Website user will be redirected to this page">
                                <Icon size={35} name="question-mark-circle-outline" />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                      )}
                      {((!isNil(getFieldValue([...name, 'learnMoreUrl'])) || isNewProduct) && !isLandingPageEnabled) ||
                      landingType === 'COMPACT' ? (
                        <Form.Item
                          name={[...name, 'learnMoreUrl']}
                          rules={[
                            {
                              required: true,
                              message: '"Learn more" Url is required for non-API products',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input
                            label='URL "Learn more"'
                            addonAfter={
                              <Tooltip title="Public Website user will be redirected to this page">
                                <Icon size={35} name="question-mark-circle-outline" />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                      ) : null}
                      <Form.Item name={[...name, 'quoteUrl']}>
                        <Input
                          label='URL "Get a quote"'
                          addonAfter={
                            <Tooltip title='Please note that the button "Get quote" will not be displayed if the URL is not specified'>
                              <Icon size={35} name="question-mark-circle-outline" />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
            )}
            {type === 'category' && !isLandingPageEnabled && (
              <Form.Item
                name={[...name, 'learnMoreUrl']}
                rules={[
                  {
                    required: true,
                    message: '"Learn more" Url is required',
                    whitespace: true,
                  },
                  {
                    max: 2049,
                    message: '"Learn more" Url should be at most 2049 characters',
                  },
                ]}
              >
                <Input
                  label='URL "Learn more"'
                  addonAfter={
                    <Tooltip title='The link will be attached to text "Learn more..." on the tile of the Category'>
                      <Icon size={35} name="question-mark-circle-outline" />
                    </Tooltip>
                  }
                />
              </Form.Item>
            )}
          </div>
        </div>
      </div>
    </CollapsiblePanel>
  );
};
