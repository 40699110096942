import { ReactNode, useMemo } from 'react';
import type { FC } from 'react';

import result from 'lodash/result';

import { Form } from '@saturn/uikit';

interface VisibilityChangeListenerProps {
  section: string | number | (string | number)[];
  children: (required: boolean) => ReactNode;
}

export const VisibilityChangeListener: FC<VisibilityChangeListenerProps> = ({ section, children }) => {
  const switchName = useMemo(() => [section, 'visible'].flat(), [section]);
  return (
    <Form.Item
      required={false}
      noStyle
      shouldUpdate={(prevValue, newValue) => result(prevValue, switchName) !== result(newValue, switchName)}
    >
      {({ getFieldValue }) => children?.(getFieldValue(switchName))}
    </Form.Item>
  );
};
