import { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { AboutBlock } from './components/AboutBlock';
import { AboutProps } from './model';

import styles from './AboutBlock.module.scss';

export const About: FC<AboutProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'About'} name={['about']} showSectionTitle>
      <ContentBlockContainer
        className={styles.content}
        name={['about', 'aboutBlocks']}
        title="Text block"
        addButtonText="Add About"
        showChangePositionButton={true}
      >
        {props => <AboutBlock {...props} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
