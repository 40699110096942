import type { FC } from 'react';

import { Progress } from '../../../Progress';
import { UploadContentProps } from './models';

export const UploadContent: FC<UploadContentProps> = ({ percent, content = 'Upload Image' }) => {
  return (
    <div>
      {content && <div>{content}</div>}
      {typeof percent === 'number' && <Progress style={{ marginTop: 5 }} percent={percent} showInfo={true} />}
    </div>
  );
};
