import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';

import { MarkdownTip } from 'shared/components';

import { IconsGallery } from '../IconsGallery';
import { BlockWithIconProps } from './models';

export const BlockWithIcon: FC<BlockWithIconProps> = ({
  labelTitle = 'Title',
  showTitle = true,
  isIconRequired = false,
  preserve = true,
  required,
  name: fieldName,
  ...fieldProps
}) => {
  return (
    <div>
      {showTitle && (
        <Form.Item
          {...fieldProps}
          preserve={preserve}
          name={[fieldName, 'title']}
          rules={[
            {
              required,
              message: `${labelTitle} is required`,
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    max: 255,
                    message: `${labelTitle} should be at most 255 characters`,
                  },
                ]
              : []),
          ]}
        >
          <Input label={labelTitle} />
        </Form.Item>
      )}
      <MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[fieldName, 'description']}
          preserve={preserve}
          rules={[
            {
              required: fieldProps.parentName[1] === 'coverage' ? false : required,
              message: 'Description is required',
              whitespace: true,
            },
            ...((fieldProps.parentName[1] === 'coverage' ? false : required)
              ? [
                  {
                    max: fieldProps.parentName[1] === 'coverage' ? 10000 : 1000,
                    message: `Description should be at most ${
                      fieldProps.parentName[1] === 'coverage' ? 10000 : 1000
                    } characters`,
                  },
                ]
              : []),
          ]}
        >
          <Textarea label="Description" />
        </Form.Item>
      </MarkdownTip>
      <Form.Item
        {...fieldProps}
        preserve={false}
        valuePropName="icon"
        name={[fieldName, 'thumbnailId']}
        rules={[
          {
            required: required && isIconRequired,
            message: 'Thumbnail is required',
          },
        ]}
      >
        <IconsGallery />
      </Form.Item>
    </div>
  );
};
