import { InfoDto } from './InfoDto';
import { SeoDto } from './SeoDto';
import { RequireLocation } from './common';

export interface ProductDto extends RequireLocation {
  providerId: string;
  categoryId: string;
  status: PRODUCT_STATUS;
  type: PRODUCT_TYPE;
  updatedAt: string;
  createdAt: string;
  customLocalizationMessages: boolean;
  marketingDetails: {
    hasPromocode: boolean;
    defaultPromocodeValue: string;
  };
  systemSettings: {
    aerolabSection: {
      enabled: boolean;
      hidden: boolean;
    } | null;
    maintenance: {
      sectionTitle?: string | null;
      description?: string | null;
      underMaintenance: boolean;
    };
    hidden: boolean;
  };
  landing: {
    landingType: LANDING_TYPE;
    language: string;
    info: InfoDto;
    seo: SeoDto;
  };
}

export const PRODUCT_TYPE_API = 'API';
export const PRODUCT_TYPE_NON_API = 'NON_API';
export type PRODUCT_TYPE = typeof PRODUCT_TYPE_API | typeof PRODUCT_TYPE_NON_API;
export type PRODUCT_STATUS = 'NEW' | 'DRAFT' | 'PUBLISHED' | 'MAINTENANCE';
export type LANDING_TYPE = 'COMPACT' | 'EXTENDED';
