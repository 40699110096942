import { CreateUserDto, UserDto } from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function getUserList(): Promise<Array<UserDto>> {
  const { data } = await api.get<Array<UserDto>>('/admin/iam/users');
  return data;
}

export async function getCurrentUser(): Promise<UserDto> {
  const { data } = await api.get<UserDto>('/admin/iam/users/me');
  return data;
}

export async function deleteUser(userId: string): Promise<void> {
  return api.delete(`/admin/iam/users/${userId}`);
}

export async function createUser(userData: CreateUserDto): Promise<void> {
  return api.post('/admin/iam/users', userData);
}

export async function enableUser(userId: string): Promise<void> {
  return api.put(`/admin/iam/users/${userId}/enable`);
}
export async function disableUser(userId: string): Promise<void> {
  return api.put(`/admin/iam/users/${userId}/disable`);
}
