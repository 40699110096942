import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../utils';

export type ProtectedRouteProps = {
  redirectTo: string;
  children: ReactNode;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectTo, children }) => {
  const { isAuthEnabled, isAuthorized } = useAuth();

  if (isAuthEnabled && !isAuthorized) {
    return <Navigate to={redirectTo} replace />;
  } else {
    return <>{children}</>;
  }
};
