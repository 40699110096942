import type { FC, ReactNode } from 'react';

import { ProductDto } from '@saturn/api';
import { Form, Icon, Input, Option, Select, Switch, Title, Tooltip } from '@saturn/uikit';

import { ContentBlockContainer, IconsGallery } from 'shared/components';

import { Tip } from '../Tip';

import styles from './InsuranceProductsBlock.module.scss';

interface InsuranceProductsBlockProps {
  name: string[];
  maxItems: number;
  products: ProductDto[];
  selectedInsuranceProducts: string[];
  title?: ReactNode;
  titleTip?: ReactNode;
  blockTip?: ReactNode;
  addButtonText?: string;
  showHightlightProduct?: boolean;
}

export const InsuranceProductsBlock: FC<InsuranceProductsBlockProps> = ({
  titleTip,
  blockTip,
  name,
  maxItems,
  title,
  addButtonText = 'Add product',
  products,
  selectedInsuranceProducts,
  showHightlightProduct = true,
}) => {
  const form = Form.useFormInstance();
  const formValues = form.getFieldsValue();
  const formProducts = formValues[name[0]]?.products;
  return (
    <div className={styles.content}>
      {titleTip && <Tip>{titleTip}</Tip>}
      {title && (
        <Title className={styles.title} level={4}>
          {title}
        </Title>
      )}
      {blockTip && <Tip>{blockTip}</Tip>}
      <ContentBlockContainer maxItems={maxItems} title="Product" addButtonText={addButtonText} name={name}>
        {({ name: blockName, required, index, ...rest }) => {
          return (
            <>
              <Form.Item
                name={[blockName, 'productId']}
                rules={[
                  {
                    required,
                    message: 'Product is required',
                  },
                ]}
                {...rest}
              >
                <Select placeholder="Product">
                  {products?.map(item => (
                    <Option
                      key={item.id}
                      data-key={item.id}
                      value={item.id}
                      disabled={selectedInsuranceProducts?.some(productId => productId === item.id)}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {showHightlightProduct && (
                <>
                  <div className={styles.flexItem}>
                    <p className={styles.label}>{`Highlight the product ${index + 1} card?`}</p>
                    <Form.Item name={[blockName, 'isHighlighted']} valuePropName="checked" {...rest}>
                      <Switch checkedChildren="yes" unCheckedChildren="off" />
                    </Form.Item>
                    <Tooltip title="This functionality includes a badge that can be applied to the card, allowing you to emphasize the product within the list.">
                      <Icon size={25} name="question-mark-circle-outline" />
                    </Tooltip>
                  </div>
                  <div style={{ display: formProducts?.[blockName]?.isHighlighted ? 'block' : 'none' }}>
                    <div className={styles.inputsWrapper}>
                      <Form.Item
                        name={[blockName, 'badgeColor']}
                        rules={[
                          {
                            required: required && formProducts?.[blockName]?.isHighlighted,
                            message: 'Color is required',
                          },
                        ]}
                        {...rest}
                      >
                        <Select placeholder="Badge Color">
                          {[
                            { title: 'Red', value: 'red' },
                            { title: 'Blue', value: 'blue' },
                            { title: 'Orange', value: 'orange' },
                          ].map(color => (
                            <Option key={color.value} data-key={color.value}>
                              {color.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[blockName, 'badgeTitle']}
                        rules={[
                          {
                            required: required && formProducts?.[blockName]?.isHighlighted,
                            message: 'Title is required',
                            whitespace: true,
                          },
                          {
                            max: 30,
                            message: 'Title should be at most 30 characters',
                          },
                          {
                            pattern: /^[A-Za-z\d\s-]*$/,
                            message: 'There are restricted symbols in the value',
                          },
                        ]}
                        {...rest}
                      >
                        <Input label="Badge Title" />
                      </Form.Item>
                    </div>
                    <Form.Item preserve={false} valuePropName="icon" name={[blockName, 'thumbnailId']} {...rest}>
                      <IconsGallery />
                    </Form.Item>
                  </div>
                </>
              )}
            </>
          );
        }}
      </ContentBlockContainer>
    </div>
  );
};
