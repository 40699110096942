const EVENT_SECTION_TITLES: Record<string, string> = {
  seo: 'SEO',
  hero: 'Hero Banner',
  howToSection: 'How to',
  productsSection: 'Products',
  about: 'About',
  stripe: 'Stripe',
  disclosuresFooter: 'Footer Disclosures',
};

export default EVENT_SECTION_TITLES;
