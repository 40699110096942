import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { ProductDetailsDto, PromoEventBannerDto } from '../models';
import { PromocodeBasic, PromocodeDto } from '../models/dto/PromocodeDto';

dayjs.extend(utc);

const locationTimezones: { [key: string]: string } = {
  sg: '+08:00',
  fr: '+01:00',
  hk: '+08:00',
  it: '+01:00',
  jp: '+09:00',
  in: '+05:30',
  de: '+01:00',
  es: '+01:00',
  th: '+07:00',
  ar: '-03:00',
  test: '+00:00',
};

export const getLocationTimezone = (locationId: string) => {
  if (!locationTimezones[locationId]) {
    console.error('Error: There are no such location in locationTimezones mapping. Fallback to UTC timezone');
    return '+00:00';
  }
  return locationTimezones[locationId];
};

// This method applies UTC timezone for BE dates validation,
// merges date and time fields into a single DTO field (e.g. bannerStartDate => bannerStartDate + bannerStartTime)
// and formates dates for API model YYYY-MM-DDTHH:mm:ssZ”
export const formatTimeFieldsForAPI = (landingData: ProductDetailsDto): ProductDetailsDto => {
  const { bannerStartTime, bannerExpiryTime, bannerExpiryDate, bannerStartDate, ...restPromoEventBanner } =
    landingData?.promoEventBanner ?? {
      bannerStartDate: null,
      bannerStartTime: null,
      bannerExpiryTime: null,
      bannerExpiryDate: null,
    };

  const mergedStartDate = `${bannerStartDate}T${dayjs(bannerStartTime).format().split('T')[1]}`;
  const mergedExpiryDate = `${bannerExpiryDate}T${dayjs(bannerExpiryTime).format().split('T')[1]}`;

  return {
    ...landingData,
    ...(landingData.promoEventBanner && {
      promoEventBanner: {
        ...restPromoEventBanner,
        bannerStartDate: bannerStartDate && bannerStartTime ? dayjs(mergedStartDate).utc().format() : null,
        bannerExpiryDate: bannerExpiryDate && bannerExpiryTime ? dayjs(mergedExpiryDate).utc().format() : null,
      } as PromoEventBannerDto,
    }),
  };
};

export const getTimezoneOffset = (locale?: string) => {
  if (locale === 'en-in') {
    return locationTimezones.in; // asia/kolkata
  }
  // Empty offset equals local browser timezone
  return '';
};

export const formatPayloadForPromocodeApi = ({
  promocodeData,
  id,
  locationId,
  language,
  isActive,
}: {
  isActive: boolean;
  promocodeData: PromocodeBasic;
  id?: string;
  locationId: string;
  language: string;
}): PromocodeDto => {
  const { isStartNow, isNoExpired, promocodeValue, productId, startDate, startTime, expiryDate, expiryTime } =
    promocodeData;
  const mergedStartDate = `${startDate}T${startTime ? dayjs(startTime).format().split('T')[1] : ''}`;
  const mergedExpiryDate = `${expiryDate}T${expiryTime ? dayjs(expiryTime).format().split('T')[1] : ''}`;

  return {
    isActive,
    startDate: startDate && startTime ? dayjs(mergedStartDate).utc().format() : null,
    expiredDate: expiryDate && expiryTime ? dayjs(mergedExpiryDate).utc().format() : null,
    id,
    promocodeValue,
    productId,
    locationId,
    language,
    isStartNow,
    isNoExpired,
  };
};
