import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

import { FormErrorField } from '@saturn/uikit';

import {
  COVERAGE_SECTION_DESCRIPTION_MAX_CHAR,
  COVERAGE_SECTION_DESCRIPTION_MAX_LENGH_ERROR,
  COVERAGE_SECTION_TABSUBTITLE_MAX_CHAR,
  COVERAGE_SECTION_TABSUBTITLE_MAX_LENGH_ERROR,
  COVERAGE_SECTION_TITLE_MAX_CHAR,
  COVERAGE_SECTION_TITLE_MAX_LENGH_ERROR,
} from './constants';

export interface EditCoveragesState {
  sectionTitle: string;
  sectionTitleTouched: boolean;
  tabSubtitle?: string;
  thumbnailId: string | null;
  subSections: Array<{
    key: string;
    subtitle: string;
    coverage: Array<{
      key: string;
      title: string;
      titleTouched: boolean;
      description: string;
      thumbnailId: string;
    }>;
  }>;
}

const initialState: EditCoveragesState = {
  sectionTitle: '',
  thumbnailId: null,
  sectionTitleTouched: false,
  subSections: [],
  tabSubtitle: '',
};

interface EditCoveragesStore {
  coveragesState: EditCoveragesState | undefined;
  coveragesError: EditCoveragesState | undefined;
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  validateCoverageFormValues: () => void;
  setCoveragesState: (formState: EditCoveragesState) => void;
  revalidateErrors: () => void;
}

const useEditCoveragesStore = create<EditCoveragesStore>((set, get) => ({
  coveragesState: undefined,
  coveragesError: undefined,
  coveragesFormTouched: false,
  isVisible: false,

  setIsVisible: (visibility: boolean) => {
    set({ isVisible: visibility });
    get().revalidateErrors();
  },
  // This method validates coverages form and throws error with antd-compatible FormSubmitFailed for generic error notifications approach
  // eslint-disable-next-line sonarjs/cognitive-complexity
  validateCoverageFormValues: () => {
    const formError = get().coveragesError;
    const formState = get().coveragesState;
    if (formError) {
      let subSectionsErrors: FormErrorField[] = [];

      formError.subSections.forEach((subSection, subSectionIndex) => {
        const sectionAccumulator = [{ ...(subSection.subtitle && { errors: [subSection.subtitle] }) }];
        subSection.coverage.forEach((coverage, coverageIndex) => {
          if (coverage.title) {
            sectionAccumulator.push({ errors: [coverage.title] });
          }
          if (isEmpty(formState?.subSections[subSectionIndex]?.coverage[coverageIndex]?.title)) {
            sectionAccumulator.push({ errors: ['Title is required'] });
            const updatedFormError = get().coveragesError;
            if (updatedFormError) {
              set({
                coveragesError: {
                  ...updatedFormError,
                  subSections: updatedFormError.subSections.map((formErrorSubSection, formErrorSubSectionSIndex) => {
                    return {
                      ...formErrorSubSection,
                      coverage: formErrorSubSection.coverage.map((formErrorCoverage, formErrorCoverageIndex) => {
                        if (formErrorSubSectionSIndex === subSectionIndex && formErrorCoverageIndex === coverageIndex) {
                          return { ...formErrorCoverage, title: 'Title is required' };
                        }
                        return { ...formErrorCoverage };
                      }),
                    };
                  }),
                },
              });
            }
          }
          if (coverage.description) {
            sectionAccumulator.push({ errors: [coverage.description] });
          }
          if (coverage.thumbnailId) {
            sectionAccumulator.push({ errors: [coverage.thumbnailId] });
          }
        });
        subSectionsErrors = [...subSectionsErrors, ...sectionAccumulator].filter(Boolean) as FormErrorField[];
      });
      const errorFields = [
        { ...(formError.sectionTitle && { errors: [formError.sectionTitle] }) },
        { ...(formError.tabSubtitle && { errors: [formError.tabSubtitle] }) },
        { ...(formError.thumbnailId && { errors: [formError.thumbnailId] }) },
        ...subSectionsErrors,
      ].filter(value => !isEmpty(value));

      if (errorFields.length) {
        // Errors exist => throw error
        throw {
          errorFields,
        };
      }
    }
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity
  setCoveragesState: newFormState => {
    const newFormError = cloneDeep(newFormState);
    // Form validation
    newFormError.sectionTitle = '';
    if (isEmpty(newFormState?.sectionTitle) && newFormState?.sectionTitleTouched) {
      newFormError.sectionTitle = 'Section Title is required';
    }
    if (newFormState?.sectionTitle?.length > COVERAGE_SECTION_TITLE_MAX_CHAR) {
      newFormError.sectionTitle = COVERAGE_SECTION_TITLE_MAX_LENGH_ERROR;
    }
    if (!isEmpty(newFormState.sectionTitle) && newFormState.sectionTitle?.length <= COVERAGE_SECTION_TITLE_MAX_CHAR) {
      newFormError.sectionTitle = '';
    }

    newFormError.tabSubtitle =
      newFormState.tabSubtitle && newFormState.tabSubtitle?.length > COVERAGE_SECTION_TABSUBTITLE_MAX_CHAR
        ? COVERAGE_SECTION_TABSUBTITLE_MAX_LENGH_ERROR
        : '';

    newFormError.thumbnailId = newFormState.thumbnailId ? '' : 'Thumbnail is required';

    newFormState.subSections.forEach((subSection, subSectionIndex) => {
      // Loop through newFormState to verify each nested item and change related newFormError item
      newFormError.subSections[subSectionIndex].subtitle =
        subSection.subtitle?.length > 255 ? 'Section Subtitle should be at most 255 characters' : '';

      subSection.coverage.forEach((coverage, coverageIndex) => {
        const itemToModify = newFormError.subSections[subSectionIndex].coverage[coverageIndex];
        itemToModify.title = '';
        if (isEmpty(coverage.title) && coverage.titleTouched) {
          itemToModify.title = 'Title is required';
        }
        if (coverage.title?.length > 255) {
          itemToModify.title = 'Title should be at most 255 characters';
        }
        if (!isEmpty(coverage.title) && coverage.title.length <= 255) {
          itemToModify.title = '';
        }
        // No validation for thumbnailId
        itemToModify.thumbnailId = '';
        itemToModify.description =
          coverage.description?.length > COVERAGE_SECTION_DESCRIPTION_MAX_CHAR
            ? COVERAGE_SECTION_DESCRIPTION_MAX_LENGH_ERROR
            : '';
      });
    });

    set({
      coveragesState: newFormState,
      coveragesError: get().isVisible ? newFormError : undefined,
    });
  },

  revalidateErrors: () => {
    const state = get().coveragesState;
    get().setCoveragesState({ ...initialState, ...(state ? state : {}) });
  },
}));

if (typeof window !== 'undefined') {
  mountStoreDevtool('EditCoveragesStore', useEditCoveragesStore);
}

export default useEditCoveragesStore;
