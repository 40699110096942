import { useState } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductTranslationDto } from '@saturn/api';
import { Button, Form, Icon, Modal, Textarea } from '@saturn/uikit';

import { Alert } from 'shared/components/Alert';

import { useAdminLocation } from '../../utils';
import { TranslationModalProps, TranslationObject } from './models';

import styles from './TranslationModal.module.scss';

export const TranslationModal: FC<TranslationModalProps> = ({ record, onTranslate, onTranslateQuoteForm }) => {
  const { locationLanguage = 'en', adminLocation } = useAdminLocation();
  const locale = `${locationLanguage}-${adminLocation.id.toUpperCase()}`;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<TranslationObject | ProductTranslationDto>();
  const [messageValue, setMessageValue] = useState<string>();

  const showModal = (record: TranslationObject | ProductTranslationDto) => {
    setModalData(record);
    setMessageValue(record.message);
    setIsModalOpen(true);
  };

  const handleUpdate = () => {
    handleSubmit();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleFinish = (values: Record<string, string>) => {
    onTranslate && onTranslate(record.key, values.message ?? record.message, locale, adminLocation.id);
    onTranslateQuoteForm &&
      onTranslateQuoteForm(
        record.key,
        locale,
        (record as ProductTranslationDto).productId,
        values.message ?? record.message,
      );
  };

  return (
    <>
      <Button data-key="action-edit" type="link" onClick={() => showModal(record)} isStaticBtn>
        <Icon name="edit-outline" />
      </Button>
      <Modal
        title={modalData?.key}
        open={isModalOpen}
        onCancel={handleCancel}
        width={740}
        className={styles.wrapper}
        footer={
          <>
            <Button onClick={handleCancel}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" description="Cancel btn text" />
            </Button>
            <Button type="primary" onClick={handleUpdate}>
              <FormattedMessage
                id="modal.translation-table.update"
                defaultMessage="Update"
                description="Translation table modal => Update button"
              />
            </Button>
          </>
        }
      >
        <Alert
          message="Please don't change the text in curly brackets (for example, {name}). Otherwise, it will negatively affect the performance of the system."
          type="info"
        />
        <Form form={form} onFinish={handleFinish} preserve={false}>
          <Form.Item name="message">
            <label htmlFor="message">
              Localized Text
              <Textarea id="message" label="" value={messageValue} onChange={e => setMessageValue(e.target.value)} />
            </label>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
