import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';

import { admin, getLocationTimezone } from '@saturn/api';
import { PromocodeBasic, PromocodeDetails } from '@saturn/api/src/models/dto/PromocodeDto';
import { formatPayloadForPromocodeApi } from '@saturn/api/src/utils/timezone.utils';
import { Button, Card, Form, FormSubmitFailed, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { onFormFinishFailed, useAdminLocation } from 'shared/utils';

import { PromocodeInfo } from '../features/promocodes/PromocodeInfo/PromocodeInfo';
import { PromotionBenefits } from '../features/promocodes/PromotionBenefits/PromotionBenefits';

import styles from './styles.module.scss';

const PromocodeCreatePage: FC = () => {
  const { promocodeId = '' } = useParams();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromocodeBasic>();
  const [formDetails] = Form.useForm<PromocodeDetails>();

  const onError = () => {
    notification.error({
      message: 'Error during promocode creation has happened',
    });
  };

  const { data: promocodeInfo, loading: promocodeLoading } = useRequest(() => admin.getPromocode(promocodeId), {
    refreshDeps: [promocodeId],
    onSuccess: data => {
      if (data) {
        // Map DTO to form fields and apply market timezone
        const locationTimezone = getLocationTimezone(adminLocation.id);

        const zonedExpiryDate = data.expiredDate ? dayjs(data.expiredDate).utcOffset(locationTimezone) : null;
        const zonedStartDate = data.startDate ? dayjs(data.startDate).utcOffset(locationTimezone) : null;

        form.setFieldsValue({
          isStartNow: data.isStartNow,
          isNoExpired: data.isNoExpired,
          promocodeValue: data.promocodeValue,
          productId: data.productId,
          startDate: zonedStartDate?.format('YYYY-MM-DD') ?? null,
          startTime: zonedStartDate ?? null,
          expiryDate: zonedExpiryDate?.format('YYYY-MM-DD') ?? null,
          expiryTime: zonedExpiryDate ?? null,
        });
      }
    },
    onError,
  });

  const { data: promocodeBenefits, loading: promocodeBenefitsLoading } = useRequest(
    () => admin.getPromocodeBenefits(promocodeId, locationLanguage),
    {
      refreshDeps: [promocodeId, locationLanguage],
      onSuccess: data => {
        if (data) {
          formDetails.setFieldsValue(data);
        }
      },
      onError,
    },
  );

  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const apiProducts = locationProducts.filter(p => p.type === 'API');

  const { runAsync: savePromocode } = useRequest(admin.savePromocode, { manual: true });
  const { runAsync: savePromocodeBenefits } = useRequest(admin.savePromocodeBenefits, { manual: true });

  const formFinished = async () => {
    try {
      if (!promocodeInfo) return;
      const promocodeData = await form.validateFields();
      const promocodeDetails = await formDetails.validateFields();

      const promocodesWithFormattedTimeFields = formatPayloadForPromocodeApi({
        isActive: promocodeInfo.isActive,
        promocodeData,
        language: locationLanguage as string,
        id: promocodeId,
        locationId,
      });
      const mapedBenefits: PromocodeDetails = {
        benefits: promocodeDetails.benefits,
      };

      await savePromocode(promocodesWithFormattedTimeFields);
      await savePromocodeBenefits(promocodeId, locationLanguage as string, mapedBenefits);

      notification.success({
        message: 'Promocode has been successfully updated',
      });
    } catch (error) {
      onFormFinishFailed(error as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Create Promocodes" subTitle={`Edit Promocode: ${promocodeInfo?.promocodeValue}`} />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Spin spinning={promocodeLoading || promocodeBenefitsLoading}>
              <Form name="promocode" form={form} onFinishFailed={onFormFinishFailed} preserve={false}>
                <PromocodeInfo products={apiProducts} loadingProducts={loadingProducts} />
              </Form>
              {promocodeBenefits && (
                <Form
                  name="promocodeDetails"
                  form={formDetails}
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  initialValues={promocodeBenefits}
                >
                  <PromotionBenefits />
                </Form>
              )}
            </Spin>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Save
                </Button>
              </Form.Item>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default PromocodeCreatePage;
