import { Rule } from '@saturn/uikit';

const subTitleMax = 120;
const titleMax = 60;
const phoneNumberMax = 19;

export const getTimeoutRules = (required: boolean): Rule[] =>
  required
    ? [
        {
          pattern: /^\d{1,2}$/,
          message: 'Value should be numeric and have max 2 symbols',
        },
        { required: required, message: 'Field is required' },
        {
          pattern: /^(?:[1-9]|[1-9]\d)$/,
          message: 'Out of range, timeout should be 1-99',
        },
      ]
    : [];

export const getSubtitleRules = (required: boolean) =>
  required
    ? [
        { required: required, message: 'Field is required' },
        {
          max: subTitleMax,
          message: `Sub-title should be at most ${subTitleMax} characters`,
        },
      ]
    : [];

export const getPhoneNumberRules = (required: boolean) =>
  required
    ? [
        { required: required, message: 'Field is required' },
        {
          max: phoneNumberMax,
          message: `Phone number should be at most ${phoneNumberMax} characters`,
        },
        {
          pattern: /^\+?[\d\s\-()]+$/,
          message: 'Phone number should be numeric or has special char:  - space ( ) +',
        },
      ]
    : [];

export const getTitleRules = (required: boolean) =>
  required
    ? [
        { required: required, message: 'Field is required' },
        {
          max: titleMax,
          message: `Title should be at most ${titleMax} characters`,
        },
      ]
    : [];

export const getBannerRules = (required: boolean) =>
  required
    ? [
        {
          required: true,
          message: 'Mobile number pop-up logo is required',
        },
      ]
    : [];
