import type { FC } from 'react';

import { Tabs as AntTabs } from 'antd';
import type { TabsProps } from 'antd';
import cn from 'classnames';

import styles from './Tabs.module.scss';

export const Tabs: FC<TabsProps> = (props: TabsProps) => {
  const { centered = true, defaultActiveKey = '1', className, children, ...rest } = props;

  return (
    <AntTabs
      className={cn(className, 'saturn-tabs', styles.wrapper)}
      defaultActiveKey={defaultActiveKey}
      centered={centered}
      {...rest}
    >
      {children}
    </AntTabs>
  );
};
