import type { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { HowItWorksBlock } from './components/HowItWorks';

import styles from './HowItWorksBlock.module.scss';

type HowItWorksProps = {
  header?: string;
};

export const HowItWorks: FC<HowItWorksProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'How It Works'} name={['howItWorksSection']} showSectionTitle>
      <ContentBlockContainer
        className={styles.content}
        name={['howItWorksSection', 'cards']}
        title="Scenario"
        addButtonText="Add Scenario"
        showChangePositionButton={true}
        maxItems={10}
      >
        {props => <HowItWorksBlock {...props} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
