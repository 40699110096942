import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, IconsGallery } from 'shared/components';

import { FAQBlock } from './components/FAQBlock';
import { FaqProps } from './model';

import styles from './FAQ.module.scss';

export const FAQ: FC<FaqProps> = ({ header }) => {
  const sectionName = ['allYouNeedToKnow', 'subSections', 'faq'];
  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel sectionTitleLabel="Tab Title" header={header || 'FAQs'} name={sectionName} showSectionTitle>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...sectionName, 'visible']);
            return (
              <>
                <Form.Item
                  preserve={false}
                  valuePropName="icon"
                  name={[...sectionName, 'thumbnailId']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            required: true,
                            message: 'Thumbnail is required',
                          },
                        ]
                      : []),
                  ]}
                >
                  <IconsGallery />
                </Form.Item>
                <Form.Item
                  name={[...sectionName, 'tabSubtitle']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            max: 100,
                            message: 'Tab Subtitle should be at most 100 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Input label="Tab Subtitle" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
        <ContentBlockContainer
          className={styles.content}
          maxItems={50}
          name={[...sectionName, 'faq']}
          addButtonText="Add question"
          title="Question"
          showChangePositionButton={true}
        >
          {props => <FAQBlock {...props} />}
        </ContentBlockContainer>
        <Form.Item name={[...sectionName, 'disclosure']}>
          <Textarea label="FAQ Disclosure" />
        </Form.Item>
      </CollapsiblePanel>
    </div>
  );
};
