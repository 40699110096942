import { useState } from 'react';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { Input, Spin, Table, notification } from '@saturn/uikit';

import { Alert } from 'shared/components/Alert';

import { TranslationModal } from '../../../../shared/components';
import { useAdminLocation } from '../../../../shared/utils';

export function TranslationTable(): JSX.Element {
  const { locationLanguage = 'en', adminLocation } = useAdminLocation();
  const locale = `${locationLanguage}-${adminLocation.id.toUpperCase()}`;
  const [search, setSearch] = useState('');

  const { data: updatedData, run: updateTableData } = useRequest(admin.updateLocalizedMessage, {
    manual: true,
    onSuccess: () => {
      notification.success({
        message: 'Translation has been successfully updated',
      });
    },
  });

  const { data, loading } = useRequest(
    () =>
      admin.getI18n(adminLocation.id, locale).then(data =>
        data.map(({ key, defaultMessage, message }) => ({
          key,
          defaultMessage,
          message,
          search: `${key} | ${defaultMessage} | ${message}`.toLowerCase(),
        })),
      ),
    { refreshDeps: [updatedData?.message] },
  );

  return (
    <Spin spinning={loading}>
      <Alert
        message="Please don't change the text in curly brackets (for example, {name}). Otherwise, it will negatively affect the performance of the system."
        type="info"
      />
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Input
          type="text"
          label="Search"
          id="search"
          value={search}
          // @ts-expect-error broken typings for onChange
          onChange={e => setSearch(e?.currentTarget?.value ?? '')}
        />
      </div>

      <Table
        rowKey="key"
        tableLayout="fixed"
        columns={[
          {
            title: 'Translation Key',
            width: 320,
            dataIndex: 'key',
          },
          {
            title: 'Translation Text',
            dataIndex: 'defaultMessage',
          },
          {
            title: 'Localized Text',
            dataIndex: 'message',
          },
          {
            title: 'Edit',
            fixed: 'right',
            width: 100,
            render: record => <TranslationModal record={record} onTranslate={updateTableData} />,
          },
        ]}
        dataSource={(data ?? []).filter(item => search.length == 0 || item.search.includes(search.toLowerCase()))}
        pagination={false}
      />
    </Spin>
  );
}
