import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-scroll';

import cn from 'classnames';
import type { XYCoord } from 'dnd-core';

import { Icon } from '@saturn/uikit';

import { useAdminLocation } from '../../utils';
import { DefaultIndexValue, IndicesType } from './types';

import styles from './SidebarList.module.scss';

interface CardProps extends ComponentPropsWithoutRef<'div'> {
  id: string;
  children: ReactNode;
  index: number;
  setIndices?: (newIndicies: IndicesType) => void;
  disabled?: boolean;
  sidebarListValues: Record<string, boolean | null>;
  parent: string;
  indices: IndicesType;
}

interface DragItem {
  index: number;
  id: string;
}

export const getIndices = (
  state: Array<[string, DefaultIndexValue]>,
  sidebarListValues: Record<string, boolean | null>,
): Array<[string, DefaultIndexValue]> => {
  const res: Array<[string, DefaultIndexValue]> = [];
  if ('planLegalDisclaimer' in sidebarListValues) {
    res.push(['planLegalDisclaimer', { order: 0, subSections: null }]);
  }
  if ('highlightPlan' in sidebarListValues) {
    res.push(['highlightPlan', { order: 0, subSections: null }]);
  }
  if ('promotionBenefit' in sidebarListValues) {
    res.push(['promotionBenefit', { order: 0, subSections: null }]);
  }
  res.push(
    ...state.filter(x => {
      return !['planLegalDisclaimer', 'referFriend', 'highlightPlan', 'promotionBenefit', 'nextSteps'].includes(x[0]);
    }),
  );
  if ('referFriend' in sidebarListValues) {
    res.push(['referFriend', { order: 0, subSections: null }]);
  }
  if ('nextSteps' in sidebarListValues) {
    res.push(['nextSteps', { order: 0, subSections: null }]);
  }
  return res;
};

function Card({
  id,
  disabled = false,
  children,
  index,
  setIndices,
  sidebarListValues,
  indices,
  parent,
  ...props
}: CardProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void>({
    accept: `NESTED_CARD_${parent}`,
    hover: (item: DragItem, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = (monitor.getClientOffset() as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      if (setIndices) {
        const hoverId = indices[hoverIndex];
        const state1 = indices.filter(x => x !== hoverId);
        const state2 = [...state1.slice(0, dragIndex), hoverId, ...state1.slice(dragIndex)];
        item.index = hoverIndex;
        const newIndicies = getIndices(state2, sidebarListValues);
        setIndices(newIndicies);
      }
    },
  });

  const [, drag] = useDrag({
    type: `NESTED_CARD_${parent}`,
    canDrag: () => !disabled,
    item: () => ({ id, index }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} {...props} className={cn(props.className, styles.card, { [styles.draggableCard]: !disabled })}>
      {children}
    </div>
  );
}

type SidebarListProps = {
  parent: string;
  sidebarListValues: Record<string, boolean | null>;
  sectionTitles: Record<string, string>;
  indices?: IndicesType;
  setIndices?: (newIndicies: IndicesType) => void;
};

export function NestedSidebarList({ parent, sidebarListValues, sectionTitles, indices, setIndices }: SidebarListProps) {
  const { locationLanguage } = useAdminLocation();

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={cn(styles.wrapper, styles.nestedWrapper)}>
        {indices &&
          indices.map(([key], i) => {
            return (
              <Card
                id={key}
                disabled={
                  !setIndices ||
                  ['planLegalDisclaimer', 'highlightPlan', 'promotionBenefit', 'referFriend', 'nextSteps'].includes(key)
                }
                index={i}
                key={key}
                setIndices={setIndices}
                style={{
                  order: indices.findIndex(el => {
                    return el[0] === key;
                  }),
                }}
                sidebarListValues={sidebarListValues}
                parent={parent}
                indices={indices}
              >
                <div
                  className={cn('saturn-sidebar-product', styles.sidebarListItem, {
                    [styles.notVisible]: sidebarListValues[key] === false,
                  })}
                >
                  <Link
                    activeClass="active"
                    className="saturn-scroll-link"
                    to={`${key}_${locationLanguage}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    {sectionTitles[key]}
                  </Link>
                  {sidebarListValues[key] !== null && (
                    <Icon
                      name={sidebarListValues[key] ? 'eye-outline' : 'eye-off-outline'}
                      size={18}
                      color={'#aab2ba'}
                    />
                  )}
                </div>
              </Card>
            );
          })}
      </div>
    </DndProvider>
  );
}
