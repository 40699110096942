import type { FileMetadataDto } from '@saturn/api';
import { getFileUrl } from '@saturn/api';
import type { DefaultFileForUploader } from '@saturn/uikit';

export function fileUrlFormatter(value?: FileMetadataDto): string | undefined {
  const { id } = value || {};
  return id && getFileUrl(id);
}

export function fileListFormatter(fileMetadata?: FileMetadataDto): DefaultFileForUploader[] | undefined {
  const { id: fileId } = fileMetadata || {};
  if (fileMetadata && fileId) {
    return [
      {
        uid: fileId,
        name: fileMetadata.title ?? 'Unknown File',
        status: 'done',
        url: getFileUrl(fileId),
      },
    ];
  } else {
    return undefined;
  }
}
