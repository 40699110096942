import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN, admin } from '@saturn/api';
import { notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { useAuth } from 'shared/utils';

import { UsersList } from 'features/users/components/UsersList';

import styles from './styles.module.scss';

const UsersPage: FC = () => {
  const { user, isAuthEnabled } = useAuth();

  const { loading: fetching, data: usersList = [], refresh } = useRequest(admin.getUserList);
  const { run: deleteUser, loading: deleting } = useRequest(admin.deleteUser, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'User successfully deleted',
        duration: 5,
      });
    },
    onError: () => {
      notification.error({
        message: 'Error while deleting user',
        duration: 5,
      });
    },
  });

  return (
    <main>
      <PageHeader title="Manage Admin Users" />
      {(!isAuthEnabled ||
        (isAuthEnabled && (user?.roles.includes(ROLE_SUPER_ADMIN) || user?.roles.includes(ROLE_ADMIN)))) && (
        <div className={styles.buttonContainer}>
          <Link type="primary" className="ant-btn ant-btn-primary" to="create">
            <span>Create New User</span>
          </Link>
        </div>
      )}
      <UsersList loading={fetching || deleting} users={usersList} onDeleteUser={({ id }) => deleteUser(id)} />
    </main>
  );
};

export default UsersPage;
