import { createContext } from 'react';

import {
  ImportCategoryLandingPageResponse,
  ImportEventPageResponse,
  ImportLocationLandingPageResponse,
  ImportProductLandingPageResponse,
} from '@saturn/api/src/models/dto/ExportImportDto';

const ImportPageContext = createContext<
  | ImportEventPageResponse
  | ImportLocationLandingPageResponse
  | ImportProductLandingPageResponse
  | ImportCategoryLandingPageResponse
  | undefined
>(undefined);

export default ImportPageContext;
