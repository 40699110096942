import type { FC } from 'react';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN, admin } from '@saturn/api';
import { FormProps, downloadDocument } from '@saturn/uikit';

import { AnalyticsForm, PageHeader } from 'shared/components';
import { useAuth } from 'shared/utils';

const AnalyticsPage: FC = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes(ROLE_SUPER_ADMIN) || user?.roles.includes(ROLE_ADMIN);

  const onSubmit: FormProps['onFinish'] = async values =>
    admin
      .sendAnalyticsReport({ from: values.from.toISOString(), to: values.to.toISOString() })
      .then(({ data, name }) => downloadDocument(data, name))
      .catch(console.error);

  if (!isAdmin) {
    return null;
  }

  return (
    <main>
      <PageHeader title="Analytics Report" />
      <AnalyticsForm onSubmit={onSubmit} />
    </main>
  );
};

export default AnalyticsPage;
