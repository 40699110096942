import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { Icon, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import { ProductsList } from 'features/products-list/components/ProductsList';

import styles from './styles.module.scss';

const ProductsPage: FC = () => {
  const { adminLocation } = useAdminLocation();

  const {
    data: locationProducts = [],
    refresh,
    loading,
  } = useRequest(() => admin.getProductList(adminLocation.id), {
    refreshDeps: [adminLocation.id],
  });
  const { run: deleteProduct } = useRequest(admin.deleteProduct, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Product has been successfully deleted',
      });
    },
    onError: () => {
      notification.error({
        message: 'Something went wrong',
      });
    },
  });

  return (
    <main>
      <PageHeader title="Products List" />
      <div className={styles.buttonContainer}>
        <Link type="primary" className="ant-btn ant-btn-primary" to="create">
          <span>Create NON-API product</span>
        </Link>
      </div>
      <Spin spinning={loading} indicator={<Icon name="sync-outline" />}>
        <ProductsList products={locationProducts} onDeleteProduct={deleteProduct} />
      </Spin>
    </main>
  );
};

export default ProductsPage;
