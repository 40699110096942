import { FC, ReactNode } from 'react';

import { ContentBlockContainer } from 'shared/components';
import { ProviderOption } from 'shared/utils';

import { CollapsiblePanel } from '../CollapsiblePanel/CollapsiblePanel';
import { HeroBannerBlock } from './components/HeroBannerBlock';

import styles from './HeroBanner.module.scss';

type HeroBannerProps = {
  otherCategoriesButtonToggle?: ReactNode;
  header?: string;
  providers: ProviderOption[];
};

export const HeroBanner: FC<HeroBannerProps> = ({ header, otherCategoriesButtonToggle, providers }) => {
  return (
    <CollapsiblePanel header={header || 'Hero Banner'} name={['hero']}>
      {otherCategoriesButtonToggle}
      <ContentBlockContainer
        className={styles.content}
        maxItems={10}
        name={['hero', 'heroBanners']}
        addButtonText="Add banner"
        title="Banner"
        showChangePositionButton={true}
      >
        {props => <HeroBannerBlock {...props} providers={providers} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
