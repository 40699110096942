export default function downloadJson(base64Data: string, fileName: string) {
  try {
    // Create a Blob from the Base64 string without modifications
    const blob = new Blob([base64Data], { type: 'application/json' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName.endsWith('.json') ? fileName : `${fileName}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Failed to decode Base64 string:', error);
  }
}
