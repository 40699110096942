import type { FC } from 'react';

import { Form, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, MarkdownTip } from 'shared/components';

import { FOOTER_DISCLOSURES_MAX_LENGTH_RULE } from '../../constants';
import { SecondaryFooterDisclosuresProps } from './model';

export const SecondaryFooterDisclosures: FC<SecondaryFooterDisclosuresProps> = ({ header }) => {
  return (
    <CollapsiblePanel
      header={header || 'Non-amex footer Disclosures'}
      name={['secondaryFooterDisclosure']}
      showSectionTitle
      isRequiredSectionTitle={false}
    >
      <MarkdownTip>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue(['disclosuresFooter', 'visible']);
            return (
              <Form.Item
                name={['secondaryFooterDisclosure', 'disclosureContent']}
                rules={[...(isFieldRequired ? [FOOTER_DISCLOSURES_MAX_LENGTH_RULE] : [])]}
              >
                <Textarea label="Disclosures" />
              </Form.Item>
            );
          }}
        </Form.Item>
      </MarkdownTip>
    </CollapsiblePanel>
  );
};
