import type { FC } from 'react';

import { Title } from '@saturn/uikit';

import { BlockWithIcon, CollapsiblePanel, ContentBlockContainer, ProductsPerRowToggle } from 'shared/components';

import { FeaturesProps } from './model';

import styles from './Features.module.scss';

export const Features: FC<FeaturesProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Features'} name={['feature']} showSectionTitle>
      <Title className={styles.title} level={4}>
        <ProductsPerRowToggle
          switchName={['feature', 'featuresPerRow']}
          text="Features per row"
          checkedValue={2}
          unCheckedValue={1}
          isChecked={false}
        />
      </Title>

      <ContentBlockContainer
        name={['feature', 'features']}
        className={styles.content}
        title="Feature"
        addButtonText="Add feature"
        showChangePositionButton={true}
      >
        {props => <BlockWithIcon {...props} labelTitle="Feature Title" isIconRequired />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
