export const ROLE_ADMIN = 'admin';
export const ROLE_SUPER_ADMIN = 'super-admin';
export const ROLE_CM = 'content-manager';
export type SUPPORTED_ROLE = typeof ROLE_CM | typeof ROLE_ADMIN | typeof ROLE_SUPER_ADMIN;

export interface UserDto extends CreateUserDto {
  id: string;
  active: boolean;
  attachedPolicies: string[];
  refreshTokenId: string;
  createdAt: string;
}

export interface CreateUserDto {
  email: string;
  firstName: string;
  lastName: string;
  locationIds: string[];
  roles: SUPPORTED_ROLE[];
}
