import type {
  CategoryCreateDto,
  CategoryDataDto,
  CategoryDetailsDto,
  DisableCategoryRequestDto,
  LocationCategoryDto,
} from '../../models';
import { axiosClient as api } from '../axiosClient';

export async function getCategoriesList(locationId: string | undefined): Promise<LocationCategoryDto[]> {
  if (!locationId) {
    return [];
  }
  const { data } = await api.get<LocationCategoryDto[]>(`/admin/locations/${locationId}/categories`);
  return data;
}

export async function updateCategoryDetails(
  categoryId: string,
  locationLanguage: string,
  categoryDetails: CategoryDetailsDto,
): Promise<CategoryDetailsDto> {
  const { data } = await api.put(`/admin/categories/${categoryId}/landing/${locationLanguage}`, categoryDetails);
  return data;
}

export async function getCategoryDetails(
  categoryId: string,
  language: string | undefined,
): Promise<CategoryDetailsDto | undefined> {
  if (language === undefined) {
    return undefined;
  }
  const { data } = await api.get<CategoryDetailsDto>(`/admin/categories/${categoryId}/landing/${language}`);
  return data;
}

export async function getCategory(categoryId: string): Promise<CategoryDataDto> {
  const { data } = await api.get<CategoryDataDto>(`/admin/categories/${categoryId}`);
  return data;
}

export async function createCategory(category: CategoryCreateDto, language: string): Promise<CategoryCreateDto> {
  const { data } = await api.post(`/admin/categories/landing/${language}`, category);
  return data;
}

export async function updateCategory(categoryData: CategoryDataDto): Promise<void> {
  const { data } = await api.put(`/admin/categories/${categoryData.id}`, categoryData);
  return data;
}

export async function disableCategory(catData: DisableCategoryRequestDto): Promise<DisableCategoryRequestDto> {
  const { data } = await api.put(`/admin/categories/${catData.id}`, catData);
  return data;
}
