import type { FC } from 'react';

import { LanguageTabs, PageHeader } from 'shared/components';

import { TranslationTable } from 'features/location-settings/components/TranslationTable';

const LocationSettingsPage: FC = () => {
  return (
    <main>
      <PageHeader title="Manage Translations" />
      <LanguageTabs isShowLeaveModal={false}>
        <TranslationTable />
      </LanguageTabs>
    </main>
  );
};

export default LocationSettingsPage;
