import type { FC } from 'react';

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import { ProductDto, getLocationTimezone } from '@saturn/api';
import { DatePicker, Form, Icon, Input, Option, Select, Switch, TimePicker, Tooltip } from '@saturn/uikit';

import { Tip } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import styles from './PromocodeInfo.module.scss';

type PromocodeInfoProps = {
  products: ProductDto[];
  loadingProducts: boolean;
};
export const PromocodeInfo: FC<PromocodeInfoProps> = ({ products, loadingProducts }) => {
  const { adminLocation } = useAdminLocation();
  const locationTimezone = getLocationTimezone(adminLocation.id);

  const disabledStartDate = (date: Dayjs) => {
    const minDate = dayjs().startOf('day');
    const maxDate = dayjs().add(1, 'month').endOf('day');
    return !date || date.isBefore(minDate) || date.isAfter(maxDate);
  };

  const disabledExpiryDates = (startDate: string) => (date: Dayjs) => {
    const start = startDate ? dayjs(startDate) : dayjs();
    const minDate = start.clone().add(1, 'day').startOf('day');
    const maxDate = minDate.clone().add(3, 'months').endOf('day');
    return !date || date.isBefore(minDate) || date.isAfter(maxDate);
  };

  return (
    <div className={styles.content}>
      <Form.Item name="visible" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="promocodeValue"
        normalize={value => value?.trim().toUpperCase()}
        rules={[
          {
            required: true,
            message: 'Promocode value is required',
            whitespace: true,
          },
          {
            min: 3,
            message: 'Name should be at least 3 characters',
          },
          {
            max: 50,
            message: 'Name should be at most 50 characters',
          },
        ]}
      >
        <Input label="Promocode Value" />
      </Form.Item>
      <Form.Item
        name="productId"
        rules={[
          {
            required: true,
            message: 'Product is required',
          },
        ]}
      >
        <Select placeholder="Product" loading={loadingProducts}>
          {products?.map(item => (
            <Option key={item.id} data-key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldValue, setFieldValue }) => {
          const isStartNow = getFieldValue(['isStartNow']);
          const isNoExpired = getFieldValue(['isNoExpired']);
          const startDate = getFieldValue(['startDate']);
          const expiryDate = getFieldValue(['expiryDate']);

          return (
            <>
              <div className={styles.toggleWrapper}>
                <p className={styles.label}>Start now?</p>
                <Form.Item name={['isStartNow']} valuePropName="checked">
                  <Switch checkedChildren="on" unCheckedChildren="off" />
                </Form.Item>
                <Tooltip title="Enable to turn on promocode immediately after saving changes.">
                  <Icon size={25} name="question-mark-circle-outline" />
                </Tooltip>
              </div>
              <div className={styles.toggleWrapper}>
                <p className={styles.label}>No expiry date?</p>
                <Form.Item name={['isNoExpired']} valuePropName="checked">
                  <Switch checkedChildren="on" unCheckedChildren="off" />
                </Form.Item>
                <Tooltip title="Promocode will persist until manually turned off, with no automatic expiration.">
                  <Icon size={25} name="question-mark-circle-outline" />
                </Tooltip>
              </div>
              <div className={styles.dateTimeBlock}>
                {!isStartNow && (
                  <>
                    <Form.Item
                      name={['startDate']}
                      normalize={date => {
                        return date && dayjs(date).startOf('day').format('YYYY-MM-DD');
                      }}
                      rules={[{ required: !isStartNow, message: 'Display start date is required' }]}
                    >
                      <DatePicker
                        onChange={() => {
                          if (expiryDate) {
                            setFieldValue(['expiryDate'], null);
                          }
                        }}
                        label="Promo Start Date"
                        showNow={true}
                        disabledDate={disabledStartDate}
                        suffixIcon={<Icon name="calendar-outline" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['startTime']}
                      normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                      rules={[{ required: !isStartNow, message: 'Display start time is required' }]}
                    >
                      <TimePicker
                        timezone={locationTimezone}
                        label="Promo Start Time"
                        inputReadOnly
                        allowClear
                        showNow={false}
                      />
                    </Form.Item>
                  </>
                )}
                {!isNoExpired && (
                  <>
                    <Form.Item
                      name={['expiryDate']}
                      normalize={date => {
                        return date && dayjs(date).startOf('day').format('YYYY-MM-DD');
                      }}
                      rules={[{ required: !isNoExpired, message: 'Expiry date is required' }]}
                    >
                      <DatePicker
                        disabled={!isStartNow && !startDate}
                        label="Promo Expiry Date"
                        disabledDate={disabledExpiryDates(startDate)}
                        suffixIcon={<Icon name="calendar-outline" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['expiryTime']}
                      normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                      rules={[{ required: !isNoExpired, message: 'Expiry time is required' }]}
                    >
                      <TimePicker
                        showNow={false}
                        timezone={locationTimezone}
                        label="Promo Expiry Time"
                        inputReadOnly
                        allowClear
                      />
                    </Form.Item>
                  </>
                )}
              </div>
              {isStartNow && isNoExpired ? null : (
                <Tip>
                  Please take into account that the time should be adjusted for the UTC
                  {locationTimezone}({adminLocation.name}) timezone.
                </Tip>
              )}
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};
