import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import { FormAndCardViewProductDto, PromoEventBasicDto, PromoEventDetailsDto, admin } from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { SidebarList } from 'shared/components/SidebarList/SidebarList';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import { getVisibleSections, onFormFinishFailed, useAdminLocation } from 'shared/utils';

import { EventSystemInfo } from 'features/events/components/EventSystemInfo/EventSystemInfo';
import { PromoEventTabs } from 'features/events/components/PromoEventTabs/PromoEventTabs';
import EVENT_SECTION_TITLES from 'features/events/constants';

import styles from './styles.module.scss';

const EventCreatePage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromoEventBasicDto>();
  const [formDetails] = Form.useForm<PromoEventDetailsDto>();
  const [selectedProducts, updateSelectedProducts] = useState<string[]>([]);
  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [indices, setIndices] = useState<IndicesType>([]);

  const defaultIndices: Record<string, DefaultIndexValue> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(sectionsVisibility).map(([key]) => [
          key,
          {
            order: 0,
            subSections: null,
          },
        ]),
      ),
    [sectionsVisibility],
  );

  const disableProducts = (newSelectedProducts: FormAndCardViewProductDto[]) => {
    const result = [
      ...new Set(newSelectedProducts?.map(item => item?.productId).filter((id: string) => id)),
    ] as string[];
    updateSelectedProducts(result);
  };

  useEffect(() => {
    form.setFieldsValue({
      visible: false,
    });
    formDetails.setFieldsValue({
      hero: {
        visible: false,
      },
      howToSection: {
        visible: false,
      },
      about: {
        visible: false,
      },
      stripe: {
        visible: false,
      },
      disclosuresFooter: {
        visible: false,
      },
      productsSection: {
        isCarousel: true,
        cardsAlignment: 'left',
      },
    });
  }, []);

  useEffect(() => {
    setSectionsVisibility(getVisibleSections(formDetails.getFieldsValue()));
  }, []);

  const { data: providers = [] } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const { data: locationProducts = [] } = useRequest(() => admin.getProductForLocation(locationId), {
    refreshDeps: [locationId],
  });

  const { runAsync: createEvent } = useRequest(admin.updatePromoEvent, { manual: true });
  const { runAsync: createEventDetails } = useRequest(admin.createPromoEventDetails, { manual: true });

  const onError = () => {
    notification.error({
      message: 'Error during event creation has happened',
    });
  };

  const formFinished = async () => {
    try {
      const promoEventData = await form.validateFields();
      const promoEventDetails = await formDetails.validateFields();

      const indexedValues = indices.reduce((res, item, i) => {
        const itemKey: string = item[0];
        // @ts-ignore
        const block = res[itemKey];
        if (block && typeof block == 'object') {
          // @ts-ignore
          block.order = i;
        }
        if ('subSections' in block) {
          const indItem = indices.find(ind => ind[0] === itemKey);
          const itemSubSections = indItem && indItem[1]?.subSections;
          itemSubSections?.forEach((subSection, subSectionIndex) => {
            block.subSections[subSection[0]].order = subSectionIndex;
          });
        }
        return res;
      }, promoEventDetails);

      const newEvent = await createEvent({ ...promoEventData, locationId }).catch(onError);
      await createEventDetails({
        ...indexedValues,
        ...{
          language: locationLanguage || '',
          locationEventId: newEvent?.id,
        },
      });
      notification.success({
        message: 'Promo event has been successfully created',
      });
      const [, locationInPath, events] = location.pathname.split('/');
      navigate(`/${locationInPath}/${events}`, { replace: true });
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Create Event" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Form name="event" form={form} onFinishFailed={onFormFinishFailed} preserve={false}>
              <EventSystemInfo />
            </Form>
            <Form
              name="eventDetails"
              form={formDetails}
              onFinishFailed={onFormFinishFailed}
              onValuesChange={(changedValues, allValues) => {
                if (changedValues.productsSection) {
                  disableProducts(allValues.productsSection.products);
                }
                setSectionsVisibility(getVisibleSections(allValues));
              }}
              preserve={false}
            >
              <PromoEventTabs
                isShowLeaveModal={false}
                sectionTitles={EVENT_SECTION_TITLES}
                providers={providers}
                products={locationProducts}
                selectedProducts={selectedProducts}
              />
            </Form>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Create
                </Button>
              </Form.Item>
            </Card>
            <Card title="Layout" bordered={false} className={styles.sidebarCardWrapper}>
              <SidebarList
                parent="event"
                sidebarListValues={sectionsVisibility}
                sectionTitles={EVENT_SECTION_TITLES}
                defaultIndices={defaultIndices}
                indices={indices}
                setIndices={setIndices}
              />
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default EventCreatePage;
