import type { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { FindOutMoreBlock } from './components/FindOutMoreBlock';
import { FindOutMoreProps } from './model';

import styles from './FindOutMore.module.scss';

export const FindOutMore: FC<FindOutMoreProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Find Out More'} name={['findOutMoreSection']} showSectionTitle>
      <ContentBlockContainer
        className={styles.content}
        name={['findOutMoreSection', 'blogs']}
        addButtonText="Add Content"
        title="Find Out More"
        showChangePositionButton={true}
      >
        {fieldsInfo => <FindOutMoreBlock {...fieldsInfo} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
