import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

import { Alert } from '../Alert';

interface MostPopularPlanProps {
  header?: string;
  productLandingId?: string;
}

export const MostPopularPlan: FC<MostPopularPlanProps> = ({ header, productLandingId }) => {
  return (
    <CollapsiblePanel
      header={header || 'Most popular plan'}
      name={productLandingId !== 'it-chubb-viaggio-singolo-landing-it' ? ['highlightPlan'] : undefined}
      isRequiredSectionTitle={false}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue(['highlightPlan', 'visible']);

          return (
            <Form.Item
              name={['highlightPlan', 'planName']}
              rules={[
                {
                  required: isFieldRequired,
                  message: 'Plan name is required',
                  whitespace: true,
                },
                ...(isFieldRequired
                  ? [
                      {
                        max: 100,
                        message: 'Plan name should be at most 100 character(s)',
                      },
                    ]
                  : []),
              ]}
            >
              {productLandingId !== 'it-chubb-viaggio-singolo-landing-it' ? (
                <Input type="text" label="Plan name" />
              ) : (
                <Alert type="info" message="The logic is specific for the product. Please contact admin." />
              )}
            </Form.Item>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
