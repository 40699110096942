const LOCATION_SECTION_TITLES: Record<string, string> = {
  seo: 'SEO',
  hero: 'Hero Banner',
  quoteWidget: 'Quote Widget',
  callUsBack: 'Call Us Back',
  americanExpressInsurance: 'American Express Insurance Categories',
  americanExpressPartners: 'American Express Partners Categories',
  ourInsuranceProducts: 'Our Insurance Products',
  fastInsurance: 'Fast Insurance',
  whyAmericanExpress: 'Why American Express',
  insurancePartners: 'Insurance Partners',
  disclosuresFooter: 'Footer Disclosures',
  secondaryFooterDisclosure: 'Non-amex Footer Disclosures',
  video: 'Marketing video',
  breadcrumb: 'Breadcrumb',
};

export default LOCATION_SECTION_TITLES;
