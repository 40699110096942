import type { FC } from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Icon, Switch, Table } from '@saturn/uikit';

import { CategoryListProps } from './models';

export const CategoryList: FC<CategoryListProps> = ({ categories, toggleCategoryState, disabledCategories }) => {
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Edit',
        dataIndex: 'id',
        render: function adminLink(id: string) {
          const path = `${location.pathname}/${id}`.replaceAll(/\/{2,}/gi, '/');

          return (
            <Link data-key="action-edit" to={path}>
              <Icon name="edit-2-outline" />
            </Link>
          );
        },
      },
      {
        title: 'Hide?',
        dataIndex: 'id',
        render: function adminDisableToggler(id: string) {
          return (
            <Switch
              checkedChildren="yes"
              unCheckedChildren="no"
              checked={disabledCategories[id]}
              data-key="action-hide"
              defaultChecked={categories.find(cat => cat.id === id)?.hidden}
              onChange={checked => {
                toggleCategoryState && toggleCategoryState(id, checked);
              }}
            />
          );
        },
      },
    ],
    [location, categories, toggleCategoryState, disabledCategories],
  );

  return <Table rowKey={record => record.id} columns={columns} dataSource={categories} pagination={false} />;
};
