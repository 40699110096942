import { FC } from 'react';

import {
  Breadcrumb,
  FooterDisclosures,
  HeroBanner,
  LanguageTabs,
  PageSEO,
  SecondaryFooterDisclosures,
  Video,
} from 'shared/components';
import { CallUsBack } from 'shared/components/CallUsBack/CallUsBack';
import { InsurancePartners } from 'shared/components/InsurancePartners/InsurancePartners';
import { QuoteWidget } from 'shared/components/QuoteWidget/QuoteWidget';
import { WhyAmex } from 'shared/components/WhyAmex';

import { FastInsurance } from '../FastInsurance';
import { InsuranceCategories } from '../InsuranceCategories';
import { InsuranceCategoriesPartners } from '../InsuranceCategoriesPartners';
import { InsuranceProducts } from '../InsuranceProducts';
import { LocationTabsProps } from './model';

export const LocationTabs: FC<LocationTabsProps> = ({
  categories = [],
  products = [],
  selectedInsuranceCategories = [],
  selectedInsuranceProducts = [],
  isShowLeaveModal,
  sectionTitles,
  isSecondaryFooterDisclosureVisible,
  providers,
  selectedInsurancePartners,
}) => {
  return (
    <>
      <LanguageTabs isShowLeaveModal={isShowLeaveModal}>
        <Breadcrumb header={sectionTitles.breadcrumb} />
        <PageSEO header={sectionTitles.seo} />
        <HeroBanner header={sectionTitles.hero} providers={providers} />
        <QuoteWidget header={sectionTitles.quoteWidget} />
        <CallUsBack header={sectionTitles.callUsBack} />
        <Video header={sectionTitles.video} parent="location" />
        <InsuranceCategories
          header={sectionTitles.americanExpressInsurance}
          selectedInsuranceCategories={selectedInsuranceCategories}
          categories={categories}
        />
        <InsuranceCategoriesPartners
          header={sectionTitles.americanExpressPartners}
          selectedInsuranceCategories={selectedInsuranceCategories}
          categories={categories}
        />
        <InsuranceProducts
          header={sectionTitles.ourInsuranceProducts}
          selectedInsuranceProducts={selectedInsuranceProducts}
          products={products}
        />
        <FastInsurance header={sectionTitles.fastInsurance} />
        <WhyAmex header={sectionTitles.whyAmericanExpress} />
        <InsurancePartners
          header={sectionTitles.insurancePartners}
          providers={providers}
          selectedInsurancePartners={selectedInsurancePartners}
        />
        <FooterDisclosures header={sectionTitles.disclosuresFooter} />
        {isSecondaryFooterDisclosureVisible && (
          <SecondaryFooterDisclosures header={sectionTitles.secondaryFooterDisclosure} />
        )}
      </LanguageTabs>
    </>
  );
};
