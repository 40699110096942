import { Dispatch, SetStateAction, useState } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import cn from 'classnames';

import { Button, Checkbox, Modal, notification } from '@saturn/uikit';

import { ReactComponent as ClipIcon } from './clip.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';

import styles from './ImportPageContentModal.module.scss';

const { Dragger } = Upload;

type ImportPageContentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onImportPage: (form: FormData) => void;
  onImportFinished: Dispatch<SetStateAction<boolean>>;
};
export default function ImportPageContentModal({
  isOpen,
  onClose,
  onImportPage,
  onImportFinished,
}: ImportPageContentModalProps) {
  const [file, setFile] = useState<File | null>(null);
  const [isAgree, setIsAgree] = useState(false);
  const [showAgreeError, setShowAgreeError] = useState(false);

  const handleSubmit = () => {
    if (isAgree) {
      handleImport();
      setShowAgreeError(false);
      onImportFinished(true);
    } else {
      setShowAgreeError(true);
    }
  };
  const handleImport = () => {
    if (file) {
      const importForm = new FormData();
      importForm.append('file', file);
      onImportPage(importForm);
      setFile(null);
      onClose();
    } else {
      notification.error({
        message: 'Please select file',
      });
    }
  };

  const uploadProps = {
    name: 'file',
    beforeUpload: (file: File) => {
      setFile(file);
      return false;
    },
  };

  return (
    <Modal
      className={styles.modal}
      open={isOpen}
      onCancel={onClose}
      footer={() => {
        return (
          <Button type="primary" className={styles.submit} onClick={handleSubmit}>
            Import
          </Button>
        );
      }}
    >
      <p className={styles.modalTitle}>Import Page Content</p>
      <p className={styles.modalSubtitle}>To proceed please upload exported page file in .json format.</p>
      <div className={styles.uploader}>
        {file ? (
          <div className={styles.fileRow}>
            <ClipIcon />
            <span className={styles.name}>{file.name}</span>
            <DeleteIcon className={styles.trash} onClick={() => setFile(null)} />
          </div>
        ) : (
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className={styles.title}>Click or drag file to this area to upload</p>
            <p className={styles.subtitle}>Make sure that you’re loading matching page type</p>
          </Dragger>
        )}
      </div>
      <Checkbox
        className={cn(showAgreeError && styles.checkboxWithError)}
        checked={isAgree}
        onChange={e => setIsAgree(e.target.checked)}
      >
        <p>
          I confirm that by importing, I still remain fully responsible for all aspects of how the content appears on
          the page.
        </p>
      </Checkbox>
      {showAgreeError && <span className={styles.modalErrorText}>Agree before import</span>}
      <div className={styles.divider} />
    </Modal>
  );
}
