import { PromoEventBasicDto, PromoEventDetailsDto } from '../../models/dto/PromoEventDto';
import { axiosClient as api } from '../axiosClient';

export async function getPromoEventsList(locationId: string | undefined): Promise<PromoEventBasicDto[]> {
  if (!locationId) {
    return [];
  }
  const { data } = await api.get<PromoEventBasicDto[]>(`/admin/location-event/list/${locationId}`);
  return data;
}

export async function getPromoEvent(eventId: string): Promise<PromoEventBasicDto> {
  const { data } = await api.get<PromoEventBasicDto>(`/admin/location-event/${eventId}`);
  return data;
}

export async function updatePromoEvent(event: PromoEventBasicDto): Promise<PromoEventBasicDto> {
  const { data } = await api.put<PromoEventBasicDto>('/admin/location-event', event);
  return data;
}

export async function deletePromoEvent(eventId: string): Promise<void> {
  await api.delete<PromoEventBasicDto>(`/admin/location-event/${eventId}`);
}

export async function disablePromoEvent(eventId: string): Promise<void> {
  await api.put<PromoEventBasicDto>(`/admin/location-event/${eventId}/disable`);
}

export async function enablePromoEvent(eventId: string): Promise<void> {
  await api.put<PromoEventBasicDto>(`/admin/location-event/${eventId}/enable`);
}

export async function getPromoEventDetails(locationEventId: string, language: string): Promise<PromoEventDetailsDto> {
  const { data } = await api.get<PromoEventDetailsDto>(`/admin/location-event/landing/${locationEventId}/${language}`);
  return data;
}

export async function updatePromoEventDetails(eventDetails: PromoEventDetailsDto): Promise<PromoEventDetailsDto> {
  const { data } = await api.put<PromoEventDetailsDto>('/admin/location-event/landing', eventDetails);
  return data;
}

export async function createPromoEventDetails(eventDetails: PromoEventDetailsDto): Promise<PromoEventDetailsDto> {
  const { data } = await api.post<PromoEventDetailsDto>('/admin/location-event/landing', eventDetails);
  return data;
}
