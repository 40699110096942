import { FC } from 'react';

import { ProductDto } from '@saturn/api';

import {
  Breadcrumb,
  CallUsBack,
  FooterDisclosures,
  GeneralInfo,
  HeroBanner,
  LanguageTabs,
  PageSEO,
  SecondaryFooterDisclosures,
  Video,
  VisibilitySwitcher,
} from 'shared/components';
import { InsurancePartners } from 'shared/components/InsurancePartners/InsurancePartners';
import { QuoteWidget } from 'shared/components/QuoteWidget/QuoteWidget';
import { WhyAmex } from 'shared/components/WhyAmex';
import type { ProviderOption } from 'shared/utils';

import { FindOutMore, InsuranceProducts } from 'features/categories/components';

import { ProductQuoteForms } from '../ProductQuoteForms/ProductQuoteForms';

export interface CategoryTabsProps {
  isShowLeaveModal: boolean;
  isSecondaryFooterDisclosureVisible?: boolean;
  products: ProductDto[];
  selectedProducts?: string[];
  sectionTitles: Record<string, string>;
  isNewCategory?: boolean;
  providers: ProviderOption[];
  isLandingPageEnabled?: boolean;
  selectedInsurancePartners: string[];
}

export const CategoryTabs: FC<CategoryTabsProps> = ({
  isShowLeaveModal,
  selectedProducts,
  products,
  sectionTitles,
  isSecondaryFooterDisclosureVisible,
  providers,
  isLandingPageEnabled,
  selectedInsurancePartners,
}) => {
  return (
    <LanguageTabs isShowLeaveModal={isShowLeaveModal}>
      {isLandingPageEnabled && (
        <>
          <Breadcrumb header={sectionTitles.breadcrumb} />
          <PageSEO header={sectionTitles.seo} />
        </>
      )}
      <GeneralInfo header={sectionTitles.info} type="category" isLandingPageEnabled={isLandingPageEnabled} />
      {isLandingPageEnabled && (
        <>
          <HeroBanner
            header={sectionTitles.hero}
            otherCategoriesButtonToggle={
              <VisibilitySwitcher
                title="Navigate to other categories button"
                name={['hero', 'goToActionButtonVisible']}
              />
            }
            providers={providers}
          />
          <QuoteWidget header={sectionTitles.quoteWidget} />
          <ProductQuoteForms header={sectionTitles.productQuoteForms} />
          <CallUsBack header={sectionTitles.callUsBack} />
          <Video header={sectionTitles.video} parent="category" />
          <InsuranceProducts
            header={sectionTitles.categoryProducts}
            selectedInsuranceProducts={selectedProducts}
            products={products}
          />
          <FindOutMore header={sectionTitles.findOutMoreSection} />
          <InsurancePartners
            header={sectionTitles.insurancePartners}
            providers={providers}
            selectedInsurancePartners={selectedInsurancePartners}
          />
          <WhyAmex header={sectionTitles.whyAmericanExpress} />
          <FooterDisclosures header={sectionTitles.disclosuresFooter} />
          {isSecondaryFooterDisclosureVisible && (
            <SecondaryFooterDisclosures header={sectionTitles.secondaryFooterDisclosure} />
          )}
        </>
      )}
    </LanguageTabs>
  );
};
